/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AffiliationCreate {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  id: number
  /** קהילה */
  kehila?: number | null
  kehilaKey: string
  /** קן */
  ken?: number | null
  /** רשות */
  kenGroup?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position: number
  /** שכבות */
  shchavot?: number[]
  statusDisplay: string
  /** משתמש */
  user?: number
}

export interface AffiliationCreateFormPopulate {
  kenGroups?: KenGroup[]
  kenim?: Ken[]
  mehozot?: Mahoz[]
  positions: Position[]
  shchavot?: Shichva[]
}

export interface AffiliationCreateRequest {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** קהילה */
  kehila?: number | null
  /** קן */
  ken?: number | null
  /** רשות */
  kenGroup?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position: number
  /** שכבות */
  shchavot?: number[]
  /** משתמש */
  user?: number
}

export interface AffiliationFK {
  id: number
  kehila?: KehilaThinFK
  ken?: KenFK
  mahoz?: MahozFK
  position: PositionFK
  shchavot?: ShichvaFK[]
}

/**
 * * `create` - Create
 * * `update` - Update
 * * `partial_update` - Partial Update
 * * `list` - List
 * * `retrieve` - Retrieve
 * * `delete` - Delete
 * * `manage_affiliation` - Manage Affiliation
 * * `access_affiliation_manager` - Access Affiliation Manager
 */
export enum AffiliationPermissionActions {
  Create = 'create',
  Update = 'update',
  PartialUpdate = 'partial_update',
  List = 'list',
  Retrieve = 'retrieve',
  Delete = 'delete',
  ManageAffiliation = 'manage_affiliation',
  AccessAffiliationManager = 'access_affiliation_manager',
}

export interface AffiliationPermissions {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  id: number
  kehila: Kehila
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  permissions: Permissions
  /** תפקיד */
  position: number
  shchavot: Shichva[]
}

/**
 * * `PENDING` - מחכה לאישור
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `BANNED` - נחסם
 */
export enum AffiliationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  BANNED = 'BANNED',
}

/**
 * סטטוס
 * * `PENDING` - מחכה לאישור
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `BANNED` - נחסם
 *
 * * `PENDING` - מחכה לאישור
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `BANNED` - נחסם
 */
export enum AffiliationStatusEnum {
  APPROVED = 'APPROVED',
  BANNED = 'BANNED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface AffiliationUpdate {
  /** קהילה */
  kehila?: number | null
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position?: number
  /** שכבות */
  shchavot?: number[]
  /** משתמש */
  user?: number
}

export interface AffiliationUpdateRequest {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  /** קהילה */
  kehila?: number | null
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position?: number
  /** שכבות */
  shchavot?: number[]
  /** משתמש */
  user?: number
}

export interface AffiliationView {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  displayName: string
  id: number
  kehila?: KehilaThinnest
  ken?: Ken
  kenGroup?: KenGroup
  mahoz?: Mahoz
  position: Position
  shchavot?: Shichva[]
  statusDisplay: string
  unitName: string
  /**
   * זמן עדכון
   * @format date-time
   */
  updatedAt: string
  user: ThinUser
}

export interface AffiliationViewRequest {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  kehila?: KehilaThinnestRequest
  ken?: KenRequest
  kenGroup?: KenGroupRequest
  mahoz?: MahozRequest
  position: PositionRequest
  shchavot?: ShichvaRequest[]
  /** @minLength 1 */
  unitName: string
  user: ThinUserRequest
}

export interface AffiliationsCreateRetrieveParams {
  /** request user affiliation id */
  affiliation?: number
  /** Kehila id */
  kehila_id?: number
  /** Kehila key */
  kehila_key?: string
  /** Position id */
  position?: number
  /** KEN positions or MAHOZ positions */
  position_tier?: PositionTierEnum
}

/** KEN positions or MAHOZ positions */
export enum AffiliationsCreateRetrieveParams1PositionTierEnum {
  KEHILA = 'KEHILA',
  KEN = 'KEN',
  KEN_GROUP = 'KEN_GROUP',
  KLALI = 'KLALI',
  MAHOZ = 'MAHOZ',
}

export interface AffiliationsListParams {
  /** Affiliation id */
  affiliation?: number
  /**
   * סטטוס
   * * `PENDING` - מחכה לאישור
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `BANNED` - נחסם
   *
   * * `PENDING` - מחכה לאישור
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `BANNED` - נחסם
   */
  affiliation_status?: AffiliationStatusEnum
  kehila?: number
  /** id of kehila */
  kehila_id?: number
  /** Key of kehila */
  kehila_key?: string
  ken?: number[]
  /** Number of results to return per page. */
  limit?: number
  mahoz?: number[]
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  position?: number[]
  /** A search term. */
  search?: string
  shchavot?: number[]
  user__email?: string
  user__first_name?: string
  user__last_name?: string
}

/**
 * סטטוס
 * * `PENDING` - מחכה לאישור
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `BANNED` - נחסם
 *
 * * `PENDING` - מחכה לאישור
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `BANNED` - נחסם
 */
export enum AffiliationsListParams1AffiliationStatusEnum {
  APPROVED = 'APPROVED',
  BANNED = 'BANNED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface AffiliationsRetrieveParams {
  /** A unique integer value identifying this שיוך. */
  id: number
  /** id of kehila */
  kehila_id?: number
  /** Key of kehila */
  kehila_key?: string
}

export interface AffiliationsTestDataDestroyParams {
  /** Do not delete affiliations */
  skip_affiliations?: boolean
  /** Do not delete peilut */
  skip_peilut?: boolean
  /** Do not delete strips */
  skip_strips?: boolean
  /** Do not delete random users */
  skip_users?: boolean
}

export interface Asset {
  /** @maxLength 255 */
  contentType: string
  /** @maxLength 255 */
  filename: string
  id: number
  /**
   * @format uri
   * @pattern (?:odt|doc|docx|pages|txt|pdf|jpg|jpeg|ppt|pptx|png|svg|ppsx|xls|xlsx)$
   */
  source: string
  /**
   * * `temp` - זמני
   * * `att` - משוייך
   */
  status?: StatusEnum
}

export interface AssetFK {
  /** @maxLength 255 */
  contentType: string
  /** @maxLength 255 */
  filename: string
  id: number
  /**
   * @format uri
   * @pattern (?:odt|doc|docx|pages|txt|pdf|jpg|jpeg|ppt|pptx|png|svg|ppsx|xls|xlsx)$
   */
  source: string
  /**
   * * `temp` - זמני
   * * `att` - משוייך
   */
  status?: StatusEnum
}

export interface AssetRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  contentType: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  filename: string
  /**
   * @format binary
   * @pattern (?:odt|doc|docx|pages|txt|pdf|jpg|jpeg|ppt|pptx|png|svg|ppsx|xls|xlsx)$
   */
  source: File
  /**
   * * `temp` - זמני
   * * `att` - משוייך
   */
  status?: StatusEnum
}

export interface AssetsListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export enum BlankEnum {
  Value = '',
}

export interface CategoriesListParams {
  is_highlighted?: boolean
  /** Number of results to return per page. */
  limit?: number
  name?: string
  /** A page number within the paginated result set. */
  page?: number
  /** A search term. */
  search?: string
}

export interface Category {
  id: number
  isHighlighted?: boolean
  /** @maxLength 254 */
  name: string
}

export interface CategoryRequest {
  isHighlighted?: boolean
  /**
   * @minLength 1
   * @maxLength 254
   */
  name: string
}

export interface ChangePassword {
  confirmPassword: string
  newPassword: string
  oldPassword: string
}

export interface ChangePasswordRequest {
  /** @minLength 1 */
  confirmPassword: string
  /** @minLength 1 */
  newPassword: string
  /** @minLength 1 */
  oldPassword: string
}

export interface ClassificationCreate {
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** פעילות */
  peilut: number
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationCreateRequest {
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** פעילות */
  peilut: number
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationFK {
  id: number
  kehila: KehilaThinFK
  ken: KenFK
  mahoz: MahozFK
  position: PositionFK
  shchavot: ShichvaFK[]
}

export interface ClassificationUpdate {
  id: number
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** פעילות */
  peilut: number
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationUpdateForPeilut {
  id: number
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationUpdateForPeilutRequest {
  id: number
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationUpdateRequest {
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** פעילות */
  peilut: number
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface ClassificationView {
  id: number
  kehila: KehilaThinFK
  ken: KenFK
  mahoz: MahozFK
  peilut: PeilutFK
  position: PositionFK
  shchavot: ShichvaFK[]
}

export interface ClassificationsListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface CodeEmailRequest {
  code: number
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export interface CodePhoneRequest {
  code: number
  /** @minLength 1 */
  phone: string
}

/**
 * * `LEGAL` - משפטי
 * * `EDUCATIONAL` - חינוכי
 */
export enum CommentTypeEnum {
  LEGAL = 'LEGAL',
  EDUCATIONAL = 'EDUCATIONAL',
}

export interface CommentsListParams {
  creator?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  peilut?: number
}

export interface Cycle {
  about?: string | null
  alias: string
  /** @maxLength 7 */
  color?: string | null
  /** @maxLength 255 */
  cover?: string | null
  /** @format date */
  displayEndDate: string
  /** @format date */
  displayStartDate: string
  /** @format date */
  endDate: string
  id: number
  isCurrent: boolean
  /** @maxLength 255 */
  logo?: string | null
  /** @maxLength 254 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
  /** @format date */
  startDate: string
}

export interface CycleCard {
  id: number
  /** @maxLength 255 */
  logo?: string | null
  /** @maxLength 254 */
  name: string
}

export interface CycleFK {
  /** @maxLength 7 */
  color?: string | null
  /** @maxLength 255 */
  cover?: string | null
  /** @format date */
  displayEndDate: string
  /** @format date */
  displayStartDate: string
  /** @format date */
  endDate: string
  id: number
  isCurrent: boolean
  /** @maxLength 255 */
  logo?: string | null
  /** @maxLength 254 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
  /** @format date */
  startDate: string
}

export interface CyclesListParams {
  /** Affiliation id */
  affiliation?: number
  /** return cycle for card display */
  card?: boolean
  /** Kehila id */
  kehila_id?: number
  /** Kehila key */
  kehila_key?: string
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface ForgotPassword {
  /** @format email */
  email?: string
}

export interface ForgotPasswordRequest {
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  phone?: string
}

export enum FormatEnum {
  Json = 'json',
  Yaml = 'yaml',
}

/**
 * * `view_analytics_dashboard` - View Analytics Dashboard
 * * `switch_kehila` - Kehila Switch
 */
export enum GlobalpermissionEnum {
  ViewAnalyticsDashboard = 'view_analytics_dashboard',
  SwitchKehila = 'switch_kehila',
}

/**
 * * `#f7fafc` - gray 100
 * * `#edf2f7` - gray 200
 * * `#e2e8f0` - gray 300
 * * `#cbd5e0` - gray 400
 * * `#a0aec0` - gray 500
 * * `#718096` - gray 600
 * * `#4a5568` - gray 700
 * * `#2d3748` - gray 800
 * * `#1a202c` - gray 900
 * * `#fff5f5` - red 100
 * * `#fed7d7` - red 200
 * * `#feb2b2` - red 300
 * * `#fc8181` - red 400
 * * `#f56565` - red 500
 * * `#e53e3e` - red 600
 * * `#c53030` - red 700
 * * `#9b2c2c` - red 800
 * * `#742a2a` - red 900
 * * `#fffaf0` - orange 100
 * * `#feebc8` - orange 200
 * * `#fbd38d` - orange 300
 * * `#f6ad55` - orange 400
 * * `#ed8936` - orange 500
 * * `#dd6b20` - orange 600
 * * `#c05621` - orange 700
 * * `#9c4221` - orange 800
 * * `#7b341e` - orange 900
 * * `#fffff0` - yellow 100
 * * `#fefcbf` - yellow 200
 * * `#faf089` - yellow 300
 * * `#f6e05e` - yellow 400
 * * `#ecc94b` - yellow 500
 * * `#d69e2e` - yellow 600
 * * `#b7791f` - yellow 700
 * * `#975a16` - yellow 800
 * * `#744210` - yellow 900
 * * `#f0fff4` - green 100
 * * `#c6f6d5` - green 200
 * * `#9ae6b4` - green 300
 * * `#68d391` - green 400
 * * `#48bb78` - green 500
 * * `#38a169` - green 600
 * * `#2f855a` - green 700
 * * `#276749` - green 800
 * * `#22543d` - green 900
 * * `#e6fffa` - teal 100
 * * `#b2f5ea` - teal 200
 * * `#81e6d9` - teal 300
 * * `#4fd1c5` - teal 400
 * * `#38b2ac` - teal 500
 * * `#319795` - teal 600
 * * `#2c7a7b` - teal 700
 * * `#285e61` - teal 800
 * * `#234e52` - teal 900
 * * `#ebf8ff` - blue 100
 * * `#bee3f8` - blue 200
 * * `#90cdf4` - blue 300
 * * `#63b3ed` - blue 400
 * * `#4299e1` - blue 500
 * * `#3182ce` - blue 600
 * * `#2b6cb0` - blue 700
 * * `#2c5282` - blue 800
 * * `#2a4365` - blue 900
 * * `#ebf4ff` - indigo 100
 * * `#c3dafe` - indigo 200
 * * `#a3bffa` - indigo 300
 * * `#7f9cf5` - indigo 400
 * * `#667eea` - indigo 500
 * * `#5a67d8` - indigo 600
 * * `#4c51bf` - indigo 700
 * * `#434190` - indigo 800
 * * `#3c366b` - indigo 900
 * * `#faf5ff` - purple 100
 * * `#e9d8fd` - purple 200
 * * `#d6bcfa` - purple 300
 * * `#b794f4` - purple 400
 * * `#9f7aea` - purple 500
 * * `#805ad5` - purple 600
 * * `#6b46c1` - purple 700
 * * `#553c9a` - purple 800
 * * `#44337a` - purple 900
 * * `#fff5f7` - pink 100
 * * `#fed7e2` - pink 200
 * * `#fbb6ce` - pink 300
 * * `#f687b3` - pink 400
 * * `#ed64a6` - pink 500
 * * `#d53f8c` - pink 600
 * * `#b83280` - pink 700
 * * `#97266d` - pink 800
 * * `#702459` - pink 900
 */
export enum IconColorEnum {
  ValueF7Fafc = '#f7fafc',
  ValueEdf2F7 = '#edf2f7',
  ValueE2E8F0 = '#e2e8f0',
  ValueCbd5E0 = '#cbd5e0',
  ValueA0Aec0 = '#a0aec0',
  Value718096 = '#718096',
  Value4A5568 = '#4a5568',
  Value2D3748 = '#2d3748',
  Value1A202C = '#1a202c',
  ValueFff5F5 = '#fff5f5',
  ValueFed7D7 = '#fed7d7',
  ValueFeb2B2 = '#feb2b2',
  ValueFc8181 = '#fc8181',
  ValueF56565 = '#f56565',
  ValueE53E3E = '#e53e3e',
  ValueC53030 = '#c53030',
  Value9B2C2C = '#9b2c2c',
  Value742A2A = '#742a2a',
  ValueFffaf0 = '#fffaf0',
  ValueFeebc8 = '#feebc8',
  ValueFbd38D = '#fbd38d',
  ValueF6Ad55 = '#f6ad55',
  ValueEd8936 = '#ed8936',
  ValueDd6B20 = '#dd6b20',
  ValueC05621 = '#c05621',
  Value9C4221 = '#9c4221',
  Value7B341E = '#7b341e',
  ValueFffff0 = '#fffff0',
  ValueFefcbf = '#fefcbf',
  ValueFaf089 = '#faf089',
  ValueF6E05E = '#f6e05e',
  ValueEcc94B = '#ecc94b',
  ValueD69E2E = '#d69e2e',
  ValueB7791F = '#b7791f',
  Value975A16 = '#975a16',
  Value744210 = '#744210',
  ValueF0Fff4 = '#f0fff4',
  ValueC6F6D5 = '#c6f6d5',
  Value9Ae6B4 = '#9ae6b4',
  Value68D391 = '#68d391',
  Value48Bb78 = '#48bb78',
  Value38A169 = '#38a169',
  Value2F855A = '#2f855a',
  Value276749 = '#276749',
  Value22543D = '#22543d',
  ValueE6Fffa = '#e6fffa',
  ValueB2F5Ea = '#b2f5ea',
  Value81E6D9 = '#81e6d9',
  Value4Fd1C5 = '#4fd1c5',
  Value38B2Ac = '#38b2ac',
  Value319795 = '#319795',
  Value2C7A7B = '#2c7a7b',
  Value285E61 = '#285e61',
  Value234E52 = '#234e52',
  ValueEbf8Ff = '#ebf8ff',
  ValueBee3F8 = '#bee3f8',
  Value90Cdf4 = '#90cdf4',
  Value63B3Ed = '#63b3ed',
  Value4299E1 = '#4299e1',
  Value3182Ce = '#3182ce',
  Value2B6Cb0 = '#2b6cb0',
  Value2C5282 = '#2c5282',
  Value2A4365 = '#2a4365',
  ValueEbf4Ff = '#ebf4ff',
  ValueC3Dafe = '#c3dafe',
  ValueA3Bffa = '#a3bffa',
  Value7F9Cf5 = '#7f9cf5',
  Value667Eea = '#667eea',
  Value5A67D8 = '#5a67d8',
  Value4C51Bf = '#4c51bf',
  Value434190 = '#434190',
  Value3C366B = '#3c366b',
  ValueFaf5Ff = '#faf5ff',
  ValueE9D8Fd = '#e9d8fd',
  ValueD6Bcfa = '#d6bcfa',
  ValueB794F4 = '#b794f4',
  Value9F7Aea = '#9f7aea',
  Value805Ad5 = '#805ad5',
  Value6B46C1 = '#6b46c1',
  Value553C9A = '#553c9a',
  Value44337A = '#44337a',
  ValueFff5F7 = '#fff5f7',
  ValueFed7E2 = '#fed7e2',
  ValueFbb6Ce = '#fbb6ce',
  ValueF687B3 = '#f687b3',
  ValueEd64A6 = '#ed64a6',
  ValueD53F8C = '#d53f8c',
  ValueB83280 = '#b83280',
  Value97266D = '#97266d',
  Value702459 = '#702459',
}

/**
 * * `whatsapp` - whatsapp
 * * `abacus` - abacus
 * * `accent-grave` - accent grave
 * * `acorn` - acorn
 * * `address-book` - address book
 * * `address-card` - address card
 * * `air-conditioner` - air conditioner
 * * `airplay` - airplay
 * * `alarm-clock` - alarm clock
 * * `alarm-exclamation` - alarm exclamation
 * * `alarm-plus` - alarm plus
 * * `alarm-snooze` - alarm snooze
 * * `album-circle-plus` - album circle plus
 * * `album-circle-user` - album circle user
 * * `album-collection-circle-plus` - album collection circle plus
 * * `album-collection-circle-user` - album collection circle user
 * * `album-collection` - album collection
 * * `album` - album
 * * `alicorn` - alicorn
 * * `alien-8bit` - alien 8bit
 * * `alien` - alien
 * * `align-center` - align center
 * * `align-justify` - align justify
 * * `align-left` - align left
 * * `align-right` - align right
 * * `align-slash` - align slash
 * * `alt` - alt
 * * `amp-guitar` - amp guitar
 * * `ampersand` - ampersand
 * * `anchor-circle-check` - anchor circle check
 * * `anchor-circle-exclamation` - anchor circle exclamation
 * * `anchor-circle-xmark` - anchor circle xmark
 * * `anchor-lock` - anchor lock
 * * `anchor` - anchor
 * * `angel` - angel
 * * `angle-90` - angle 90
 * * `angle-down` - angle down
 * * `angle-left` - angle left
 * * `angle-right` - angle right
 * * `angle-up` - angle up
 * * `angle` - angle
 * * `angles-down` - angles down
 * * `angles-left` - angles left
 * * `angles-right` - angles right
 * * `angles-up` - angles up
 * * `ankh` - ankh
 * * `apartment` - apartment
 * * `aperture` - aperture
 * * `apostrophe` - apostrophe
 * * `apple-core` - apple core
 * * `apple-whole` - apple whole
 * * `archway` - archway
 * * `arrow-down-1-9` - arrow down 1 9
 * * `arrow-down-9-1` - arrow down 9 1
 * * `arrow-down-a-z` - arrow down a z
 * * `arrow-down-arrow-up` - arrow down arrow up
 * * `arrow-down-big-small` - arrow down big small
 * * `arrow-down-from-dotted-line` - arrow down from dotted line
 * * `arrow-down-from-line` - arrow down from line
 * * `arrow-down-left-and-arrow-up-right-to-center` - arrow down left and arrow up right to center
 * * `arrow-down-left` - arrow down left
 * * `arrow-down-long` - arrow down long
 * * `arrow-down-right` - arrow down right
 * * `arrow-down-short-wide` - arrow down short wide
 * * `arrow-down-small-big` - arrow down small big
 * * `arrow-down-square-triangle` - arrow down square triangle
 * * `arrow-down-to-arc` - arrow down to arc
 * * `arrow-down-to-bracket` - arrow down to bracket
 * * `arrow-down-to-dotted-line` - arrow down to dotted line
 * * `arrow-down-to-line` - arrow down to line
 * * `arrow-down-to-square` - arrow down to square
 * * `arrow-down-triangle-square` - arrow down triangle square
 * * `arrow-down-up-across-line` - arrow down up across line
 * * `arrow-down-up-lock` - arrow down up lock
 * * `arrow-down-wide-short` - arrow down wide short
 * * `arrow-down-z-a` - arrow down z a
 * * `arrow-down` - arrow down
 * * `arrow-left-from-line` - arrow left from line
 * * `arrow-left-long-to-line` - arrow left long to line
 * * `arrow-left-long` - arrow left long
 * * `arrow-left-to-line` - arrow left to line
 * * `arrow-left` - arrow left
 * * `arrow-pointer` - arrow pointer
 * * `arrow-right-arrow-left` - arrow right arrow left
 * * `arrow-right-from-arc` - arrow right from arc
 * * `arrow-right-from-bracket` - arrow right from bracket
 * * `arrow-right-from-line` - arrow right from line
 * * `arrow-right-long-to-line` - arrow right long to line
 * * `arrow-right-long` - arrow right long
 * * `arrow-right-to-arc` - arrow right to arc
 * * `arrow-right-to-bracket` - arrow right to bracket
 * * `arrow-right-to-city` - arrow right to city
 * * `arrow-right-to-line` - arrow right to line
 * * `arrow-right` - arrow right
 * * `arrow-rotate-left` - arrow rotate left
 * * `arrow-rotate-right` - arrow rotate right
 * * `arrow-trend-down` - arrow trend down
 * * `arrow-trend-up` - arrow trend up
 * * `arrow-turn-down-left` - arrow turn down left
 * * `arrow-turn-down-right` - arrow turn down right
 * * `arrow-turn-down` - arrow turn down
 * * `arrow-turn-up` - arrow turn up
 * * `arrow-up-1-9` - arrow up 1 9
 * * `arrow-up-9-1` - arrow up 9 1
 * * `arrow-up-a-z` - arrow up a z
 * * `arrow-up-arrow-down` - arrow up arrow down
 * * `arrow-up-big-small` - arrow up big small
 * * `arrow-up-from-arc` - arrow up from arc
 * * `arrow-up-from-bracket` - arrow up from bracket
 * * `arrow-up-from-dotted-line` - arrow up from dotted line
 * * `arrow-up-from-ground-water` - arrow up from ground water
 * * `arrow-up-from-line` - arrow up from line
 * * `arrow-up-from-square` - arrow up from square
 * * `arrow-up-from-water-pump` - arrow up from water pump
 * * `arrow-up-left-from-circle` - arrow up left from circle
 * * `arrow-up-left` - arrow up left
 * * `arrow-up-long` - arrow up long
 * * `arrow-up-right-and-arrow-down-left-from-center` - arrow up right and arrow down left from center
 * * `arrow-up-right-dots` - arrow up right dots
 * * `arrow-up-right-from-square` - arrow up right from square
 * * `arrow-up-right` - arrow up right
 * * `arrow-up-short-wide` - arrow up short wide
 * * `arrow-up-small-big` - arrow up small big
 * * `arrow-up-square-triangle` - arrow up square triangle
 * * `arrow-up-to-dotted-line` - arrow up to dotted line
 * * `arrow-up-to-line` - arrow up to line
 * * `arrow-up-triangle-square` - arrow up triangle square
 * * `arrow-up-wide-short` - arrow up wide short
 * * `arrow-up-z-a` - arrow up z a
 * * `arrow-up` - arrow up
 * * `arrows-cross` - arrows cross
 * * `arrows-down-to-line` - arrows down to line
 * * `arrows-down-to-people` - arrows down to people
 * * `arrows-from-dotted-line` - arrows from dotted line
 * * `arrows-from-line` - arrows from line
 * * `arrows-left-right-to-line` - arrows left right to line
 * * `arrows-left-right` - arrows left right
 * * `arrows-maximize` - arrows maximize
 * * `arrows-minimize` - arrows minimize
 * * `arrows-repeat-1` - arrows repeat 1
 * * `arrows-repeat` - arrows repeat
 * * `arrows-retweet` - arrows retweet
 * * `arrows-rotate` - arrows rotate
 * * `arrows-spin` - arrows spin
 * * `arrows-split-up-and-left` - arrows split up and left
 * * `arrows-to-circle` - arrows to circle
 * * `arrows-to-dot` - arrows to dot
 * * `arrows-to-dotted-line` - arrows to dotted line
 * * `arrows-to-eye` - arrows to eye
 * * `arrows-to-line` - arrows to line
 * * `arrows-turn-right` - arrows turn right
 * * `arrows-turn-to-dots` - arrows turn to dots
 * * `arrows-up-down-left-right` - arrows up down left right
 * * `arrows-up-down` - arrows up down
 * * `arrows-up-to-line` - arrows up to line
 * * `asterisk` - asterisk
 * * `at` - at
 * * `atom-simple` - atom simple
 * * `atom` - atom
 * * `audio-description-slash` - audio description slash
 * * `audio-description` - audio description
 * * `austral-sign` - austral sign
 * * `avocado` - avocado
 * * `award-simple` - award simple
 * * `award` - award
 * * `axe-battle` - axe battle
 * * `axe` - axe
 * * `b` - b
 * * `baby-carriage` - baby carriage
 * * `baby` - baby
 * * `backpack` - backpack
 * * `backward-fast` - backward fast
 * * `backward-step` - backward step
 * * `backward` - backward
 * * `bacon` - bacon
 * * `bacteria` - bacteria
 * * `bacterium` - bacterium
 * * `badge-check` - badge check
 * * `badge-dollar` - badge dollar
 * * `badge-percent` - badge percent
 * * `badge-sheriff` - badge sheriff
 * * `badge` - badge
 * * `badger-honey` - badger honey
 * * `badminton` - badminton
 * * `bag-shopping` - bag shopping
 * * `bagel` - bagel
 * * `bags-shopping` - bags shopping
 * * `baguette` - baguette
 * * `bahai` - bahai
 * * `baht-sign` - baht sign
 * * `ball-pile` - ball pile
 * * `balloon` - balloon
 * * `balloons` - balloons
 * * `ballot-check` - ballot check
 * * `ballot` - ballot
 * * `ban-bug` - ban bug
 * * `ban-parking` - ban parking
 * * `ban-smoking` - ban smoking
 * * `ban` - ban
 * * `banana` - banana
 * * `bandage` - bandage
 * * `bangladeshi-taka-sign` - bangladeshi taka sign
 * * `banjo` - banjo
 * * `barcode-read` - barcode read
 * * `barcode-scan` - barcode scan
 * * `barcode` - barcode
 * * `bars-filter` - bars filter
 * * `bars-progress` - bars progress
 * * `bars-sort` - bars sort
 * * `bars-staggered` - bars staggered
 * * `bars` - bars
 * * `baseball-bat-ball` - baseball bat ball
 * * `baseball` - baseball
 * * `basket-shopping-simple` - basket shopping simple
 * * `basket-shopping` - basket shopping
 * * `basketball-hoop` - basketball hoop
 * * `basketball` - basketball
 * * `bat` - bat
 * * `bath` - bath
 * * `battery-bolt` - battery bolt
 * * `battery-empty` - battery empty
 * * `battery-exclamation` - battery exclamation
 * * `battery-full` - battery full
 * * `battery-half` - battery half
 * * `battery-low` - battery low
 * * `battery-quarter` - battery quarter
 * * `battery-slash` - battery slash
 * * `battery-three-quarters` - battery three quarters
 * * `bed-bunk` - bed bunk
 * * `bed-empty` - bed empty
 * * `bed-front` - bed front
 * * `bed-pulse` - bed pulse
 * * `bed` - bed
 * * `bee` - bee
 * * `beer-mug-empty` - beer mug empty
 * * `beer-mug` - beer mug
 * * `bell-concierge` - bell concierge
 * * `bell-exclamation` - bell exclamation
 * * `bell-on` - bell on
 * * `bell-plus` - bell plus
 * * `bell-school-slash` - bell school slash
 * * `bell-school` - bell school
 * * `bell-slash` - bell slash
 * * `bell` - bell
 * * `bells` - bells
 * * `bench-tree` - bench tree
 * * `bezier-curve` - bezier curve
 * * `bicycle` - bicycle
 * * `binary-circle-check` - binary circle check
 * * `binary-lock` - binary lock
 * * `binary-slash` - binary slash
 * * `binary` - binary
 * * `binoculars` - binoculars
 * * `biohazard` - biohazard
 * * `bird` - bird
 * * `bitcoin-sign` - bitcoin sign
 * * `blanket-fire` - blanket fire
 * * `blanket` - blanket
 * * `blender-phone` - blender phone
 * * `blender` - blender
 * * `blinds-open` - blinds open
 * * `blinds-raised` - blinds raised
 * * `blinds` - blinds
 * * `block-brick-fire` - block brick fire
 * * `block-brick` - block brick
 * * `block-question` - block question
 * * `block-quote` - block quote
 * * `block` - block
 * * `blog` - blog
 * * `blueberries` - blueberries
 * * `bluetooth` - bluetooth
 * * `bold` - bold
 * * `bolt-auto` - bolt auto
 * * `bolt-lightning` - bolt lightning
 * * `bolt-slash` - bolt slash
 * * `bolt` - bolt
 * * `bomb` - bomb
 * * `bone-break` - bone break
 * * `bone` - bone
 * * `bong` - bong
 * * `book-arrow-right` - book arrow right
 * * `book-arrow-up` - book arrow up
 * * `book-atlas` - book atlas
 * * `book-bible` - book bible
 * * `book-blank` - book blank
 * * `book-bookmark` - book bookmark
 * * `book-circle-arrow-right` - book circle arrow right
 * * `book-circle-arrow-up` - book circle arrow up
 * * `book-copy` - book copy
 * * `book-font` - book font
 * * `book-heart` - book heart
 * * `book-journal-whills` - book journal whills
 * * `book-medical` - book medical
 * * `book-open-cover` - book open cover
 * * `book-open-reader` - book open reader
 * * `book-open` - book open
 * * `book-quran` - book quran
 * * `book-section` - book section
 * * `book-skull` - book skull
 * * `book-sparkles` - book sparkles
 * * `book-tanakh` - book tanakh
 * * `book-user` - book user
 * * `book` - book
 * * `bookmark-slash` - bookmark slash
 * * `bookmark` - bookmark
 * * `books-medical` - books medical
 * * `books` - books
 * * `boombox` - boombox
 * * `boot-heeled` - boot heeled
 * * `boot` - boot
 * * `booth-curtain` - booth curtain
 * * `border-all` - border all
 * * `border-bottom-right` - border bottom right
 * * `border-bottom` - border bottom
 * * `border-center-h` - border center h
 * * `border-center-v` - border center v
 * * `border-inner` - border inner
 * * `border-left` - border left
 * * `border-none` - border none
 * * `border-outer` - border outer
 * * `border-right` - border right
 * * `border-top-left` - border top left
 * * `border-top` - border top
 * * `bore-hole` - bore hole
 * * `bottle-droplet` - bottle droplet
 * * `bottle-water` - bottle water
 * * `bow-arrow` - bow arrow
 * * `bowl-chopsticks-noodles` - bowl chopsticks noodles
 * * `bowl-chopsticks` - bowl chopsticks
 * * `bowl-food` - bowl food
 * * `bowl-hot` - bowl hot
 * * `bowl-rice` - bowl rice
 * * `bowl-scoop` - bowl scoop
 * * `bowl-scoops` - bowl scoops
 * * `bowl-soft-serve` - bowl soft serve
 * * `bowl-spoon` - bowl spoon
 * * `bowling-ball-pin` - bowling ball pin
 * * `bowling-ball` - bowling ball
 * * `bowling-pins` - bowling pins
 * * `box-archive` - box archive
 * * `box-ballot` - box ballot
 * * `box-check` - box check
 * * `box-circle-check` - box circle check
 * * `box-dollar` - box dollar
 * * `box-heart` - box heart
 * * `box-open-full` - box open full
 * * `box-open` - box open
 * * `box-taped` - box taped
 * * `box-tissue` - box tissue
 * * `box` - box
 * * `boxes-packing` - boxes packing
 * * `boxes-stacked` - boxes stacked
 * * `boxing-glove` - boxing glove
 * * `bracket-curly-right` - bracket curly right
 * * `bracket-curly` - bracket curly
 * * `bracket-round-right` - bracket round right
 * * `bracket-round` - bracket round
 * * `bracket-square-right` - bracket square right
 * * `bracket-square` - bracket square
 * * `brackets-curly` - brackets curly
 * * `brackets-round` - brackets round
 * * `brackets-square` - brackets square
 * * `braille` - braille
 * * `brain-arrow-curved-right` - brain arrow curved right
 * * `brain-circuit` - brain circuit
 * * `brain` - brain
 * * `brake-warning` - brake warning
 * * `brazilian-real-sign` - brazilian real sign
 * * `bread-loaf` - bread loaf
 * * `bread-slice-butter` - bread slice butter
 * * `bread-slice` - bread slice
 * * `bridge-circle-check` - bridge circle check
 * * `bridge-circle-exclamation` - bridge circle exclamation
 * * `bridge-circle-xmark` - bridge circle xmark
 * * `bridge-lock` - bridge lock
 * * `bridge-suspension` - bridge suspension
 * * `bridge-water` - bridge water
 * * `bridge` - bridge
 * * `briefcase-arrow-right` - briefcase arrow right
 * * `briefcase-blank` - briefcase blank
 * * `briefcase-medical` - briefcase medical
 * * `briefcase` - briefcase
 * * `brightness-low` - brightness low
 * * `brightness` - brightness
 * * `bring-forward` - bring forward
 * * `bring-front` - bring front
 * * `broccoli` - broccoli
 * * `broom-ball` - broom ball
 * * `broom` - broom
 * * `browser` - browser
 * * `browsers` - browsers
 * * `brush` - brush
 * * `bucket` - bucket
 * * `bug-slash` - bug slash
 * * `bug` - bug
 * * `bugs` - bugs
 * * `building-circle-arrow-right` - building circle arrow right
 * * `building-circle-check` - building circle check
 * * `building-circle-exclamation` - building circle exclamation
 * * `building-circle-xmark` - building circle xmark
 * * `building-columns` - building columns
 * * `building-flag` - building flag
 * * `building-lock` - building lock
 * * `building-ngo` - building ngo
 * * `building-shield` - building shield
 * * `building-un` - building un
 * * `building-user` - building user
 * * `building-wheat` - building wheat
 * * `building` - building
 * * `buildings` - buildings
 * * `bullhorn` - bullhorn
 * * `bullseye-arrow` - bullseye arrow
 * * `bullseye-pointer` - bullseye pointer
 * * `bullseye` - bullseye
 * * `buoy-mooring` - buoy mooring
 * * `buoy` - buoy
 * * `burger-cheese` - burger cheese
 * * `burger-fries` - burger fries
 * * `burger-glass` - burger glass
 * * `burger-lettuce` - burger lettuce
 * * `burger-soda` - burger soda
 * * `burger` - burger
 * * `burrito` - burrito
 * * `burst` - burst
 * * `bus-school` - bus school
 * * `bus-simple` - bus simple
 * * `bus` - bus
 * * `business-time` - business time
 * * `butter` - butter
 * * `c` - c
 * * `cabin` - cabin
 * * `cabinet-filing` - cabinet filing
 * * `cable-car` - cable car
 * * `cactus` - cactus
 * * `cake-candles` - cake candles
 * * `cake-slice` - cake slice
 * * `calculator-simple` - calculator simple
 * * `calculator` - calculator
 * * `calendar-arrow-down` - calendar arrow down
 * * `calendar-arrow-up` - calendar arrow up
 * * `calendar-check` - calendar check
 * * `calendar-circle-exclamation` - calendar circle exclamation
 * * `calendar-circle-minus` - calendar circle minus
 * * `calendar-circle-plus` - calendar circle plus
 * * `calendar-circle-user` - calendar circle user
 * * `calendar-clock` - calendar clock
 * * `calendar-day` - calendar day
 * * `calendar-days` - calendar days
 * * `calendar-exclamation` - calendar exclamation
 * * `calendar-heart` - calendar heart
 * * `calendar-image` - calendar image
 * * `calendar-lines-pen` - calendar lines pen
 * * `calendar-lines` - calendar lines
 * * `calendar-minus` - calendar minus
 * * `calendar-pen` - calendar pen
 * * `calendar-plus` - calendar plus
 * * `calendar-range` - calendar range
 * * `calendar-star` - calendar star
 * * `calendar-week` - calendar week
 * * `calendar-xmark` - calendar xmark
 * * `calendar` - calendar
 * * `calendars` - calendars
 * * `camcorder` - camcorder
 * * `camera-cctv` - camera cctv
 * * `camera-movie` - camera movie
 * * `camera-polaroid` - camera polaroid
 * * `camera-retro` - camera retro
 * * `camera-rotate` - camera rotate
 * * `camera-security` - camera security
 * * `camera-slash` - camera slash
 * * `camera-viewfinder` - camera viewfinder
 * * `camera-web-slash` - camera web slash
 * * `camera-web` - camera web
 * * `camera` - camera
 * * `campfire` - campfire
 * * `campground` - campground
 * * `can-food` - can food
 * * `candle-holder` - candle holder
 * * `candy-bar` - candy bar
 * * `candy-cane` - candy cane
 * * `candy-corn` - candy corn
 * * `candy` - candy
 * * `cannabis` - cannabis
 * * `capsules` - capsules
 * * `car-battery` - car battery
 * * `car-bolt` - car bolt
 * * `car-building` - car building
 * * `car-bump` - car bump
 * * `car-burst` - car burst
 * * `car-bus` - car bus
 * * `car-circle-bolt` - car circle bolt
 * * `car-garage` - car garage
 * * `car-mirrors` - car mirrors
 * * `car-on` - car on
 * * `car-rear` - car rear
 * * `car-side-bolt` - car side bolt
 * * `car-side` - car side
 * * `car-tilt` - car tilt
 * * `car-tunnel` - car tunnel
 * * `car-wash` - car wash
 * * `car-wrench` - car wrench
 * * `car` - car
 * * `caravan-simple` - caravan simple
 * * `caravan` - caravan
 * * `card-club` - card club
 * * `card-diamond` - card diamond
 * * `card-heart` - card heart
 * * `card-spade` - card spade
 * * `cards-blank` - cards blank
 * * `cards` - cards
 * * `caret-down` - caret down
 * * `caret-left` - caret left
 * * `caret-right` - caret right
 * * `caret-up` - caret up
 * * `carrot` - carrot
 * * `cars` - cars
 * * `cart-arrow-down` - cart arrow down
 * * `cart-arrow-up` - cart arrow up
 * * `cart-circle-arrow-down` - cart circle arrow down
 * * `cart-circle-arrow-up` - cart circle arrow up
 * * `cart-circle-check` - cart circle check
 * * `cart-circle-exclamation` - cart circle exclamation
 * * `cart-circle-plus` - cart circle plus
 * * `cart-circle-xmark` - cart circle xmark
 * * `cart-flatbed-boxes` - cart flatbed boxes
 * * `cart-flatbed-empty` - cart flatbed empty
 * * `cart-flatbed-suitcase` - cart flatbed suitcase
 * * `cart-flatbed` - cart flatbed
 * * `cart-minus` - cart minus
 * * `cart-plus` - cart plus
 * * `cart-shopping-fast` - cart shopping fast
 * * `cart-shopping` - cart shopping
 * * `cart-xmark` - cart xmark
 * * `cash-register` - cash register
 * * `cassette-betamax` - cassette betamax
 * * `cassette-tape` - cassette tape
 * * `cassette-vhs` - cassette vhs
 * * `castle` - castle
 * * `cat-space` - cat space
 * * `cat` - cat
 * * `cauldron` - cauldron
 * * `cedi-sign` - cedi sign
 * * `cent-sign` - cent sign
 * * `certificate` - certificate
 * * `chair-office` - chair office
 * * `chair` - chair
 * * `chalkboard-user` - chalkboard user
 * * `chalkboard` - chalkboard
 * * `champagne-glass` - champagne glass
 * * `champagne-glasses` - champagne glasses
 * * `charging-station` - charging station
 * * `chart-area` - chart area
 * * `chart-bar` - chart bar
 * * `chart-bullet` - chart bullet
 * * `chart-candlestick` - chart candlestick
 * * `chart-column` - chart column
 * * `chart-gantt` - chart gantt
 * * `chart-line-down` - chart line down
 * * `chart-line-up` - chart line up
 * * `chart-line` - chart line
 * * `chart-mixed` - chart mixed
 * * `chart-network` - chart network
 * * `chart-pie-simple` - chart pie simple
 * * `chart-pie` - chart pie
 * * `chart-pyramid` - chart pyramid
 * * `chart-radar` - chart radar
 * * `chart-scatter-3d` - chart scatter 3d
 * * `chart-scatter-bubble` - chart scatter bubble
 * * `chart-scatter` - chart scatter
 * * `chart-simple-horizontal` - chart simple horizontal
 * * `chart-simple` - chart simple
 * * `chart-tree-map` - chart tree map
 * * `chart-user` - chart user
 * * `chart-waterfall` - chart waterfall
 * * `check-double` - check double
 * * `check-to-slot` - check to slot
 * * `check` - check
 * * `cheese-swiss` - cheese swiss
 * * `cheese` - cheese
 * * `cherries` - cherries
 * * `chess-bishop-piece` - chess bishop piece
 * * `chess-bishop` - chess bishop
 * * `chess-board` - chess board
 * * `chess-clock-flip` - chess clock flip
 * * `chess-clock` - chess clock
 * * `chess-king-piece` - chess king piece
 * * `chess-king` - chess king
 * * `chess-knight-piece` - chess knight piece
 * * `chess-knight` - chess knight
 * * `chess-pawn-piece` - chess pawn piece
 * * `chess-pawn` - chess pawn
 * * `chess-queen-piece` - chess queen piece
 * * `chess-queen` - chess queen
 * * `chess-rook-piece` - chess rook piece
 * * `chess-rook` - chess rook
 * * `chess` - chess
 * * `chestnut` - chestnut
 * * `chevron-down` - chevron down
 * * `chevron-left` - chevron left
 * * `chevron-right` - chevron right
 * * `chevron-up` - chevron up
 * * `chevrons-down` - chevrons down
 * * `chevrons-left` - chevrons left
 * * `chevrons-right` - chevrons right
 * * `chevrons-up` - chevrons up
 * * `child-combatant` - child combatant
 * * `child-dress` - child dress
 * * `child-reaching` - child reaching
 * * `child` - child
 * * `children` - children
 * * `chimney` - chimney
 * * `chopsticks` - chopsticks
 * * `church` - church
 * * `circle-0` - circle 0
 * * `circle-1` - circle 1
 * * `circle-2` - circle 2
 * * `circle-3` - circle 3
 * * `circle-4` - circle 4
 * * `circle-5` - circle 5
 * * `circle-6` - circle 6
 * * `circle-7` - circle 7
 * * `circle-8` - circle 8
 * * `circle-9` - circle 9
 * * `circle-a` - circle a
 * * `circle-ampersand` - circle ampersand
 * * `circle-arrow-down-left` - circle arrow down left
 * * `circle-arrow-down-right` - circle arrow down right
 * * `circle-arrow-down` - circle arrow down
 * * `circle-arrow-left` - circle arrow left
 * * `circle-arrow-right` - circle arrow right
 * * `circle-arrow-up-left` - circle arrow up left
 * * `circle-arrow-up-right` - circle arrow up right
 * * `circle-arrow-up` - circle arrow up
 * * `circle-b` - circle b
 * * `circle-bolt` - circle bolt
 * * `circle-book-open` - circle book open
 * * `circle-bookmark` - circle bookmark
 * * `circle-c` - circle c
 * * `circle-calendar` - circle calendar
 * * `circle-camera` - circle camera
 * * `circle-caret-down` - circle caret down
 * * `circle-caret-left` - circle caret left
 * * `circle-caret-right` - circle caret right
 * * `circle-caret-up` - circle caret up
 * * `circle-check` - circle check
 * * `circle-chevron-down` - circle chevron down
 * * `circle-chevron-left` - circle chevron left
 * * `circle-chevron-right` - circle chevron right
 * * `circle-chevron-up` - circle chevron up
 * * `circle-d` - circle d
 * * `circle-dashed` - circle dashed
 * * `circle-divide` - circle divide
 * * `circle-dollar-to-slot` - circle dollar to slot
 * * `circle-dollar` - circle dollar
 * * `circle-dot` - circle dot
 * * `circle-down-left` - circle down left
 * * `circle-down-right` - circle down right
 * * `circle-down` - circle down
 * * `circle-e` - circle e
 * * `circle-ellipsis-vertical` - circle ellipsis vertical
 * * `circle-ellipsis` - circle ellipsis
 * * `circle-envelope` - circle envelope
 * * `circle-exclamation-check` - circle exclamation check
 * * `circle-exclamation` - circle exclamation
 * * `circle-f` - circle f
 * * `circle-g` - circle g
 * * `circle-h` - circle h
 * * `circle-half-stroke` - circle half stroke
 * * `circle-half` - circle half
 * * `circle-heart` - circle heart
 * * `circle-i` - circle i
 * * `circle-info` - circle info
 * * `circle-j` - circle j
 * * `circle-k` - circle k
 * * `circle-l` - circle l
 * * `circle-left` - circle left
 * * `circle-location-arrow` - circle location arrow
 * * `circle-m` - circle m
 * * `circle-microphone-lines` - circle microphone lines
 * * `circle-microphone` - circle microphone
 * * `circle-minus` - circle minus
 * * `circle-n` - circle n
 * * `circle-nodes` - circle nodes
 * * `circle-notch` - circle notch
 * * `circle-o` - circle o
 * * `circle-p` - circle p
 * * `circle-parking` - circle parking
 * * `circle-pause` - circle pause
 * * `circle-phone-flip` - circle phone flip
 * * `circle-phone-hangup` - circle phone hangup
 * * `circle-phone` - circle phone
 * * `circle-play` - circle play
 * * `circle-plus` - circle plus
 * * `circle-q` - circle q
 * * `circle-quarter` - circle quarter
 * * `circle-quarters` - circle quarters
 * * `circle-question` - circle question
 * * `circle-r` - circle r
 * * `circle-radiation` - circle radiation
 * * `circle-right` - circle right
 * * `circle-s` - circle s
 * * `circle-small` - circle small
 * * `circle-sort-down` - circle sort down
 * * `circle-sort-up` - circle sort up
 * * `circle-sort` - circle sort
 * * `circle-star` - circle star
 * * `circle-stop` - circle stop
 * * `circle-t` - circle t
 * * `circle-three-quarters` - circle three quarters
 * * `circle-trash` - circle trash
 * * `circle-u` - circle u
 * * `circle-up-left` - circle up left
 * * `circle-up-right` - circle up right
 * * `circle-up` - circle up
 * * `circle-user` - circle user
 * * `circle-v` - circle v
 * * `circle-video` - circle video
 * * `circle-w` - circle w
 * * `circle-waveform-lines` - circle waveform lines
 * * `circle-x` - circle x
 * * `circle-xmark` - circle xmark
 * * `circle-y` - circle y
 * * `circle-z` - circle z
 * * `circle` - circle
 * * `citrus-slice` - citrus slice
 * * `citrus` - citrus
 * * `city` - city
 * * `clapperboard-play` - clapperboard play
 * * `clapperboard` - clapperboard
 * * `clarinet` - clarinet
 * * `claw-marks` - claw marks
 * * `clipboard-check` - clipboard check
 * * `clipboard-list-check` - clipboard list check
 * * `clipboard-list` - clipboard list
 * * `clipboard-medical` - clipboard medical
 * * `clipboard-prescription` - clipboard prescription
 * * `clipboard-question` - clipboard question
 * * `clipboard-user` - clipboard user
 * * `clipboard` - clipboard
 * * `clock-desk` - clock desk
 * * `clock-eight-thirty` - clock eight thirty
 * * `clock-eight` - clock eight
 * * `clock-eleven-thirty` - clock eleven thirty
 * * `clock-eleven` - clock eleven
 * * `clock-five-thirty` - clock five thirty
 * * `clock-five` - clock five
 * * `clock-four-thirty` - clock four thirty
 * * `clock-nine-thirty` - clock nine thirty
 * * `clock-nine` - clock nine
 * * `clock-one-thirty` - clock one thirty
 * * `clock-one` - clock one
 * * `clock-rotate-left` - clock rotate left
 * * `clock-seven-thirty` - clock seven thirty
 * * `clock-seven` - clock seven
 * * `clock-six-thirty` - clock six thirty
 * * `clock-six` - clock six
 * * `clock-ten-thirty` - clock ten thirty
 * * `clock-ten` - clock ten
 * * `clock-three-thirty` - clock three thirty
 * * `clock-three` - clock three
 * * `clock-twelve-thirty` - clock twelve thirty
 * * `clock-twelve` - clock twelve
 * * `clock-two-thirty` - clock two thirty
 * * `clock-two` - clock two
 * * `clock` - clock
 * * `clone` - clone
 * * `closed-captioning-slash` - closed captioning slash
 * * `closed-captioning` - closed captioning
 * * `clothes-hanger` - clothes hanger
 * * `cloud-arrow-down` - cloud arrow down
 * * `cloud-arrow-up` - cloud arrow up
 * * `cloud-bolt-moon` - cloud bolt moon
 * * `cloud-bolt-sun` - cloud bolt sun
 * * `cloud-bolt` - cloud bolt
 * * `cloud-check` - cloud check
 * * `cloud-drizzle` - cloud drizzle
 * * `cloud-exclamation` - cloud exclamation
 * * `cloud-fog` - cloud fog
 * * `cloud-hail-mixed` - cloud hail mixed
 * * `cloud-hail` - cloud hail
 * * `cloud-meatball` - cloud meatball
 * * `cloud-minus` - cloud minus
 * * `cloud-moon-rain` - cloud moon rain
 * * `cloud-moon` - cloud moon
 * * `cloud-music` - cloud music
 * * `cloud-plus` - cloud plus
 * * `cloud-question` - cloud question
 * * `cloud-rain` - cloud rain
 * * `cloud-rainbow` - cloud rainbow
 * * `cloud-showers-heavy` - cloud showers heavy
 * * `cloud-showers-water` - cloud showers water
 * * `cloud-showers` - cloud showers
 * * `cloud-slash` - cloud slash
 * * `cloud-sleet` - cloud sleet
 * * `cloud-snow` - cloud snow
 * * `cloud-sun-rain` - cloud sun rain
 * * `cloud-sun` - cloud sun
 * * `cloud-word` - cloud word
 * * `cloud-xmark` - cloud xmark
 * * `cloud` - cloud
 * * `clouds-moon` - clouds moon
 * * `clouds-sun` - clouds sun
 * * `clouds` - clouds
 * * `clover` - clover
 * * `club` - club
 * * `coconut` - coconut
 * * `code-branch` - code branch
 * * `code-commit` - code commit
 * * `code-compare` - code compare
 * * `code-fork` - code fork
 * * `code-merge` - code merge
 * * `code-pull-request-closed` - code pull request closed
 * * `code-pull-request-draft` - code pull request draft
 * * `code-pull-request` - code pull request
 * * `code-simple` - code simple
 * * `code` - code
 * * `coffee-bean` - coffee bean
 * * `coffee-beans` - coffee beans
 * * `coffee-pot` - coffee pot
 * * `coffin-cross` - coffin cross
 * * `coffin` - coffin
 * * `coin-blank` - coin blank
 * * `coin-front` - coin front
 * * `coin-vertical` - coin vertical
 * * `coin` - coin
 * * `coins` - coins
 * * `colon-sign` - colon sign
 * * `colon` - colon
 * * `columns-3` - columns 3
 * * `comet` - comet
 * * `comma` - comma
 * * `command` - command
 * * `comment-arrow-down` - comment arrow down
 * * `comment-arrow-up-right` - comment arrow up right
 * * `comment-arrow-up` - comment arrow up
 * * `comment-captions` - comment captions
 * * `comment-check` - comment check
 * * `comment-code` - comment code
 * * `comment-dollar` - comment dollar
 * * `comment-dots` - comment dots
 * * `comment-exclamation` - comment exclamation
 * * `comment-heart` - comment heart
 * * `comment-image` - comment image
 * * `comment-lines` - comment lines
 * * `comment-medical` - comment medical
 * * `comment-middle-top` - comment middle top
 * * `comment-middle` - comment middle
 * * `comment-minus` - comment minus
 * * `comment-music` - comment music
 * * `comment-pen` - comment pen
 * * `comment-plus` - comment plus
 * * `comment-question` - comment question
 * * `comment-quote` - comment quote
 * * `comment-slash` - comment slash
 * * `comment-smile` - comment smile
 * * `comment-sms` - comment sms
 * * `comment-text` - comment text
 * * `comment-xmark` - comment xmark
 * * `comment` - comment
 * * `comments-dollar` - comments dollar
 * * `comments-question-check` - comments question check
 * * `comments-question` - comments question
 * * `comments` - comments
 * * `compact-disc` - compact disc
 * * `compass-drafting` - compass drafting
 * * `compass-slash` - compass slash
 * * `compass` - compass
 * * `compress-wide` - compress wide
 * * `compress` - compress
 * * `computer-classic` - computer classic
 * * `computer-mouse-scrollwheel` - computer mouse scrollwheel
 * * `computer-mouse` - computer mouse
 * * `computer-speaker` - computer speaker
 * * `computer` - computer
 * * `container-storage` - container storage
 * * `conveyor-belt-boxes` - conveyor belt boxes
 * * `conveyor-belt-empty` - conveyor belt empty
 * * `conveyor-belt` - conveyor belt
 * * `cookie-bite` - cookie bite
 * * `cookie` - cookie
 * * `copy` - copy
 * * `copyright` - copyright
 * * `corn` - corn
 * * `corner` - corner
 * * `couch` - couch
 * * `cow` - cow
 * * `cowbell-circle-plus` - cowbell circle plus
 * * `cowbell` - cowbell
 * * `crab` - crab
 * * `crate-apple` - crate apple
 * * `crate-empty` - crate empty
 * * `credit-card-blank` - credit card blank
 * * `credit-card-front` - credit card front
 * * `credit-card` - credit card
 * * `cricket-bat-ball` - cricket bat ball
 * * `croissant` - croissant
 * * `crop-simple` - crop simple
 * * `crop` - crop
 * * `cross` - cross
 * * `crosshairs-simple` - crosshairs simple
 * * `crosshairs` - crosshairs
 * * `crow` - crow
 * * `crown` - crown
 * * `crutch` - crutch
 * * `crutches` - crutches
 * * `cruzeiro-sign` - cruzeiro sign
 * * `crystal-ball` - crystal ball
 * * `cube` - cube
 * * `cubes-stacked` - cubes stacked
 * * `cubes` - cubes
 * * `cucumber` - cucumber
 * * `cup-straw-swoosh` - cup straw swoosh
 * * `cup-straw` - cup straw
 * * `cup-togo` - cup togo
 * * `cupcake` - cupcake
 * * `curling-stone` - curling stone
 * * `custard` - custard
 * * `d` - d
 * * `dagger` - dagger
 * * `dash` - dash
 * * `database` - database
 * * `deer-rudolph` - deer rudolph
 * * `deer` - deer
 * * `delete-left` - delete left
 * * `delete-right` - delete right
 * * `democrat` - democrat
 * * `desktop-arrow-down` - desktop arrow down
 * * `desktop` - desktop
 * * `dharmachakra` - dharmachakra
 * * `diagram-cells` - diagram cells
 * * `diagram-lean-canvas` - diagram lean canvas
 * * `diagram-nested` - diagram nested
 * * `diagram-next` - diagram next
 * * `diagram-predecessor` - diagram predecessor
 * * `diagram-previous` - diagram previous
 * * `diagram-project` - diagram project
 * * `diagram-sankey` - diagram sankey
 * * `diagram-subtask` - diagram subtask
 * * `diagram-successor` - diagram successor
 * * `diagram-venn` - diagram venn
 * * `dial-high` - dial high
 * * `dial-low` - dial low
 * * `dial-max` - dial max
 * * `dial-med-low` - dial med low
 * * `dial-med` - dial med
 * * `dial-min` - dial min
 * * `dial-off` - dial off
 * * `dial` - dial
 * * `diamond-exclamation` - diamond exclamation
 * * `diamond-half-stroke` - diamond half stroke
 * * `diamond-half` - diamond half
 * * `diamond-turn-right` - diamond turn right
 * * `diamond` - diamond
 * * `dice-d10` - dice d10
 * * `dice-d12` - dice d12
 * * `dice-d20` - dice d20
 * * `dice-d4` - dice d4
 * * `dice-d6` - dice d6
 * * `dice-d8` - dice d8
 * * `dice-five` - dice five
 * * `dice-four` - dice four
 * * `dice-one` - dice one
 * * `dice-six` - dice six
 * * `dice-three` - dice three
 * * `dice-two` - dice two
 * * `dice` - dice
 * * `diploma` - diploma
 * * `disc-drive` - disc drive
 * * `disease` - disease
 * * `display-arrow-down` - display arrow down
 * * `display-code` - display code
 * * `display-medical` - display medical
 * * `display-slash` - display slash
 * * `display` - display
 * * `distribute-spacing-horizontal` - distribute spacing horizontal
 * * `distribute-spacing-vertical` - distribute spacing vertical
 * * `ditto` - ditto
 * * `divide` - divide
 * * `dna` - dna
 * * `do-not-enter` - do not enter
 * * `dog-leashed` - dog leashed
 * * `dog` - dog
 * * `dollar-sign` - dollar sign
 * * `dolly-empty` - dolly empty
 * * `dolly` - dolly
 * * `dolphin` - dolphin
 * * `dong-sign` - dong sign
 * * `donut` - donut
 * * `door-closed` - door closed
 * * `door-open` - door open
 * * `dove` - dove
 * * `down-from-dotted-line` - down from dotted line
 * * `down-from-line` - down from line
 * * `down-left-and-up-right-to-center` - down left and up right to center
 * * `down-left` - down left
 * * `down-long` - down long
 * * `down-right` - down right
 * * `down-to-bracket` - down to bracket
 * * `down-to-dotted-line` - down to dotted line
 * * `down-to-line` - down to line
 * * `down` - down
 * * `download` - download
 * * `dragon` - dragon
 * * `draw-circle` - draw circle
 * * `draw-polygon` - draw polygon
 * * `draw-square` - draw square
 * * `dreidel` - dreidel
 * * `drone-front` - drone front
 * * `drone` - drone
 * * `droplet-degree` - droplet degree
 * * `droplet-percent` - droplet percent
 * * `droplet-slash` - droplet slash
 * * `droplet` - droplet
 * * `drum-steelpan` - drum steelpan
 * * `drum` - drum
 * * `drumstick-bite` - drumstick bite
 * * `drumstick` - drumstick
 * * `dryer-heat` - dryer heat
 * * `dryer` - dryer
 * * `duck` - duck
 * * `dumbbell` - dumbbell
 * * `dumpster-fire` - dumpster fire
 * * `dumpster` - dumpster
 * * `dungeon` - dungeon
 * * `e` - e
 * * `ear-deaf` - ear deaf
 * * `ear-listen` - ear listen
 * * `ear-muffs` - ear muffs
 * * `ear` - ear
 * * `earth-africa` - earth africa
 * * `earth-americas` - earth americas
 * * `earth-asia` - earth asia
 * * `earth-europe` - earth europe
 * * `earth-oceania` - earth oceania
 * * `eclipse` - eclipse
 * * `egg-fried` - egg fried
 * * `egg` - egg
 * * `eggplant` - eggplant
 * * `eject` - eject
 * * `elephant` - elephant
 * * `elevator` - elevator
 * * `ellipsis-stroke-vertical` - ellipsis stroke vertical
 * * `ellipsis-stroke` - ellipsis stroke
 * * `ellipsis-vertical` - ellipsis vertical
 * * `ellipsis` - ellipsis
 * * `empty-set` - empty set
 * * `engine-warning` - engine warning
 * * `engine` - engine
 * * `envelope-circle-check` - envelope circle check
 * * `envelope-dot` - envelope dot
 * * `envelope-open-dollar` - envelope open dollar
 * * `envelope-open-text` - envelope open text
 * * `envelope-open` - envelope open
 * * `envelope` - envelope
 * * `envelopes-bulk` - envelopes bulk
 * * `envelopes` - envelopes
 * * `equals` - equals
 * * `eraser` - eraser
 * * `escalator` - escalator
 * * `ethernet` - ethernet
 * * `euro-sign` - euro sign
 * * `exclamation` - exclamation
 * * `expand-wide` - expand wide
 * * `expand` - expand
 * * `explosion` - explosion
 * * `eye-dropper-full` - eye dropper full
 * * `eye-dropper-half` - eye dropper half
 * * `eye-dropper` - eye dropper
 * * `eye-evil` - eye evil
 * * `eye-low-vision` - eye low vision
 * * `eye-slash` - eye slash
 * * `eye` - eye
 * * `eyes` - eyes
 * * `f` - f
 * * `face-angry-horns` - face angry horns
 * * `face-angry` - face angry
 * * `face-anguished` - face anguished
 * * `face-anxious-sweat` - face anxious sweat
 * * `face-astonished` - face astonished
 * * `face-awesome` - face awesome
 * * `face-beam-hand-over-mouth` - face beam hand over mouth
 * * `face-clouds` - face clouds
 * * `face-confounded` - face confounded
 * * `face-confused` - face confused
 * * `face-cowboy-hat` - face cowboy hat
 * * `face-diagonal-mouth` - face diagonal mouth
 * * `face-disappointed` - face disappointed
 * * `face-disguise` - face disguise
 * * `face-dizzy` - face dizzy
 * * `face-dotted` - face dotted
 * * `face-downcast-sweat` - face downcast sweat
 * * `face-drooling` - face drooling
 * * `face-exhaling` - face exhaling
 * * `face-explode` - face explode
 * * `face-expressionless` - face expressionless
 * * `face-eyes-xmarks` - face eyes xmarks
 * * `face-fearful` - face fearful
 * * `face-flushed` - face flushed
 * * `face-frown-open` - face frown open
 * * `face-frown-slight` - face frown slight
 * * `face-frown` - face frown
 * * `face-glasses` - face glasses
 * * `face-grimace` - face grimace
 * * `face-grin-beam-sweat` - face grin beam sweat
 * * `face-grin-beam` - face grin beam
 * * `face-grin-hearts` - face grin hearts
 * * `face-grin-squint-tears` - face grin squint tears
 * * `face-grin-squint` - face grin squint
 * * `face-grin-stars` - face grin stars
 * * `face-grin-tears` - face grin tears
 * * `face-grin-tongue-squint` - face grin tongue squint
 * * `face-grin-tongue-wink` - face grin tongue wink
 * * `face-grin-tongue` - face grin tongue
 * * `face-grin-wide` - face grin wide
 * * `face-grin-wink` - face grin wink
 * * `face-grin` - face grin
 * * `face-hand-over-mouth` - face hand over mouth
 * * `face-hand-peeking` - face hand peeking
 * * `face-hand-yawn` - face hand yawn
 * * `face-head-bandage` - face head bandage
 * * `face-holding-back-tears` - face holding back tears
 * * `face-hushed` - face hushed
 * * `face-icicles` - face icicles
 * * `face-kiss-beam` - face kiss beam
 * * `face-kiss-closed-eyes` - face kiss closed eyes
 * * `face-kiss-wink-heart` - face kiss wink heart
 * * `face-kiss` - face kiss
 * * `face-laugh-beam` - face laugh beam
 * * `face-laugh-squint` - face laugh squint
 * * `face-laugh-wink` - face laugh wink
 * * `face-laugh` - face laugh
 * * `face-lying` - face lying
 * * `face-mask` - face mask
 * * `face-meh-blank` - face meh blank
 * * `face-meh` - face meh
 * * `face-melting` - face melting
 * * `face-monocle` - face monocle
 * * `face-nauseated` - face nauseated
 * * `face-nose-steam` - face nose steam
 * * `face-party` - face party
 * * `face-pensive` - face pensive
 * * `face-persevering` - face persevering
 * * `face-pleading` - face pleading
 * * `face-pouting` - face pouting
 * * `face-raised-eyebrow` - face raised eyebrow
 * * `face-relieved` - face relieved
 * * `face-rolling-eyes` - face rolling eyes
 * * `face-sad-cry` - face sad cry
 * * `face-sad-sweat` - face sad sweat
 * * `face-sad-tear` - face sad tear
 * * `face-saluting` - face saluting
 * * `face-scream` - face scream
 * * `face-shush` - face shush
 * * `face-sleeping` - face sleeping
 * * `face-sleepy` - face sleepy
 * * `face-smile-beam` - face smile beam
 * * `face-smile-halo` - face smile halo
 * * `face-smile-hearts` - face smile hearts
 * * `face-smile-horns` - face smile horns
 * * `face-smile-plus` - face smile plus
 * * `face-smile-relaxed` - face smile relaxed
 * * `face-smile-tear` - face smile tear
 * * `face-smile-tongue` - face smile tongue
 * * `face-smile-upside-down` - face smile upside down
 * * `face-smile-wink` - face smile wink
 * * `face-smile` - face smile
 * * `face-smiling-hands` - face smiling hands
 * * `face-smirking` - face smirking
 * * `face-spiral-eyes` - face spiral eyes
 * * `face-sunglasses` - face sunglasses
 * * `face-surprise` - face surprise
 * * `face-swear` - face swear
 * * `face-thermometer` - face thermometer
 * * `face-thinking` - face thinking
 * * `face-tired` - face tired
 * * `face-tissue` - face tissue
 * * `face-tongue-money` - face tongue money
 * * `face-tongue-sweat` - face tongue sweat
 * * `face-unamused` - face unamused
 * * `face-viewfinder` - face viewfinder
 * * `face-vomit` - face vomit
 * * `face-weary` - face weary
 * * `face-woozy` - face woozy
 * * `face-worried` - face worried
 * * `face-zany` - face zany
 * * `face-zipper` - face zipper
 * * `falafel` - falafel
 * * `family-dress` - family dress
 * * `family-pants` - family pants
 * * `family` - family
 * * `fan-table` - fan table
 * * `fan` - fan
 * * `farm` - farm
 * * `faucet-drip` - faucet drip
 * * `faucet` - faucet
 * * `fax` - fax
 * * `feather-pointed` - feather pointed
 * * `feather` - feather
 * * `fence` - fence
 * * `ferris-wheel` - ferris wheel
 * * `ferry` - ferry
 * * `field-hockey-stick-ball` - field hockey stick ball
 * * `file-arrow-down` - file arrow down
 * * `file-arrow-up` - file arrow up
 * * `file-audio` - file audio
 * * `file-binary` - file binary
 * * `file-certificate` - file certificate
 * * `file-chart-column` - file chart column
 * * `file-chart-pie` - file chart pie
 * * `file-check` - file check
 * * `file-circle-check` - file circle check
 * * `file-circle-exclamation` - file circle exclamation
 * * `file-circle-info` - file circle info
 * * `file-circle-minus` - file circle minus
 * * `file-circle-plus` - file circle plus
 * * `file-circle-question` - file circle question
 * * `file-circle-xmark` - file circle xmark
 * * `file-code` - file code
 * * `file-contract` - file contract
 * * `file-csv` - file csv
 * * `file-dashed-line` - file dashed line
 * * `file-excel` - file excel
 * * `file-exclamation` - file exclamation
 * * `file-export` - file export
 * * `file-heart` - file heart
 * * `file-image` - file image
 * * `file-import` - file import
 * * `file-invoice-dollar` - file invoice dollar
 * * `file-invoice` - file invoice
 * * `file-lines` - file lines
 * * `file-lock` - file lock
 * * `file-magnifying-glass` - file magnifying glass
 * * `file-medical` - file medical
 * * `file-minus` - file minus
 * * `file-music` - file music
 * * `file-pdf` - file pdf
 * * `file-pen` - file pen
 * * `file-plus-minus` - file plus minus
 * * `file-plus` - file plus
 * * `file-powerpoint` - file powerpoint
 * * `file-prescription` - file prescription
 * * `file-shield` - file shield
 * * `file-signature` - file signature
 * * `file-slash` - file slash
 * * `file-spreadsheet` - file spreadsheet
 * * `file-user` - file user
 * * `file-video` - file video
 * * `file-waveform` - file waveform
 * * `file-word` - file word
 * * `file-xmark` - file xmark
 * * `file-zipper` - file zipper
 * * `file` - file
 * * `files-medical` - files medical
 * * `files` - files
 * * `fill-drip` - fill drip
 * * `fill` - fill
 * * `film-canister` - film canister
 * * `film-simple` - film simple
 * * `film-slash` - film slash
 * * `film` - film
 * * `films` - films
 * * `filter-circle-dollar` - filter circle dollar
 * * `filter-circle-xmark` - filter circle xmark
 * * `filter-list` - filter list
 * * `filter-slash` - filter slash
 * * `filter` - filter
 * * `filters` - filters
 * * `fingerprint` - fingerprint
 * * `fire-burner` - fire burner
 * * `fire-extinguisher` - fire extinguisher
 * * `fire-flame-curved` - fire flame curved
 * * `fire-flame-simple` - fire flame simple
 * * `fire-flame` - fire flame
 * * `fire-hydrant` - fire hydrant
 * * `fire-smoke` - fire smoke
 * * `fire` - fire
 * * `fireplace` - fireplace
 * * `fish-bones` - fish bones
 * * `fish-cooked` - fish cooked
 * * `fish-fins` - fish fins
 * * `fish` - fish
 * * `fishing-rod` - fishing rod
 * * `flag-checkered` - flag checkered
 * * `flag-pennant` - flag pennant
 * * `flag-swallowtail` - flag swallowtail
 * * `flag-usa` - flag usa
 * * `flag` - flag
 * * `flashlight` - flashlight
 * * `flask-round-poison` - flask round poison
 * * `flask-round-potion` - flask round potion
 * * `flask-vial` - flask vial
 * * `flask` - flask
 * * `flatbread-stuffed` - flatbread stuffed
 * * `flatbread` - flatbread
 * * `floppy-disk-circle-arrow-right` - floppy disk circle arrow right
 * * `floppy-disk-circle-xmark` - floppy disk circle xmark
 * * `floppy-disk-pen` - floppy disk pen
 * * `floppy-disk` - floppy disk
 * * `floppy-disks` - floppy disks
 * * `florin-sign` - florin sign
 * * `flower-daffodil` - flower daffodil
 * * `flower-tulip` - flower tulip
 * * `flower` - flower
 * * `flute` - flute
 * * `flux-capacitor` - flux capacitor
 * * `flying-disc` - flying disc
 * * `folder-arrow-down` - folder arrow down
 * * `folder-arrow-up` - folder arrow up
 * * `folder-bookmark` - folder bookmark
 * * `folder-closed` - folder closed
 * * `folder-gear` - folder gear
 * * `folder-grid` - folder grid
 * * `folder-heart` - folder heart
 * * `folder-image` - folder image
 * * `folder-magnifying-glass` - folder magnifying glass
 * * `folder-medical` - folder medical
 * * `folder-minus` - folder minus
 * * `folder-music` - folder music
 * * `folder-open` - folder open
 * * `folder-plus` - folder plus
 * * `folder-tree` - folder tree
 * * `folder-user` - folder user
 * * `folder-xmark` - folder xmark
 * * `folder` - folder
 * * `folders` - folders
 * * `fondue-pot` - fondue pot
 * * `font-awesome` - font awesome
 * * `font-case` - font case
 * * `font` - font
 * * `football-helmet` - football helmet
 * * `football` - football
 * * `fork-knife` - fork knife
 * * `fork` - fork
 * * `forklift` - forklift
 * * `fort` - fort
 * * `forward-fast` - forward fast
 * * `forward-step` - forward step
 * * `forward` - forward
 * * `frame` - frame
 * * `franc-sign` - franc sign
 * * `french-fries` - french fries
 * * `frog` - frog
 * * `function` - function
 * * `futbol` - futbol
 * * `g` - g
 * * `galaxy` - galaxy
 * * `gallery-thumbnails` - gallery thumbnails
 * * `game-board-simple` - game board simple
 * * `game-board` - game board
 * * `game-console-handheld-crank` - game console handheld crank
 * * `game-console-handheld` - game console handheld
 * * `gamepad-modern` - gamepad modern
 * * `gamepad` - gamepad
 * * `garage-car` - garage car
 * * `garage-open` - garage open
 * * `garage` - garage
 * * `garlic` - garlic
 * * `gas-pump-slash` - gas pump slash
 * * `gas-pump` - gas pump
 * * `gauge-circle-bolt` - gauge circle bolt
 * * `gauge-circle-minus` - gauge circle minus
 * * `gauge-circle-plus` - gauge circle plus
 * * `gauge-high` - gauge high
 * * `gauge-low` - gauge low
 * * `gauge-max` - gauge max
 * * `gauge-min` - gauge min
 * * `gauge-simple-high` - gauge simple high
 * * `gauge-simple-low` - gauge simple low
 * * `gauge-simple-max` - gauge simple max
 * * `gauge-simple-min` - gauge simple min
 * * `gauge-simple` - gauge simple
 * * `gauge` - gauge
 * * `gavel` - gavel
 * * `gear` - gear
 * * `gears` - gears
 * * `gem` - gem
 * * `genderless` - genderless
 * * `ghost` - ghost
 * * `gif` - gif
 * * `gift-card` - gift card
 * * `gift` - gift
 * * `gifts` - gifts
 * * `gingerbread-man` - gingerbread man
 * * `glass-citrus` - glass citrus
 * * `glass-empty` - glass empty
 * * `glass-half` - glass half
 * * `glass-water-droplet` - glass water droplet
 * * `glass-water` - glass water
 * * `glass` - glass
 * * `glasses-round` - glasses round
 * * `glasses` - glasses
 * * `globe-snow` - globe snow
 * * `globe-stand` - globe stand
 * * `globe` - globe
 * * `goal-net` - goal net
 * * `golf-ball-tee` - golf ball tee
 * * `golf-club` - golf club
 * * `golf-flag-hole` - golf flag hole
 * * `gopuram` - gopuram
 * * `graduation-cap` - graduation cap
 * * `gramophone` - gramophone
 * * `grapes` - grapes
 * * `grate-droplet` - grate droplet
 * * `grate` - grate
 * * `greater-than-equal` - greater than equal
 * * `greater-than` - greater than
 * * `grid-2-plus` - grid 2 plus
 * * `grid-2` - grid 2
 * * `grid-4` - grid 4
 * * `grid-5` - grid 5
 * * `grid-dividers` - grid dividers
 * * `grid-horizontal` - grid horizontal
 * * `grid` - grid
 * * `grill-fire` - grill fire
 * * `grill-hot` - grill hot
 * * `grill` - grill
 * * `grip-dots-vertical` - grip dots vertical
 * * `grip-dots` - grip dots
 * * `grip-lines-vertical` - grip lines vertical
 * * `grip-lines` - grip lines
 * * `grip-vertical` - grip vertical
 * * `grip` - grip
 * * `group-arrows-rotate` - group arrows rotate
 * * `guarani-sign` - guarani sign
 * * `guitar-electric` - guitar electric
 * * `guitar` - guitar
 * * `guitars` - guitars
 * * `gun-slash` - gun slash
 * * `gun-squirt` - gun squirt
 * * `gun` - gun
 * * `h` - h
 * * `h1` - h1
 * * `h2` - h2
 * * `h3` - h3
 * * `h4` - h4
 * * `h5` - h5
 * * `h6` - h6
 * * `hammer-crash` - hammer crash
 * * `hammer-war` - hammer war
 * * `hammer` - hammer
 * * `hamsa` - hamsa
 * * `hand-back-fist` - hand back fist
 * * `hand-back-point-down` - hand back point down
 * * `hand-back-point-left` - hand back point left
 * * `hand-back-point-ribbon` - hand back point ribbon
 * * `hand-back-point-right` - hand back point right
 * * `hand-back-point-up` - hand back point up
 * * `hand-dots` - hand dots
 * * `hand-fingers-crossed` - hand fingers crossed
 * * `hand-fist` - hand fist
 * * `hand-heart` - hand heart
 * * `hand-holding-box` - hand holding box
 * * `hand-holding-dollar` - hand holding dollar
 * * `hand-holding-droplet` - hand holding droplet
 * * `hand-holding-hand` - hand holding hand
 * * `hand-holding-heart` - hand holding heart
 * * `hand-holding-magic` - hand holding magic
 * * `hand-holding-medical` - hand holding medical
 * * `hand-holding-seedling` - hand holding seedling
 * * `hand-holding-skull` - hand holding skull
 * * `hand-holding` - hand holding
 * * `hand-horns` - hand horns
 * * `hand-lizard` - hand lizard
 * * `hand-love` - hand love
 * * `hand-middle-finger` - hand middle finger
 * * `hand-peace` - hand peace
 * * `hand-point-down` - hand point down
 * * `hand-point-left` - hand point left
 * * `hand-point-ribbon` - hand point ribbon
 * * `hand-point-right` - hand point right
 * * `hand-point-up` - hand point up
 * * `hand-pointer` - hand pointer
 * * `hand-scissors` - hand scissors
 * * `hand-sparkles` - hand sparkles
 * * `hand-spock` - hand spock
 * * `hand-wave` - hand wave
 * * `hand` - hand
 * * `handcuffs` - handcuffs
 * * `hands-asl-interpreting` - hands asl interpreting
 * * `hands-bound` - hands bound
 * * `hands-bubbles` - hands bubbles
 * * `hands-clapping` - hands clapping
 * * `hands-holding-child` - hands holding child
 * * `hands-holding-circle` - hands holding circle
 * * `hands-holding-diamond` - hands holding diamond
 * * `hands-holding-dollar` - hands holding dollar
 * * `hands-holding-heart` - hands holding heart
 * * `hands-holding` - hands holding
 * * `hands-praying` - hands praying
 * * `hands` - hands
 * * `handshake-angle` - handshake angle
 * * `handshake-simple-slash` - handshake simple slash
 * * `handshake-simple` - handshake simple
 * * `handshake-slash` - handshake slash
 * * `handshake` - handshake
 * * `hanukiah` - hanukiah
 * * `hard-drive` - hard drive
 * * `hashtag-lock` - hashtag lock
 * * `hashtag` - hashtag
 * * `hat-chef` - hat chef
 * * `hat-cowboy-side` - hat cowboy side
 * * `hat-cowboy` - hat cowboy
 * * `hat-santa` - hat santa
 * * `hat-winter` - hat winter
 * * `hat-witch` - hat witch
 * * `hat-wizard` - hat wizard
 * * `head-side-brain` - head side brain
 * * `head-side-cough-slash` - head side cough slash
 * * `head-side-cough` - head side cough
 * * `head-side-goggles` - head side goggles
 * * `head-side-headphones` - head side headphones
 * * `head-side-heart` - head side heart
 * * `head-side-mask` - head side mask
 * * `head-side-medical` - head side medical
 * * `head-side-virus` - head side virus
 * * `head-side` - head side
 * * `heading` - heading
 * * `headphones-simple` - headphones simple
 * * `headphones` - headphones
 * * `headset` - headset
 * * `heart-circle-bolt` - heart circle bolt
 * * `heart-circle-check` - heart circle check
 * * `heart-circle-exclamation` - heart circle exclamation
 * * `heart-circle-minus` - heart circle minus
 * * `heart-circle-plus` - heart circle plus
 * * `heart-circle-xmark` - heart circle xmark
 * * `heart-crack` - heart crack
 * * `heart-half-stroke` - heart half stroke
 * * `heart-half` - heart half
 * * `heart-pulse` - heart pulse
 * * `heart` - heart
 * * `heat` - heat
 * * `helicopter-symbol` - helicopter symbol
 * * `helicopter` - helicopter
 * * `helmet-battle` - helmet battle
 * * `helmet-safety` - helmet safety
 * * `helmet-un` - helmet un
 * * `hexagon-check` - hexagon check
 * * `hexagon-divide` - hexagon divide
 * * `hexagon-exclamation` - hexagon exclamation
 * * `hexagon-image` - hexagon image
 * * `hexagon-minus` - hexagon minus
 * * `hexagon-plus` - hexagon plus
 * * `hexagon-vertical-nft-slanted` - hexagon vertical nft slanted
 * * `hexagon-vertical-nft` - hexagon vertical nft
 * * `hexagon-xmark` - hexagon xmark
 * * `hexagon` - hexagon
 * * `high-definition` - high definition
 * * `highlighter-line` - highlighter line
 * * `highlighter` - highlighter
 * * `hill-avalanche` - hill avalanche
 * * `hill-rockslide` - hill rockslide
 * * `hippo` - hippo
 * * `hockey-mask` - hockey mask
 * * `hockey-puck` - hockey puck
 * * `hockey-stick-puck` - hockey stick puck
 * * `hockey-sticks` - hockey sticks
 * * `holly-berry` - holly berry
 * * `honey-pot` - honey pot
 * * `hood-cloak` - hood cloak
 * * `horizontal-rule` - horizontal rule
 * * `horse-head` - horse head
 * * `horse-saddle` - horse saddle
 * * `horse` - horse
 * * `hose-reel` - hose reel
 * * `hose` - hose
 * * `hospital-user` - hospital user
 * * `hospital` - hospital
 * * `hospitals` - hospitals
 * * `hot-tub-person` - hot tub person
 * * `hotdog` - hotdog
 * * `hotel` - hotel
 * * `hourglass-clock` - hourglass clock
 * * `hourglass-end` - hourglass end
 * * `hourglass-half` - hourglass half
 * * `hourglass-start` - hourglass start
 * * `hourglass` - hourglass
 * * `house-blank` - house blank
 * * `house-building` - house building
 * * `house-chimney-blank` - house chimney blank
 * * `house-chimney-crack` - house chimney crack
 * * `house-chimney-heart` - house chimney heart
 * * `house-chimney-medical` - house chimney medical
 * * `house-chimney-user` - house chimney user
 * * `house-chimney-window` - house chimney window
 * * `house-chimney` - house chimney
 * * `house-circle-check` - house circle check
 * * `house-circle-exclamation` - house circle exclamation
 * * `house-circle-xmark` - house circle xmark
 * * `house-crack` - house crack
 * * `house-day` - house day
 * * `house-fire` - house fire
 * * `house-flag` - house flag
 * * `house-flood-water-circle-arrow-right` - house flood water circle arrow right
 * * `house-flood-water` - house flood water
 * * `house-heart` - house heart
 * * `house-laptop` - house laptop
 * * `house-lock` - house lock
 * * `house-medical-circle-check` - house medical circle check
 * * `house-medical-circle-exclamation` - house medical circle exclamation
 * * `house-medical-circle-xmark` - house medical circle xmark
 * * `house-medical-flag` - house medical flag
 * * `house-medical` - house medical
 * * `house-night` - house night
 * * `house-person-leave` - house person leave
 * * `house-person-return` - house person return
 * * `house-signal` - house signal
 * * `house-tree` - house tree
 * * `house-tsunami` - house tsunami
 * * `house-turret` - house turret
 * * `house-user` - house user
 * * `house-water` - house water
 * * `house-window` - house window
 * * `house` - house
 * * `hryvnia-sign` - hryvnia sign
 * * `hundred-points` - hundred points
 * * `hurricane` - hurricane
 * * `hyphen` - hyphen
 * * `i-cursor` - i cursor
 * * `i` - i
 * * `ice-cream` - ice cream
 * * `ice-skate` - ice skate
 * * `icicles` - icicles
 * * `icons` - icons
 * * `id-badge` - id badge
 * * `id-card-clip` - id card clip
 * * `id-card` - id card
 * * `igloo` - igloo
 * * `image-landscape` - image landscape
 * * `image-polaroid-user` - image polaroid user
 * * `image-polaroid` - image polaroid
 * * `image-portrait` - image portrait
 * * `image-slash` - image slash
 * * `image-user` - image user
 * * `image` - image
 * * `images-user` - images user
 * * `images` - images
 * * `inbox-full` - inbox full
 * * `inbox-in` - inbox in
 * * `inbox-out` - inbox out
 * * `inbox` - inbox
 * * `inboxes` - inboxes
 * * `indent` - indent
 * * `indian-rupee-sign` - indian rupee sign
 * * `industry-windows` - industry windows
 * * `industry` - industry
 * * `infinity` - infinity
 * * `info` - info
 * * `inhaler` - inhaler
 * * `input-numeric` - input numeric
 * * `input-pipe` - input pipe
 * * `input-text` - input text
 * * `integral` - integral
 * * `interrobang` - interrobang
 * * `intersection` - intersection
 * * `island-tropical` - island tropical
 * * `italic` - italic
 * * `j` - j
 * * `jack-o-lantern` - jack o lantern
 * * `jar-wheat` - jar wheat
 * * `jar` - jar
 * * `jedi` - jedi
 * * `jet-fighter-up` - jet fighter up
 * * `jet-fighter` - jet fighter
 * * `joint` - joint
 * * `joystick` - joystick
 * * `jug-detergent` - jug detergent
 * * `jug` - jug
 * * `k` - k
 * * `kaaba` - kaaba
 * * `kazoo` - kazoo
 * * `kerning` - kerning
 * * `key-skeleton-left-right` - key skeleton left right
 * * `key-skeleton` - key skeleton
 * * `key` - key
 * * `keyboard-brightness-low` - keyboard brightness low
 * * `keyboard-brightness` - keyboard brightness
 * * `keyboard-down` - keyboard down
 * * `keyboard-left` - keyboard left
 * * `keyboard` - keyboard
 * * `keynote` - keynote
 * * `khanda` - khanda
 * * `kidneys` - kidneys
 * * `kip-sign` - kip sign
 * * `kit-medical` - kit medical
 * * `kitchen-set` - kitchen set
 * * `kite` - kite
 * * `kiwi-bird` - kiwi bird
 * * `kiwi-fruit` - kiwi fruit
 * * `knife-kitchen` - knife kitchen
 * * `knife` - knife
 * * `l` - l
 * * `lacrosse-stick-ball` - lacrosse stick ball
 * * `lacrosse-stick` - lacrosse stick
 * * `lambda` - lambda
 * * `lamp-desk` - lamp desk
 * * `lamp-floor` - lamp floor
 * * `lamp-street` - lamp street
 * * `lamp` - lamp
 * * `land-mine-on` - land mine on
 * * `landmark-dome` - landmark dome
 * * `landmark-flag` - landmark flag
 * * `landmark` - landmark
 * * `language` - language
 * * `laptop-arrow-down` - laptop arrow down
 * * `laptop-code` - laptop code
 * * `laptop-file` - laptop file
 * * `laptop-medical` - laptop medical
 * * `laptop-mobile` - laptop mobile
 * * `laptop-slash` - laptop slash
 * * `laptop` - laptop
 * * `lari-sign` - lari sign
 * * `lasso-sparkles` - lasso sparkles
 * * `lasso` - lasso
 * * `layer-group` - layer group
 * * `layer-minus` - layer minus
 * * `layer-plus` - layer plus
 * * `leaf-heart` - leaf heart
 * * `leaf-maple` - leaf maple
 * * `leaf-oak` - leaf oak
 * * `leaf` - leaf
 * * `leafy-green` - leafy green
 * * `left-from-line` - left from line
 * * `left-long-to-line` - left long to line
 * * `left-long` - left long
 * * `left-right` - left right
 * * `left-to-line` - left to line
 * * `left` - left
 * * `lemon` - lemon
 * * `less-than-equal` - less than equal
 * * `less-than` - less than
 * * `life-ring` - life ring
 * * `light-ceiling` - light ceiling
 * * `light-emergency-on` - light emergency on
 * * `light-emergency` - light emergency
 * * `light-switch-off` - light switch off
 * * `light-switch-on` - light switch on
 * * `light-switch` - light switch
 * * `lightbulb-cfl-on` - lightbulb cfl on
 * * `lightbulb-cfl` - lightbulb cfl
 * * `lightbulb-dollar` - lightbulb dollar
 * * `lightbulb-exclamation-on` - lightbulb exclamation on
 * * `lightbulb-exclamation` - lightbulb exclamation
 * * `lightbulb-on` - lightbulb on
 * * `lightbulb-slash` - lightbulb slash
 * * `lightbulb` - lightbulb
 * * `lights-holiday` - lights holiday
 * * `line-columns` - line columns
 * * `line-height` - line height
 * * `lines-leaning` - lines leaning
 * * `link-horizontal-slash` - link horizontal slash
 * * `link-horizontal` - link horizontal
 * * `link-simple-slash` - link simple slash
 * * `link-simple` - link simple
 * * `link-slash` - link slash
 * * `link` - link
 * * `lips` - lips
 * * `lira-sign` - lira sign
 * * `list-check` - list check
 * * `list-dropdown` - list dropdown
 * * `list-music` - list music
 * * `list-ol` - list ol
 * * `list-radio` - list radio
 * * `list-timeline` - list timeline
 * * `list-tree` - list tree
 * * `list-ul` - list ul
 * * `list` - list
 * * `litecoin-sign` - litecoin sign
 * * `loader` - loader
 * * `lobster` - lobster
 * * `location-arrow` - location arrow
 * * `location-check` - location check
 * * `location-crosshairs-slash` - location crosshairs slash
 * * `location-crosshairs` - location crosshairs
 * * `location-dot-slash` - location dot slash
 * * `location-dot` - location dot
 * * `location-exclamation` - location exclamation
 * * `location-minus` - location minus
 * * `location-pen` - location pen
 * * `location-pin-lock` - location pin lock
 * * `location-pin-slash` - location pin slash
 * * `location-pin` - location pin
 * * `location-plus` - location plus
 * * `location-question` - location question
 * * `location-smile` - location smile
 * * `location-xmark` - location xmark
 * * `lock-a` - lock a
 * * `lock-hashtag` - lock hashtag
 * * `lock-keyhole-open` - lock keyhole open
 * * `lock-keyhole` - lock keyhole
 * * `lock-open` - lock open
 * * `lock` - lock
 * * `locust` - locust
 * * `lollipop` - lollipop
 * * `loveseat` - loveseat
 * * `luchador-mask` - luchador mask
 * * `lungs-virus` - lungs virus
 * * `lungs` - lungs
 * * `m` - m
 * * `mace` - mace
 * * `magnet` - magnet
 * * `magnifying-glass-arrow-right` - magnifying glass arrow right
 * * `magnifying-glass-chart` - magnifying glass chart
 * * `magnifying-glass-dollar` - magnifying glass dollar
 * * `magnifying-glass-location` - magnifying glass location
 * * `magnifying-glass-minus` - magnifying glass minus
 * * `magnifying-glass-plus` - magnifying glass plus
 * * `magnifying-glass` - magnifying glass
 * * `mailbox-flag-up` - mailbox flag up
 * * `mailbox` - mailbox
 * * `manat-sign` - manat sign
 * * `mandolin` - mandolin
 * * `mango` - mango
 * * `manhole` - manhole
 * * `map-location-dot` - map location dot
 * * `map-location` - map location
 * * `map-pin` - map pin
 * * `map` - map
 * * `marker` - marker
 * * `mars-and-venus-burst` - mars and venus burst
 * * `mars-and-venus` - mars and venus
 * * `mars-double` - mars double
 * * `mars-stroke-right` - mars stroke right
 * * `mars-stroke-up` - mars stroke up
 * * `mars-stroke` - mars stroke
 * * `mars` - mars
 * * `martini-glass-citrus` - martini glass citrus
 * * `martini-glass-empty` - martini glass empty
 * * `martini-glass` - martini glass
 * * `mask-face` - mask face
 * * `mask-snorkel` - mask snorkel
 * * `mask-ventilator` - mask ventilator
 * * `mask` - mask
 * * `masks-theater` - masks theater
 * * `mattress-pillow` - mattress pillow
 * * `maximize` - maximize
 * * `meat` - meat
 * * `medal` - medal
 * * `megaphone` - megaphone
 * * `melon-slice` - melon slice
 * * `melon` - melon
 * * `memo-circle-check` - memo circle check
 * * `memo-circle-info` - memo circle info
 * * `memo-pad` - memo pad
 * * `memo` - memo
 * * `memory` - memory
 * * `menorah` - menorah
 * * `mercury` - mercury
 * * `merge` - merge
 * * `message-arrow-down` - message arrow down
 * * `message-arrow-up-right` - message arrow up right
 * * `message-arrow-up` - message arrow up
 * * `message-bot` - message bot
 * * `message-captions` - message captions
 * * `message-check` - message check
 * * `message-code` - message code
 * * `message-dollar` - message dollar
 * * `message-dots` - message dots
 * * `message-exclamation` - message exclamation
 * * `message-heart` - message heart
 * * `message-image` - message image
 * * `message-lines` - message lines
 * * `message-medical` - message medical
 * * `message-middle-top` - message middle top
 * * `message-middle` - message middle
 * * `message-minus` - message minus
 * * `message-music` - message music
 * * `message-pen` - message pen
 * * `message-plus` - message plus
 * * `message-question` - message question
 * * `message-quote` - message quote
 * * `message-slash` - message slash
 * * `message-smile` - message smile
 * * `message-sms` - message sms
 * * `message-text` - message text
 * * `message-xmark` - message xmark
 * * `message` - message
 * * `messages-dollar` - messages dollar
 * * `messages-question` - messages question
 * * `messages` - messages
 * * `meteor` - meteor
 * * `meter-bolt` - meter bolt
 * * `meter-droplet` - meter droplet
 * * `meter-fire` - meter fire
 * * `meter` - meter
 * * `microchip-ai` - microchip ai
 * * `microchip` - microchip
 * * `microphone-lines-slash` - microphone lines slash
 * * `microphone-lines` - microphone lines
 * * `microphone-slash` - microphone slash
 * * `microphone-stand` - microphone stand
 * * `microphone` - microphone
 * * `microscope` - microscope
 * * `microwave` - microwave
 * * `mill-sign` - mill sign
 * * `minimize` - minimize
 * * `minus` - minus
 * * `mistletoe` - mistletoe
 * * `mitten` - mitten
 * * `mobile-button` - mobile button
 * * `mobile-notch` - mobile notch
 * * `mobile-retro` - mobile retro
 * * `mobile-screen-button` - mobile screen button
 * * `mobile-screen` - mobile screen
 * * `mobile-signal-out` - mobile signal out
 * * `mobile-signal` - mobile signal
 * * `mobile` - mobile
 * * `money-bill-1-wave` - money bill 1 wave
 * * `money-bill-1` - money bill 1
 * * `money-bill-simple-wave` - money bill simple wave
 * * `money-bill-simple` - money bill simple
 * * `money-bill-transfer` - money bill transfer
 * * `money-bill-trend-up` - money bill trend up
 * * `money-bill-wave` - money bill wave
 * * `money-bill-wheat` - money bill wheat
 * * `money-bill` - money bill
 * * `money-bills-simple` - money bills simple
 * * `money-bills` - money bills
 * * `money-check-dollar-pen` - money check dollar pen
 * * `money-check-dollar` - money check dollar
 * * `money-check-pen` - money check pen
 * * `money-check` - money check
 * * `money-from-bracket` - money from bracket
 * * `money-simple-from-bracket` - money simple from bracket
 * * `monitor-waveform` - monitor waveform
 * * `monkey` - monkey
 * * `monument` - monument
 * * `moon-cloud` - moon cloud
 * * `moon-over-sun` - moon over sun
 * * `moon-stars` - moon stars
 * * `moon` - moon
 * * `moped` - moped
 * * `mortar-pestle` - mortar pestle
 * * `mosque` - mosque
 * * `mosquito-net` - mosquito net
 * * `mosquito` - mosquito
 * * `motorcycle` - motorcycle
 * * `mound` - mound
 * * `mountain-city` - mountain city
 * * `mountain-sun` - mountain sun
 * * `mountain` - mountain
 * * `mountains` - mountains
 * * `mouse-field` - mouse field
 * * `mp3-player` - mp3 player
 * * `mug-hot` - mug hot
 * * `mug-marshmallows` - mug marshmallows
 * * `mug-saucer` - mug saucer
 * * `mug-tea-saucer` - mug tea saucer
 * * `mug-tea` - mug tea
 * * `mug` - mug
 * * `mushroom` - mushroom
 * * `music-note-slash` - music note slash
 * * `music-note` - music note
 * * `music-slash` - music slash
 * * `music` - music
 * * `mustache` - mustache
 * * `n` - n
 * * `naira-sign` - naira sign
 * * `narwhal` - narwhal
 * * `nesting-dolls` - nesting dolls
 * * `network-wired` - network wired
 * * `neuter` - neuter
 * * `newspaper` - newspaper
 * * `nfc-lock` - nfc lock
 * * `nfc-magnifying-glass` - nfc magnifying glass
 * * `nfc-pen` - nfc pen
 * * `nfc-signal` - nfc signal
 * * `nfc-slash` - nfc slash
 * * `nfc-symbol` - nfc symbol
 * * `nfc-trash` - nfc trash
 * * `nfc` - nfc
 * * `nose` - nose
 * * `not-equal` - not equal
 * * `notdef` - notdef
 * * `note-medical` - note medical
 * * `note-sticky` - note sticky
 * * `note` - note
 * * `notebook` - notebook
 * * `notes-medical` - notes medical
 * * `notes` - notes
 * * `o` - o
 * * `object-exclude` - object exclude
 * * `object-group` - object group
 * * `object-intersect` - object intersect
 * * `object-subtract` - object subtract
 * * `object-ungroup` - object ungroup
 * * `object-union` - object union
 * * `objects-align-bottom` - objects align bottom
 * * `objects-align-center-horizontal` - objects align center horizontal
 * * `objects-align-center-vertical` - objects align center vertical
 * * `objects-align-left` - objects align left
 * * `objects-align-right` - objects align right
 * * `objects-align-top` - objects align top
 * * `objects-column` - objects column
 * * `octagon-check` - octagon check
 * * `octagon-divide` - octagon divide
 * * `octagon-exclamation` - octagon exclamation
 * * `octagon-minus` - octagon minus
 * * `octagon-plus` - octagon plus
 * * `octagon-xmark` - octagon xmark
 * * `octagon` - octagon
 * * `oil-can-drip` - oil can drip
 * * `oil-can` - oil can
 * * `oil-temperature` - oil temperature
 * * `oil-well` - oil well
 * * `olive-branch` - olive branch
 * * `olive` - olive
 * * `om` - om
 * * `omega` - omega
 * * `onion` - onion
 * * `option` - option
 * * `ornament` - ornament
 * * `otter` - otter
 * * `outdent` - outdent
 * * `outlet` - outlet
 * * `oven` - oven
 * * `overline` - overline
 * * `p` - p
 * * `page-caret-down` - page caret down
 * * `page-caret-up` - page caret up
 * * `page` - page
 * * `pager` - pager
 * * `paint-roller` - paint roller
 * * `paintbrush-fine` - paintbrush fine
 * * `paintbrush-pencil` - paintbrush pencil
 * * `paintbrush` - paintbrush
 * * `palette` - palette
 * * `pallet-box` - pallet box
 * * `pallet-boxes` - pallet boxes
 * * `pallet` - pallet
 * * `pan-food` - pan food
 * * `pan-frying` - pan frying
 * * `pancakes` - pancakes
 * * `panel-ews` - panel ews
 * * `panel-fire` - panel fire
 * * `panorama` - panorama
 * * `paper-plane-top` - paper plane top
 * * `paper-plane` - paper plane
 * * `paperclip-vertical` - paperclip vertical
 * * `paperclip` - paperclip
 * * `parachute-box` - parachute box
 * * `paragraph-left` - paragraph left
 * * `paragraph` - paragraph
 * * `party-bell` - party bell
 * * `party-horn` - party horn
 * * `passport` - passport
 * * `paste` - paste
 * * `pause` - pause
 * * `paw-claws` - paw claws
 * * `paw-simple` - paw simple
 * * `paw` - paw
 * * `peace` - peace
 * * `peach` - peach
 * * `peanut` - peanut
 * * `peanuts` - peanuts
 * * `peapod` - peapod
 * * `pear` - pear
 * * `pedestal` - pedestal
 * * `pegasus` - pegasus
 * * `pen-circle` - pen circle
 * * `pen-clip-slash` - pen clip slash
 * * `pen-clip` - pen clip
 * * `pen-fancy-slash` - pen fancy slash
 * * `pen-fancy` - pen fancy
 * * `pen-field` - pen field
 * * `pen-line` - pen line
 * * `pen-nib-slash` - pen nib slash
 * * `pen-nib` - pen nib
 * * `pen-paintbrush` - pen paintbrush
 * * `pen-ruler` - pen ruler
 * * `pen-slash` - pen slash
 * * `pen-swirl` - pen swirl
 * * `pen-to-square` - pen to square
 * * `pen` - pen
 * * `pencil-slash` - pencil slash
 * * `pencil` - pencil
 * * `people-arrows` - people arrows
 * * `people-carry-box` - people carry box
 * * `people-dress-simple` - people dress simple
 * * `people-dress` - people dress
 * * `people-group` - people group
 * * `people-line` - people line
 * * `people-pants-simple` - people pants simple
 * * `people-pants` - people pants
 * * `people-pulling` - people pulling
 * * `people-robbery` - people robbery
 * * `people-roof` - people roof
 * * `people-simple` - people simple
 * * `people` - people
 * * `pepper-hot` - pepper hot
 * * `pepper` - pepper
 * * `percent` - percent
 * * `period` - period
 * * `person-arrow-down-to-line` - person arrow down to line
 * * `person-arrow-up-from-line` - person arrow up from line
 * * `person-biking-mountain` - person biking mountain
 * * `person-biking` - person biking
 * * `person-booth` - person booth
 * * `person-breastfeeding` - person breastfeeding
 * * `person-burst` - person burst
 * * `person-cane` - person cane
 * * `person-carry-box` - person carry box
 * * `person-chalkboard` - person chalkboard
 * * `person-circle-check` - person circle check
 * * `person-circle-exclamation` - person circle exclamation
 * * `person-circle-minus` - person circle minus
 * * `person-circle-plus` - person circle plus
 * * `person-circle-question` - person circle question
 * * `person-circle-xmark` - person circle xmark
 * * `person-digging` - person digging
 * * `person-dolly-empty` - person dolly empty
 * * `person-dolly` - person dolly
 * * `person-dots-from-line` - person dots from line
 * * `person-dress-burst` - person dress burst
 * * `person-dress-simple` - person dress simple
 * * `person-dress` - person dress
 * * `person-drowning` - person drowning
 * * `person-falling-burst` - person falling burst
 * * `person-falling` - person falling
 * * `person-from-portal` - person from portal
 * * `person-half-dress` - person half dress
 * * `person-harassing` - person harassing
 * * `person-hiking` - person hiking
 * * `person-military-pointing` - person military pointing
 * * `person-military-rifle` - person military rifle
 * * `person-military-to-person` - person military to person
 * * `person-pinball` - person pinball
 * * `person-praying` - person praying
 * * `person-pregnant` - person pregnant
 * * `person-rays` - person rays
 * * `person-rifle` - person rifle
 * * `person-running` - person running
 * * `person-seat-reclined` - person seat reclined
 * * `person-seat` - person seat
 * * `person-shelter` - person shelter
 * * `person-sign` - person sign
 * * `person-simple` - person simple
 * * `person-skating` - person skating
 * * `person-ski-jumping` - person ski jumping
 * * `person-ski-lift` - person ski lift
 * * `person-skiing-nordic` - person skiing nordic
 * * `person-skiing` - person skiing
 * * `person-sledding` - person sledding
 * * `person-snowboarding` - person snowboarding
 * * `person-snowmobiling` - person snowmobiling
 * * `person-swimming` - person swimming
 * * `person-through-window` - person through window
 * * `person-to-door` - person to door
 * * `person-to-portal` - person to portal
 * * `person-walking-arrow-loop-left` - person walking arrow loop left
 * * `person-walking-arrow-right` - person walking arrow right
 * * `person-walking-dashed-line-arrow-right` - person walking dashed line arrow right
 * * `person-walking-luggage` - person walking luggage
 * * `person-walking-with-cane` - person walking with cane
 * * `person-walking` - person walking
 * * `person` - person
 * * `peseta-sign` - peseta sign
 * * `peso-sign` - peso sign
 * * `phone-arrow-down-left` - phone arrow down left
 * * `phone-arrow-right` - phone arrow right
 * * `phone-arrow-up-right` - phone arrow up right
 * * `phone-flip` - phone flip
 * * `phone-hangup` - phone hangup
 * * `phone-intercom` - phone intercom
 * * `phone-missed` - phone missed
 * * `phone-office` - phone office
 * * `phone-plus` - phone plus
 * * `phone-rotary` - phone rotary
 * * `phone-slash` - phone slash
 * * `phone-volume` - phone volume
 * * `phone-xmark` - phone xmark
 * * `phone` - phone
 * * `photo-film-music` - photo film music
 * * `photo-film` - photo film
 * * `pi` - pi
 * * `piano-keyboard` - piano keyboard
 * * `piano` - piano
 * * `pickaxe` - pickaxe
 * * `pickleball` - pickleball
 * * `pie` - pie
 * * `pig` - pig
 * * `piggy-bank` - piggy bank
 * * `pills` - pills
 * * `pinata` - pinata
 * * `pinball` - pinball
 * * `pineapple` - pineapple
 * * `pipe-circle-check` - pipe circle check
 * * `pipe-collar` - pipe collar
 * * `pipe-section` - pipe section
 * * `pipe-smoking` - pipe smoking
 * * `pipe-valve` - pipe valve
 * * `pipe` - pipe
 * * `pizza-slice` - pizza slice
 * * `pizza` - pizza
 * * `place-of-worship` - place of worship
 * * `plane-arrival` - plane arrival
 * * `plane-circle-check` - plane circle check
 * * `plane-circle-exclamation` - plane circle exclamation
 * * `plane-circle-xmark` - plane circle xmark
 * * `plane-departure` - plane departure
 * * `plane-engines` - plane engines
 * * `plane-lock` - plane lock
 * * `plane-prop` - plane prop
 * * `plane-slash` - plane slash
 * * `plane-tail` - plane tail
 * * `plane-up-slash` - plane up slash
 * * `plane-up` - plane up
 * * `plane` - plane
 * * `planet-moon` - planet moon
 * * `planet-ringed` - planet ringed
 * * `plant-wilt` - plant wilt
 * * `plate-utensils` - plate utensils
 * * `plate-wheat` - plate wheat
 * * `play-pause` - play pause
 * * `play` - play
 * * `plug-circle-bolt` - plug circle bolt
 * * `plug-circle-check` - plug circle check
 * * `plug-circle-exclamation` - plug circle exclamation
 * * `plug-circle-minus` - plug circle minus
 * * `plug-circle-plus` - plug circle plus
 * * `plug-circle-xmark` - plug circle xmark
 * * `plug` - plug
 * * `plus-large` - plus large
 * * `plus-minus` - plus minus
 * * `plus` - plus
 * * `podcast` - podcast
 * * `podium-star` - podium star
 * * `podium` - podium
 * * `police-box` - police box
 * * `poll-people` - poll people
 * * `pompebled` - pompebled
 * * `poo-storm` - poo storm
 * * `poo` - poo
 * * `pool-8-ball` - pool 8 ball
 * * `poop` - poop
 * * `popcorn` - popcorn
 * * `popsicle` - popsicle
 * * `pot-food` - pot food
 * * `potato` - potato
 * * `power-off` - power off
 * * `prescription-bottle-medical` - prescription bottle medical
 * * `prescription-bottle-pill` - prescription bottle pill
 * * `prescription-bottle` - prescription bottle
 * * `prescription` - prescription
 * * `presentation-screen` - presentation screen
 * * `pretzel` - pretzel
 * * `print-magnifying-glass` - print magnifying glass
 * * `print-slash` - print slash
 * * `print` - print
 * * `projector` - projector
 * * `pump-medical` - pump medical
 * * `pump-soap` - pump soap
 * * `pump` - pump
 * * `pumpkin` - pumpkin
 * * `puzzle-piece-simple` - puzzle piece simple
 * * `puzzle-piece` - puzzle piece
 * * `puzzle` - puzzle
 * * `q` - q
 * * `qrcode` - qrcode
 * * `question` - question
 * * `quote-left` - quote left
 * * `quote-right` - quote right
 * * `quotes` - quotes
 * * `r` - r
 * * `rabbit-running` - rabbit running
 * * `rabbit` - rabbit
 * * `racquet` - racquet
 * * `radar` - radar
 * * `radiation` - radiation
 * * `radio-tuner` - radio tuner
 * * `radio` - radio
 * * `rainbow` - rainbow
 * * `raindrops` - raindrops
 * * `ram` - ram
 * * `ramp-loading` - ramp loading
 * * `ranking-star` - ranking star
 * * `raygun` - raygun
 * * `receipt` - receipt
 * * `record-vinyl` - record vinyl
 * * `rectangle-ad` - rectangle ad
 * * `rectangle-barcode` - rectangle barcode
 * * `rectangle-code` - rectangle code
 * * `rectangle-history-circle-plus` - rectangle history circle plus
 * * `rectangle-history-circle-user` - rectangle history circle user
 * * `rectangle-history` - rectangle history
 * * `rectangle-list` - rectangle list
 * * `rectangle-pro` - rectangle pro
 * * `rectangle-terminal` - rectangle terminal
 * * `rectangle-vertical-history` - rectangle vertical history
 * * `rectangle-vertical` - rectangle vertical
 * * `rectangle-wide` - rectangle wide
 * * `rectangle-xmark` - rectangle xmark
 * * `rectangle` - rectangle
 * * `rectangles-mixed` - rectangles mixed
 * * `recycle` - recycle
 * * `reel` - reel
 * * `refrigerator` - refrigerator
 * * `registered` - registered
 * * `repeat-1` - repeat 1
 * * `repeat` - repeat
 * * `reply-all` - reply all
 * * `reply-clock` - reply clock
 * * `reply` - reply
 * * `republican` - republican
 * * `restroom-simple` - restroom simple
 * * `restroom` - restroom
 * * `retweet` - retweet
 * * `rhombus` - rhombus
 * * `ribbon` - ribbon
 * * `right-from-bracket` - right from bracket
 * * `right-from-line` - right from line
 * * `right-left` - right left
 * * `right-long-to-line` - right long to line
 * * `right-long` - right long
 * * `right-to-bracket` - right to bracket
 * * `right-to-line` - right to line
 * * `right` - right
 * * `ring-diamond` - ring diamond
 * * `ring` - ring
 * * `rings-wedding` - rings wedding
 * * `road-barrier` - road barrier
 * * `road-bridge` - road bridge
 * * `road-circle-check` - road circle check
 * * `road-circle-exclamation` - road circle exclamation
 * * `road-circle-xmark` - road circle xmark
 * * `road-lock` - road lock
 * * `road-spikes` - road spikes
 * * `road` - road
 * * `robot-astromech` - robot astromech
 * * `robot` - robot
 * * `rocket-launch` - rocket launch
 * * `rocket` - rocket
 * * `roller-coaster` - roller coaster
 * * `rotate-exclamation` - rotate exclamation
 * * `rotate-left` - rotate left
 * * `rotate-right` - rotate right
 * * `rotate` - rotate
 * * `route-highway` - route highway
 * * `route-interstate` - route interstate
 * * `route` - route
 * * `router` - router
 * * `rss` - rss
 * * `ruble-sign` - ruble sign
 * * `rug` - rug
 * * `rugby-ball` - rugby ball
 * * `ruler-combined` - ruler combined
 * * `ruler-horizontal` - ruler horizontal
 * * `ruler-triangle` - ruler triangle
 * * `ruler-vertical` - ruler vertical
 * * `ruler` - ruler
 * * `rupee-sign` - rupee sign
 * * `rupiah-sign` - rupiah sign
 * * `rv` - rv
 * * `s` - s
 * * `sack-dollar` - sack dollar
 * * `sack-xmark` - sack xmark
 * * `sack` - sack
 * * `sailboat` - sailboat
 * * `salad` - salad
 * * `salt-shaker` - salt shaker
 * * `sandwich` - sandwich
 * * `satellite-dish` - satellite dish
 * * `satellite` - satellite
 * * `sausage` - sausage
 * * `saxophone-fire` - saxophone fire
 * * `saxophone` - saxophone
 * * `scale-balanced` - scale balanced
 * * `scale-unbalanced-flip` - scale unbalanced flip
 * * `scale-unbalanced` - scale unbalanced
 * * `scalpel-line-dashed` - scalpel line dashed
 * * `scalpel` - scalpel
 * * `scanner-gun` - scanner gun
 * * `scanner-image` - scanner image
 * * `scanner-keyboard` - scanner keyboard
 * * `scanner-touchscreen` - scanner touchscreen
 * * `scarecrow` - scarecrow
 * * `scarf` - scarf
 * * `school-circle-check` - school circle check
 * * `school-circle-exclamation` - school circle exclamation
 * * `school-circle-xmark` - school circle xmark
 * * `school-flag` - school flag
 * * `school-lock` - school lock
 * * `school` - school
 * * `scissors` - scissors
 * * `screen-users` - screen users
 * * `screencast` - screencast
 * * `screwdriver-wrench` - screwdriver wrench
 * * `screwdriver` - screwdriver
 * * `scribble` - scribble
 * * `scroll-old` - scroll old
 * * `scroll-torah` - scroll torah
 * * `scroll` - scroll
 * * `scrubber` - scrubber
 * * `scythe` - scythe
 * * `sd-card` - sd card
 * * `sd-cards` - sd cards
 * * `seal-exclamation` - seal exclamation
 * * `seal-question` - seal question
 * * `seal` - seal
 * * `seat-airline` - seat airline
 * * `section` - section
 * * `seedling` - seedling
 * * `semicolon` - semicolon
 * * `send-back` - send back
 * * `send-backward` - send backward
 * * `sensor-cloud` - sensor cloud
 * * `sensor-fire` - sensor fire
 * * `sensor-on` - sensor on
 * * `sensor-triangle-exclamation` - sensor triangle exclamation
 * * `sensor` - sensor
 * * `server` - server
 * * `shapes` - shapes
 * * `share-all` - share all
 * * `share-from-square` - share from square
 * * `share-nodes` - share nodes
 * * `share` - share
 * * `sheep` - sheep
 * * `sheet-plastic` - sheet plastic
 * * `shekel-sign` - shekel sign
 * * `shelves-empty` - shelves empty
 * * `shelves` - shelves
 * * `shield-cat` - shield cat
 * * `shield-check` - shield check
 * * `shield-cross` - shield cross
 * * `shield-dog` - shield dog
 * * `shield-exclamation` - shield exclamation
 * * `shield-halved` - shield halved
 * * `shield-heart` - shield heart
 * * `shield-keyhole` - shield keyhole
 * * `shield-minus` - shield minus
 * * `shield-plus` - shield plus
 * * `shield-quartered` - shield quartered
 * * `shield-slash` - shield slash
 * * `shield-virus` - shield virus
 * * `shield-xmark` - shield xmark
 * * `shield` - shield
 * * `ship` - ship
 * * `shirt-long-sleeve` - shirt long sleeve
 * * `shirt-running` - shirt running
 * * `shirt-tank-top` - shirt tank top
 * * `shirt` - shirt
 * * `shish-kebab` - shish kebab
 * * `shoe-prints` - shoe prints
 * * `shop-lock` - shop lock
 * * `shop-slash` - shop slash
 * * `shop` - shop
 * * `shovel-snow` - shovel snow
 * * `shovel` - shovel
 * * `shower-down` - shower down
 * * `shower` - shower
 * * `shredder` - shredder
 * * `shrimp` - shrimp
 * * `shuffle` - shuffle
 * * `shutters` - shutters
 * * `shuttle-space` - shuttle space
 * * `shuttlecock` - shuttlecock
 * * `sickle` - sickle
 * * `sidebar-flip` - sidebar flip
 * * `sidebar` - sidebar
 * * `sigma` - sigma
 * * `sign-hanging` - sign hanging
 * * `signal-bars-fair` - signal bars fair
 * * `signal-bars-good` - signal bars good
 * * `signal-bars-slash` - signal bars slash
 * * `signal-bars-weak` - signal bars weak
 * * `signal-bars` - signal bars
 * * `signal-fair` - signal fair
 * * `signal-good` - signal good
 * * `signal-slash` - signal slash
 * * `signal-stream-slash` - signal stream slash
 * * `signal-stream` - signal stream
 * * `signal-strong` - signal strong
 * * `signal-weak` - signal weak
 * * `signal` - signal
 * * `signature-lock` - signature lock
 * * `signature-slash` - signature slash
 * * `signature` - signature
 * * `signs-post` - signs post
 * * `sim-card` - sim card
 * * `sim-cards` - sim cards
 * * `sink` - sink
 * * `siren-on` - siren on
 * * `siren` - siren
 * * `sitemap` - sitemap
 * * `skeleton` - skeleton
 * * `ski-boot-ski` - ski boot ski
 * * `ski-boot` - ski boot
 * * `skull-cow` - skull cow
 * * `skull-crossbones` - skull crossbones
 * * `skull` - skull
 * * `slash-back` - slash back
 * * `slash-forward` - slash forward
 * * `slash` - slash
 * * `sleigh` - sleigh
 * * `slider` - slider
 * * `sliders-simple` - sliders simple
 * * `sliders-up` - sliders up
 * * `sliders` - sliders
 * * `slot-machine` - slot machine
 * * `smog` - smog
 * * `smoke` - smoke
 * * `smoking` - smoking
 * * `snake` - snake
 * * `snooze` - snooze
 * * `snow-blowing` - snow blowing
 * * `snowflake-droplets` - snowflake droplets
 * * `snowflake` - snowflake
 * * `snowflakes` - snowflakes
 * * `snowman-head` - snowman head
 * * `snowman` - snowman
 * * `snowplow` - snowplow
 * * `soap` - soap
 * * `socks` - socks
 * * `soft-serve` - soft serve
 * * `solar-panel` - solar panel
 * * `solar-system` - solar system
 * * `sort-down` - sort down
 * * `sort-up` - sort up
 * * `sort` - sort
 * * `spa` - spa
 * * `space-station-moon-construction` - space station moon construction
 * * `space-station-moon` - space station moon
 * * `spade` - spade
 * * `spaghetti-monster-flying` - spaghetti monster flying
 * * `sparkles` - sparkles
 * * `speaker` - speaker
 * * `speakers` - speakers
 * * `spell-check` - spell check
 * * `spider-black-widow` - spider black widow
 * * `spider-web` - spider web
 * * `spider` - spider
 * * `spinner-third` - spinner third
 * * `spinner` - spinner
 * * `split` - split
 * * `splotch` - splotch
 * * `spoon` - spoon
 * * `sportsball` - sportsball
 * * `spray-can-sparkles` - spray can sparkles
 * * `spray-can` - spray can
 * * `sprinkler-ceiling` - sprinkler ceiling
 * * `sprinkler` - sprinkler
 * * `square-0` - square 0
 * * `square-1` - square 1
 * * `square-2` - square 2
 * * `square-3` - square 3
 * * `square-4` - square 4
 * * `square-5` - square 5
 * * `square-6` - square 6
 * * `square-7` - square 7
 * * `square-8` - square 8
 * * `square-9` - square 9
 * * `square-a-lock` - square a lock
 * * `square-a` - square a
 * * `square-ampersand` - square ampersand
 * * `square-arrow-down-left` - square arrow down left
 * * `square-arrow-down-right` - square arrow down right
 * * `square-arrow-down` - square arrow down
 * * `square-arrow-left` - square arrow left
 * * `square-arrow-right` - square arrow right
 * * `square-arrow-up-left` - square arrow up left
 * * `square-arrow-up-right` - square arrow up right
 * * `square-arrow-up` - square arrow up
 * * `square-b` - square b
 * * `square-bolt` - square bolt
 * * `square-c` - square c
 * * `square-caret-down` - square caret down
 * * `square-caret-left` - square caret left
 * * `square-caret-right` - square caret right
 * * `square-caret-up` - square caret up
 * * `square-check` - square check
 * * `square-chevron-down` - square chevron down
 * * `square-chevron-left` - square chevron left
 * * `square-chevron-right` - square chevron right
 * * `square-chevron-up` - square chevron up
 * * `square-code` - square code
 * * `square-d` - square d
 * * `square-dashed-circle-plus` - square dashed circle plus
 * * `square-dashed` - square dashed
 * * `square-divide` - square divide
 * * `square-dollar` - square dollar
 * * `square-down-left` - square down left
 * * `square-down-right` - square down right
 * * `square-down` - square down
 * * `square-e` - square e
 * * `square-ellipsis-vertical` - square ellipsis vertical
 * * `square-ellipsis` - square ellipsis
 * * `square-envelope` - square envelope
 * * `square-exclamation` - square exclamation
 * * `square-f` - square f
 * * `square-fragile` - square fragile
 * * `square-full` - square full
 * * `square-g` - square g
 * * `square-h` - square h
 * * `square-heart` - square heart
 * * `square-i` - square i
 * * `square-info` - square info
 * * `square-j` - square j
 * * `square-k` - square k
 * * `square-kanban` - square kanban
 * * `square-l` - square l
 * * `square-left` - square left
 * * `square-list` - square list
 * * `square-m` - square m
 * * `square-minus` - square minus
 * * `square-n` - square n
 * * `square-nfi` - square nfi
 * * `square-o` - square o
 * * `square-p` - square p
 * * `square-parking-slash` - square parking slash
 * * `square-parking` - square parking
 * * `square-pen` - square pen
 * * `square-person-confined` - square person confined
 * * `square-phone-flip` - square phone flip
 * * `square-phone-hangup` - square phone hangup
 * * `square-phone` - square phone
 * * `square-plus` - square plus
 * * `square-poll-horizontal` - square poll horizontal
 * * `square-poll-vertical` - square poll vertical
 * * `square-q` - square q
 * * `square-quarters` - square quarters
 * * `square-question` - square question
 * * `square-quote` - square quote
 * * `square-r` - square r
 * * `square-right` - square right
 * * `square-ring` - square ring
 * * `square-root-variable` - square root variable
 * * `square-root` - square root
 * * `square-rss` - square rss
 * * `square-s` - square s
 * * `square-share-nodes` - square share nodes
 * * `square-sliders-vertical` - square sliders vertical
 * * `square-sliders` - square sliders
 * * `square-small` - square small
 * * `square-star` - square star
 * * `square-t` - square t
 * * `square-terminal` - square terminal
 * * `square-this-way-up` - square this way up
 * * `square-u` - square u
 * * `square-up-left` - square up left
 * * `square-up-right` - square up right
 * * `square-up` - square up
 * * `square-user` - square user
 * * `square-v` - square v
 * * `square-virus` - square virus
 * * `square-w` - square w
 * * `square-x` - square x
 * * `square-xmark` - square xmark
 * * `square-y` - square y
 * * `square-z` - square z
 * * `square` - square
 * * `squid` - squid
 * * `squirrel` - squirrel
 * * `staff-snake` - staff snake
 * * `staff` - staff
 * * `stairs` - stairs
 * * `stamp` - stamp
 * * `standard-definition` - standard definition
 * * `stapler` - stapler
 * * `star-and-crescent` - star and crescent
 * * `star-christmas` - star christmas
 * * `star-exclamation` - star exclamation
 * * `star-half-stroke` - star half stroke
 * * `star-half` - star half
 * * `star-of-david` - star of david
 * * `star-of-life` - star of life
 * * `star-sharp-half-stroke` - star sharp half stroke
 * * `star-sharp-half` - star sharp half
 * * `star-sharp` - star sharp
 * * `star-shooting` - star shooting
 * * `star` - star
 * * `starfighter-twin-ion-engine-advanced` - starfighter twin ion engine advanced
 * * `starfighter-twin-ion-engine` - starfighter twin ion engine
 * * `starfighter` - starfighter
 * * `stars` - stars
 * * `starship-freighter` - starship freighter
 * * `starship` - starship
 * * `steak` - steak
 * * `steering-wheel` - steering wheel
 * * `sterling-sign` - sterling sign
 * * `stethoscope` - stethoscope
 * * `stocking` - stocking
 * * `stomach` - stomach
 * * `stop` - stop
 * * `stopwatch-20` - stopwatch 20
 * * `stopwatch` - stopwatch
 * * `store-lock` - store lock
 * * `store-slash` - store slash
 * * `store` - store
 * * `strawberry` - strawberry
 * * `street-view` - street view
 * * `stretcher` - stretcher
 * * `strikethrough` - strikethrough
 * * `stroopwafel` - stroopwafel
 * * `subscript` - subscript
 * * `suitcase-medical` - suitcase medical
 * * `suitcase-rolling` - suitcase rolling
 * * `suitcase` - suitcase
 * * `sun-bright` - sun bright
 * * `sun-cloud` - sun cloud
 * * `sun-dust` - sun dust
 * * `sun-haze` - sun haze
 * * `sun-plant-wilt` - sun plant wilt
 * * `sun` - sun
 * * `sunglasses` - sunglasses
 * * `sunrise` - sunrise
 * * `sunset` - sunset
 * * `superscript` - superscript
 * * `sushi-roll` - sushi roll
 * * `sushi` - sushi
 * * `swatchbook` - swatchbook
 * * `sword-laser-alt` - sword laser alt
 * * `sword-laser` - sword laser
 * * `sword` - sword
 * * `swords-laser` - swords laser
 * * `swords` - swords
 * * `symbols` - symbols
 * * `synagogue` - synagogue
 * * `syringe` - syringe
 * * `t` - t
 * * `table-cells-large` - table cells large
 * * `table-cells` - table cells
 * * `table-columns` - table columns
 * * `table-layout` - table layout
 * * `table-list` - table list
 * * `table-picnic` - table picnic
 * * `table-pivot` - table pivot
 * * `table-rows` - table rows
 * * `table-tennis-paddle-ball` - table tennis paddle ball
 * * `table-tree` - table tree
 * * `table` - table
 * * `tablet-button` - tablet button
 * * `tablet-rugged` - tablet rugged
 * * `tablet-screen-button` - tablet screen button
 * * `tablet-screen` - tablet screen
 * * `tablet` - tablet
 * * `tablets` - tablets
 * * `tachograph-digital` - tachograph digital
 * * `taco` - taco
 * * `tag` - tag
 * * `tags` - tags
 * * `tally-1` - tally 1
 * * `tally-2` - tally 2
 * * `tally-3` - tally 3
 * * `tally-4` - tally 4
 * * `tally` - tally
 * * `tamale` - tamale
 * * `tank-water` - tank water
 * * `tape` - tape
 * * `tarp-droplet` - tarp droplet
 * * `tarp` - tarp
 * * `taxi-bus` - taxi bus
 * * `taxi` - taxi
 * * `teddy-bear` - teddy bear
 * * `teeth-open` - teeth open
 * * `teeth` - teeth
 * * `telescope` - telescope
 * * `temperature-arrow-down` - temperature arrow down
 * * `temperature-arrow-up` - temperature arrow up
 * * `temperature-empty` - temperature empty
 * * `temperature-full` - temperature full
 * * `temperature-half` - temperature half
 * * `temperature-high` - temperature high
 * * `temperature-list` - temperature list
 * * `temperature-low` - temperature low
 * * `temperature-quarter` - temperature quarter
 * * `temperature-snow` - temperature snow
 * * `temperature-sun` - temperature sun
 * * `temperature-three-quarters` - temperature three quarters
 * * `tenge-sign` - tenge sign
 * * `tennis-ball` - tennis ball
 * * `tent-arrow-down-to-line` - tent arrow down to line
 * * `tent-arrow-left-right` - tent arrow left right
 * * `tent-arrow-turn-left` - tent arrow turn left
 * * `tent-arrows-down` - tent arrows down
 * * `tent` - tent
 * * `tents` - tents
 * * `terminal` - terminal
 * * `text-height` - text height
 * * `text-size` - text size
 * * `text-slash` - text slash
 * * `text-width` - text width
 * * `text` - text
 * * `thermometer` - thermometer
 * * `theta` - theta
 * * `thought-bubble` - thought bubble
 * * `thumbs-down` - thumbs down
 * * `thumbs-up` - thumbs up
 * * `thumbtack` - thumbtack
 * * `tick` - tick
 * * `ticket-airline` - ticket airline
 * * `ticket-simple` - ticket simple
 * * `ticket` - ticket
 * * `tickets-airline` - tickets airline
 * * `tilde` - tilde
 * * `timeline-arrow` - timeline arrow
 * * `timeline` - timeline
 * * `timer` - timer
 * * `tire-flat` - tire flat
 * * `tire-pressure-warning` - tire pressure warning
 * * `tire-rugged` - tire rugged
 * * `tire` - tire
 * * `toggle-large-off` - toggle large off
 * * `toggle-large-on` - toggle large on
 * * `toggle-off` - toggle off
 * * `toggle-on` - toggle on
 * * `toilet-paper-blank-under` - toilet paper blank under
 * * `toilet-paper-blank` - toilet paper blank
 * * `toilet-paper-check` - toilet paper check
 * * `toilet-paper-slash` - toilet paper slash
 * * `toilet-paper-under-slash` - toilet paper under slash
 * * `toilet-paper-under` - toilet paper under
 * * `toilet-paper-xmark` - toilet paper xmark
 * * `toilet-paper` - toilet paper
 * * `toilet-portable` - toilet portable
 * * `toilet` - toilet
 * * `toilets-portable` - toilets portable
 * * `tomato` - tomato
 * * `tombstone-blank` - tombstone blank
 * * `tombstone` - tombstone
 * * `toolbox` - toolbox
 * * `tooth` - tooth
 * * `toothbrush` - toothbrush
 * * `torii-gate` - torii gate
 * * `tornado` - tornado
 * * `tower-broadcast` - tower broadcast
 * * `tower-cell` - tower cell
 * * `tower-control` - tower control
 * * `tower-observation` - tower observation
 * * `tractor` - tractor
 * * `trademark` - trademark
 * * `traffic-cone` - traffic cone
 * * `traffic-light-go` - traffic light go
 * * `traffic-light-slow` - traffic light slow
 * * `traffic-light-stop` - traffic light stop
 * * `traffic-light` - traffic light
 * * `trailer` - trailer
 * * `train-subway-tunnel` - train subway tunnel
 * * `train-subway` - train subway
 * * `train-track` - train track
 * * `train-tram` - train tram
 * * `train-tunnel` - train tunnel
 * * `train` - train
 * * `transformer-bolt` - transformer bolt
 * * `transgender` - transgender
 * * `transporter-1` - transporter 1
 * * `transporter-2` - transporter 2
 * * `transporter-3` - transporter 3
 * * `transporter-4` - transporter 4
 * * `transporter-5` - transporter 5
 * * `transporter-6` - transporter 6
 * * `transporter-7` - transporter 7
 * * `transporter-empty` - transporter empty
 * * `transporter` - transporter
 * * `trash-arrow-up` - trash arrow up
 * * `trash-can-arrow-up` - trash can arrow up
 * * `trash-can-check` - trash can check
 * * `trash-can-clock` - trash can clock
 * * `trash-can-list` - trash can list
 * * `trash-can-plus` - trash can plus
 * * `trash-can-slash` - trash can slash
 * * `trash-can-undo` - trash can undo
 * * `trash-can-xmark` - trash can xmark
 * * `trash-can` - trash can
 * * `trash-check` - trash check
 * * `trash-clock` - trash clock
 * * `trash-list` - trash list
 * * `trash-plus` - trash plus
 * * `trash-slash` - trash slash
 * * `trash-undo` - trash undo
 * * `trash-xmark` - trash xmark
 * * `trash` - trash
 * * `treasure-chest` - treasure chest
 * * `tree-christmas` - tree christmas
 * * `tree-city` - tree city
 * * `tree-deciduous` - tree deciduous
 * * `tree-decorated` - tree decorated
 * * `tree-large` - tree large
 * * `tree-palm` - tree palm
 * * `tree` - tree
 * * `trees` - trees
 * * `triangle-exclamation` - triangle exclamation
 * * `triangle-instrument` - triangle instrument
 * * `triangle-person-digging` - triangle person digging
 * * `triangle` - triangle
 * * `tricycle-adult` - tricycle adult
 * * `tricycle` - tricycle
 * * `trillium` - trillium
 * * `trophy-star` - trophy star
 * * `trophy` - trophy
 * * `trowel-bricks` - trowel bricks
 * * `trowel` - trowel
 * * `truck-arrow-right` - truck arrow right
 * * `truck-bolt` - truck bolt
 * * `truck-clock` - truck clock
 * * `truck-container-empty` - truck container empty
 * * `truck-container` - truck container
 * * `truck-droplet` - truck droplet
 * * `truck-fast` - truck fast
 * * `truck-field-un` - truck field un
 * * `truck-field` - truck field
 * * `truck-flatbed` - truck flatbed
 * * `truck-front` - truck front
 * * `truck-medical` - truck medical
 * * `truck-monster` - truck monster
 * * `truck-moving` - truck moving
 * * `truck-pickup` - truck pickup
 * * `truck-plane` - truck plane
 * * `truck-plow` - truck plow
 * * `truck-ramp-box` - truck ramp box
 * * `truck-ramp-couch` - truck ramp couch
 * * `truck-ramp` - truck ramp
 * * `truck-tow` - truck tow
 * * `truck` - truck
 * * `trumpet` - trumpet
 * * `tty-answer` - tty answer
 * * `tty` - tty
 * * `tugrik-sign` - tugrik sign
 * * `turkey` - turkey
 * * `turkish-lira-sign` - turkish lira sign
 * * `turn-down-left` - turn down left
 * * `turn-down-right` - turn down right
 * * `turn-down` - turn down
 * * `turn-up` - turn up
 * * `turntable` - turntable
 * * `turtle` - turtle
 * * `tv-music` - tv music
 * * `tv-retro` - tv retro
 * * `tv` - tv
 * * `typewriter` - typewriter
 * * `u` - u
 * * `ufo-beam` - ufo beam
 * * `ufo` - ufo
 * * `umbrella-beach` - umbrella beach
 * * `umbrella-simple` - umbrella simple
 * * `umbrella` - umbrella
 * * `underline` - underline
 * * `unicorn` - unicorn
 * * `uniform-martial-arts` - uniform martial arts
 * * `union` - union
 * * `universal-access` - universal access
 * * `unlock-keyhole` - unlock keyhole
 * * `unlock` - unlock
 * * `up-down-left-right` - up down left right
 * * `up-down` - up down
 * * `up-from-bracket` - up from bracket
 * * `up-from-dotted-line` - up from dotted line
 * * `up-from-line` - up from line
 * * `up-left` - up left
 * * `up-long` - up long
 * * `up-right-and-down-left-from-center` - up right and down left from center
 * * `up-right-from-square` - up right from square
 * * `up-right` - up right
 * * `up-to-dotted-line` - up to dotted line
 * * `up-to-line` - up to line
 * * `up` - up
 * * `upload` - upload
 * * `usb-drive` - usb drive
 * * `user-alien` - user alien
 * * `user-astronaut` - user astronaut
 * * `user-bounty-hunter` - user bounty hunter
 * * `user-check` - user check
 * * `user-chef` - user chef
 * * `user-clock` - user clock
 * * `user-cowboy` - user cowboy
 * * `user-crown` - user crown
 * * `user-doctor-hair-long` - user doctor hair long
 * * `user-doctor-hair` - user doctor hair
 * * `user-doctor-message` - user doctor message
 * * `user-doctor` - user doctor
 * * `user-gear` - user gear
 * * `user-graduate` - user graduate
 * * `user-group-crown` - user group crown
 * * `user-group` - user group
 * * `user-hair-buns` - user hair buns
 * * `user-hair-long` - user hair long
 * * `user-hair-mullet` - user hair mullet
 * * `user-hair` - user hair
 * * `user-headset` - user headset
 * * `user-helmet-safety` - user helmet safety
 * * `user-injured` - user injured
 * * `user-large-slash` - user large slash
 * * `user-large` - user large
 * * `user-lock` - user lock
 * * `user-magnifying-glass` - user magnifying glass
 * * `user-minus` - user minus
 * * `user-music` - user music
 * * `user-ninja` - user ninja
 * * `user-nurse-hair-long` - user nurse hair long
 * * `user-nurse-hair` - user nurse hair
 * * `user-nurse` - user nurse
 * * `user-pen` - user pen
 * * `user-pilot-tie` - user pilot tie
 * * `user-pilot` - user pilot
 * * `user-plus` - user plus
 * * `user-police-tie` - user police tie
 * * `user-police` - user police
 * * `user-robot-xmarks` - user robot xmarks
 * * `user-robot` - user robot
 * * `user-secret` - user secret
 * * `user-shakespeare` - user shakespeare
 * * `user-shield` - user shield
 * * `user-slash` - user slash
 * * `user-tag` - user tag
 * * `user-tie-hair-long` - user tie hair long
 * * `user-tie-hair` - user tie hair
 * * `user-tie` - user tie
 * * `user-unlock` - user unlock
 * * `user-visor` - user visor
 * * `user-vneck-hair-long` - user vneck hair long
 * * `user-vneck-hair` - user vneck hair
 * * `user-vneck` - user vneck
 * * `user-xmark` - user xmark
 * * `user` - משתמש
 * * `users-between-lines` - users between lines
 * * `users-gear` - users gear
 * * `users-line` - users line
 * * `users-medical` - users medical
 * * `users-rays` - users rays
 * * `users-rectangle` - users rectangle
 * * `users-slash` - users slash
 * * `users-viewfinder` - users viewfinder
 * * `users` - משתמשים
 * * `utensils-slash` - utensils slash
 * * `utensils` - utensils
 * * `utility-pole-double` - utility pole double
 * * `utility-pole` - utility pole
 * * `v` - v
 * * `vacuum-robot` - vacuum robot
 * * `vacuum` - vacuum
 * * `value-absolute` - value absolute
 * * `van-shuttle` - van shuttle
 * * `vault` - vault
 * * `vector-circle` - vector circle
 * * `vector-polygon` - vector polygon
 * * `vector-square` - vector square
 * * `vent-damper` - vent damper
 * * `venus-double` - venus double
 * * `venus-mars` - venus mars
 * * `venus` - venus
 * * `vest-patches` - vest patches
 * * `vest` - vest
 * * `vial-circle-check` - vial circle check
 * * `vial-virus` - vial virus
 * * `vial` - vial
 * * `vials` - vials
 * * `video-arrow-down-left` - video arrow down left
 * * `video-arrow-up-right` - video arrow up right
 * * `video-plus` - video plus
 * * `video-slash` - video slash
 * * `video` - video
 * * `vihara` - vihara
 * * `violin` - violin
 * * `virus-covid-slash` - virus covid slash
 * * `virus-covid` - virus covid
 * * `virus-slash` - virus slash
 * * `virus` - virus
 * * `viruses` - viruses
 * * `voicemail` - voicemail
 * * `volcano` - volcano
 * * `volleyball` - volleyball
 * * `volume-high` - volume high
 * * `volume-low` - volume low
 * * `volume-off` - volume off
 * * `volume-slash` - volume slash
 * * `volume-xmark` - volume xmark
 * * `volume` - volume
 * * `vr-cardboard` - vr cardboard
 * * `w` - w
 * * `waffle` - waffle
 * * `wagon-covered` - wagon covered
 * * `walker` - walker
 * * `walkie-talkie` - walkie talkie
 * * `wallet` - wallet
 * * `wand-magic-sparkles` - wand magic sparkles
 * * `wand-magic` - wand magic
 * * `wand-sparkles` - wand sparkles
 * * `wand` - wand
 * * `warehouse-full` - warehouse full
 * * `warehouse` - warehouse
 * * `washing-machine` - washing machine
 * * `watch-apple` - watch apple
 * * `watch-calculator` - watch calculator
 * * `watch-fitness` - watch fitness
 * * `watch-smart` - watch smart
 * * `watch` - watch
 * * `water-arrow-down` - water arrow down
 * * `water-arrow-up` - water arrow up
 * * `water-ladder` - water ladder
 * * `water` - water
 * * `watermelon-slice` - watermelon slice
 * * `wave-pulse` - wave pulse
 * * `wave-sine` - wave sine
 * * `wave-square` - wave square
 * * `wave-triangle` - wave triangle
 * * `waveform-lines` - waveform lines
 * * `waveform` - waveform
 * * `weight-hanging` - weight hanging
 * * `weight-scale` - weight scale
 * * `whale` - whale
 * * `wheat-awn-circle-exclamation` - wheat awn circle exclamation
 * * `wheat-awn-slash` - wheat awn slash
 * * `wheat-awn` - wheat awn
 * * `wheat-slash` - wheat slash
 * * `wheat` - wheat
 * * `wheelchair-move` - wheelchair move
 * * `wheelchair` - wheelchair
 * * `whiskey-glass-ice` - whiskey glass ice
 * * `whiskey-glass` - whiskey glass
 * * `whistle` - whistle
 * * `wifi-exclamation` - wifi exclamation
 * * `wifi-fair` - wifi fair
 * * `wifi-slash` - wifi slash
 * * `wifi-weak` - wifi weak
 * * `wifi` - wifi
 * * `wind-turbine` - wind turbine
 * * `wind-warning` - wind warning
 * * `wind` - wind
 * * `window-flip` - window flip
 * * `window-frame-open` - window frame open
 * * `window-frame` - window frame
 * * `window-maximize` - window maximize
 * * `window-minimize` - window minimize
 * * `window-restore` - window restore
 * * `window` - window
 * * `windsock` - windsock
 * * `wine-bottle` - wine bottle
 * * `wine-glass-crack` - wine glass crack
 * * `wine-glass-empty` - wine glass empty
 * * `wine-glass` - wine glass
 * * `won-sign` - won sign
 * * `worm` - worm
 * * `wreath` - wreath
 * * `wrench-simple` - wrench simple
 * * `wrench` - wrench
 * * `x-ray` - x ray
 * * `x` - x
 * * `xmark-large` - xmark large
 * * `xmark-to-slot` - xmark to slot
 * * `xmark` - xmark
 * * `xmarks-lines` - xmarks lines
 * * `y` - y
 * * `yen-sign` - yen sign
 * * `yin-yang` - yin yang
 * * `z` - z
 */
export enum IconEnum {
  Whatsapp = 'whatsapp',
  Abacus = 'abacus',
  AccentGrave = 'accent-grave',
  Acorn = 'acorn',
  AddressBook = 'address-book',
  AddressCard = 'address-card',
  AirConditioner = 'air-conditioner',
  Airplay = 'airplay',
  AlarmClock = 'alarm-clock',
  AlarmExclamation = 'alarm-exclamation',
  AlarmPlus = 'alarm-plus',
  AlarmSnooze = 'alarm-snooze',
  AlbumCirclePlus = 'album-circle-plus',
  AlbumCircleUser = 'album-circle-user',
  AlbumCollectionCirclePlus = 'album-collection-circle-plus',
  AlbumCollectionCircleUser = 'album-collection-circle-user',
  AlbumCollection = 'album-collection',
  Album = 'album',
  Alicorn = 'alicorn',
  Alien8Bit = 'alien-8bit',
  Alien = 'alien',
  AlignCenter = 'align-center',
  AlignJustify = 'align-justify',
  AlignLeft = 'align-left',
  AlignRight = 'align-right',
  AlignSlash = 'align-slash',
  Alt = 'alt',
  AmpGuitar = 'amp-guitar',
  Ampersand = 'ampersand',
  AnchorCircleCheck = 'anchor-circle-check',
  AnchorCircleExclamation = 'anchor-circle-exclamation',
  AnchorCircleXmark = 'anchor-circle-xmark',
  AnchorLock = 'anchor-lock',
  Anchor = 'anchor',
  Angel = 'angel',
  Angle90 = 'angle-90',
  AngleDown = 'angle-down',
  AngleLeft = 'angle-left',
  AngleRight = 'angle-right',
  AngleUp = 'angle-up',
  Angle = 'angle',
  AnglesDown = 'angles-down',
  AnglesLeft = 'angles-left',
  AnglesRight = 'angles-right',
  AnglesUp = 'angles-up',
  Ankh = 'ankh',
  Apartment = 'apartment',
  Aperture = 'aperture',
  Apostrophe = 'apostrophe',
  AppleCore = 'apple-core',
  AppleWhole = 'apple-whole',
  Archway = 'archway',
  ArrowDown19 = 'arrow-down-1-9',
  ArrowDown91 = 'arrow-down-9-1',
  ArrowDownAZ = 'arrow-down-a-z',
  ArrowDownArrowUp = 'arrow-down-arrow-up',
  ArrowDownBigSmall = 'arrow-down-big-small',
  ArrowDownFromDottedLine = 'arrow-down-from-dotted-line',
  ArrowDownFromLine = 'arrow-down-from-line',
  ArrowDownLeftAndArrowUpRightToCenter = 'arrow-down-left-and-arrow-up-right-to-center',
  ArrowDownLeft = 'arrow-down-left',
  ArrowDownLong = 'arrow-down-long',
  ArrowDownRight = 'arrow-down-right',
  ArrowDownShortWide = 'arrow-down-short-wide',
  ArrowDownSmallBig = 'arrow-down-small-big',
  ArrowDownSquareTriangle = 'arrow-down-square-triangle',
  ArrowDownToArc = 'arrow-down-to-arc',
  ArrowDownToBracket = 'arrow-down-to-bracket',
  ArrowDownToDottedLine = 'arrow-down-to-dotted-line',
  ArrowDownToLine = 'arrow-down-to-line',
  ArrowDownToSquare = 'arrow-down-to-square',
  ArrowDownTriangleSquare = 'arrow-down-triangle-square',
  ArrowDownUpAcrossLine = 'arrow-down-up-across-line',
  ArrowDownUpLock = 'arrow-down-up-lock',
  ArrowDownWideShort = 'arrow-down-wide-short',
  ArrowDownZA = 'arrow-down-z-a',
  ArrowDown = 'arrow-down',
  ArrowLeftFromLine = 'arrow-left-from-line',
  ArrowLeftLongToLine = 'arrow-left-long-to-line',
  ArrowLeftLong = 'arrow-left-long',
  ArrowLeftToLine = 'arrow-left-to-line',
  ArrowLeft = 'arrow-left',
  ArrowPointer = 'arrow-pointer',
  ArrowRightArrowLeft = 'arrow-right-arrow-left',
  ArrowRightFromArc = 'arrow-right-from-arc',
  ArrowRightFromBracket = 'arrow-right-from-bracket',
  ArrowRightFromLine = 'arrow-right-from-line',
  ArrowRightLongToLine = 'arrow-right-long-to-line',
  ArrowRightLong = 'arrow-right-long',
  ArrowRightToArc = 'arrow-right-to-arc',
  ArrowRightToBracket = 'arrow-right-to-bracket',
  ArrowRightToCity = 'arrow-right-to-city',
  ArrowRightToLine = 'arrow-right-to-line',
  ArrowRight = 'arrow-right',
  ArrowRotateLeft = 'arrow-rotate-left',
  ArrowRotateRight = 'arrow-rotate-right',
  ArrowTrendDown = 'arrow-trend-down',
  ArrowTrendUp = 'arrow-trend-up',
  ArrowTurnDownLeft = 'arrow-turn-down-left',
  ArrowTurnDownRight = 'arrow-turn-down-right',
  ArrowTurnDown = 'arrow-turn-down',
  ArrowTurnUp = 'arrow-turn-up',
  ArrowUp19 = 'arrow-up-1-9',
  ArrowUp91 = 'arrow-up-9-1',
  ArrowUpAZ = 'arrow-up-a-z',
  ArrowUpArrowDown = 'arrow-up-arrow-down',
  ArrowUpBigSmall = 'arrow-up-big-small',
  ArrowUpFromArc = 'arrow-up-from-arc',
  ArrowUpFromBracket = 'arrow-up-from-bracket',
  ArrowUpFromDottedLine = 'arrow-up-from-dotted-line',
  ArrowUpFromGroundWater = 'arrow-up-from-ground-water',
  ArrowUpFromLine = 'arrow-up-from-line',
  ArrowUpFromSquare = 'arrow-up-from-square',
  ArrowUpFromWaterPump = 'arrow-up-from-water-pump',
  ArrowUpLeftFromCircle = 'arrow-up-left-from-circle',
  ArrowUpLeft = 'arrow-up-left',
  ArrowUpLong = 'arrow-up-long',
  ArrowUpRightAndArrowDownLeftFromCenter = 'arrow-up-right-and-arrow-down-left-from-center',
  ArrowUpRightDots = 'arrow-up-right-dots',
  ArrowUpRightFromSquare = 'arrow-up-right-from-square',
  ArrowUpRight = 'arrow-up-right',
  ArrowUpShortWide = 'arrow-up-short-wide',
  ArrowUpSmallBig = 'arrow-up-small-big',
  ArrowUpSquareTriangle = 'arrow-up-square-triangle',
  ArrowUpToDottedLine = 'arrow-up-to-dotted-line',
  ArrowUpToLine = 'arrow-up-to-line',
  ArrowUpTriangleSquare = 'arrow-up-triangle-square',
  ArrowUpWideShort = 'arrow-up-wide-short',
  ArrowUpZA = 'arrow-up-z-a',
  ArrowUp = 'arrow-up',
  ArrowsCross = 'arrows-cross',
  ArrowsDownToLine = 'arrows-down-to-line',
  ArrowsDownToPeople = 'arrows-down-to-people',
  ArrowsFromDottedLine = 'arrows-from-dotted-line',
  ArrowsFromLine = 'arrows-from-line',
  ArrowsLeftRightToLine = 'arrows-left-right-to-line',
  ArrowsLeftRight = 'arrows-left-right',
  ArrowsMaximize = 'arrows-maximize',
  ArrowsMinimize = 'arrows-minimize',
  ArrowsRepeat1 = 'arrows-repeat-1',
  ArrowsRepeat = 'arrows-repeat',
  ArrowsRetweet = 'arrows-retweet',
  ArrowsRotate = 'arrows-rotate',
  ArrowsSpin = 'arrows-spin',
  ArrowsSplitUpAndLeft = 'arrows-split-up-and-left',
  ArrowsToCircle = 'arrows-to-circle',
  ArrowsToDot = 'arrows-to-dot',
  ArrowsToDottedLine = 'arrows-to-dotted-line',
  ArrowsToEye = 'arrows-to-eye',
  ArrowsToLine = 'arrows-to-line',
  ArrowsTurnRight = 'arrows-turn-right',
  ArrowsTurnToDots = 'arrows-turn-to-dots',
  ArrowsUpDownLeftRight = 'arrows-up-down-left-right',
  ArrowsUpDown = 'arrows-up-down',
  ArrowsUpToLine = 'arrows-up-to-line',
  Asterisk = 'asterisk',
  At = 'at',
  AtomSimple = 'atom-simple',
  Atom = 'atom',
  AudioDescriptionSlash = 'audio-description-slash',
  AudioDescription = 'audio-description',
  AustralSign = 'austral-sign',
  Avocado = 'avocado',
  AwardSimple = 'award-simple',
  Award = 'award',
  AxeBattle = 'axe-battle',
  Axe = 'axe',
  B = 'b',
  BabyCarriage = 'baby-carriage',
  Baby = 'baby',
  Backpack = 'backpack',
  BackwardFast = 'backward-fast',
  BackwardStep = 'backward-step',
  Backward = 'backward',
  Bacon = 'bacon',
  Bacteria = 'bacteria',
  Bacterium = 'bacterium',
  BadgeCheck = 'badge-check',
  BadgeDollar = 'badge-dollar',
  BadgePercent = 'badge-percent',
  BadgeSheriff = 'badge-sheriff',
  Badge = 'badge',
  BadgerHoney = 'badger-honey',
  Badminton = 'badminton',
  BagShopping = 'bag-shopping',
  Bagel = 'bagel',
  BagsShopping = 'bags-shopping',
  Baguette = 'baguette',
  Bahai = 'bahai',
  BahtSign = 'baht-sign',
  BallPile = 'ball-pile',
  Balloon = 'balloon',
  Balloons = 'balloons',
  BallotCheck = 'ballot-check',
  Ballot = 'ballot',
  BanBug = 'ban-bug',
  BanParking = 'ban-parking',
  BanSmoking = 'ban-smoking',
  Ban = 'ban',
  Banana = 'banana',
  Bandage = 'bandage',
  BangladeshiTakaSign = 'bangladeshi-taka-sign',
  Banjo = 'banjo',
  BarcodeRead = 'barcode-read',
  BarcodeScan = 'barcode-scan',
  Barcode = 'barcode',
  BarsFilter = 'bars-filter',
  BarsProgress = 'bars-progress',
  BarsSort = 'bars-sort',
  BarsStaggered = 'bars-staggered',
  Bars = 'bars',
  BaseballBatBall = 'baseball-bat-ball',
  Baseball = 'baseball',
  BasketShoppingSimple = 'basket-shopping-simple',
  BasketShopping = 'basket-shopping',
  BasketballHoop = 'basketball-hoop',
  Basketball = 'basketball',
  Bat = 'bat',
  Bath = 'bath',
  BatteryBolt = 'battery-bolt',
  BatteryEmpty = 'battery-empty',
  BatteryExclamation = 'battery-exclamation',
  BatteryFull = 'battery-full',
  BatteryHalf = 'battery-half',
  BatteryLow = 'battery-low',
  BatteryQuarter = 'battery-quarter',
  BatterySlash = 'battery-slash',
  BatteryThreeQuarters = 'battery-three-quarters',
  BedBunk = 'bed-bunk',
  BedEmpty = 'bed-empty',
  BedFront = 'bed-front',
  BedPulse = 'bed-pulse',
  Bed = 'bed',
  Bee = 'bee',
  BeerMugEmpty = 'beer-mug-empty',
  BeerMug = 'beer-mug',
  BellConcierge = 'bell-concierge',
  BellExclamation = 'bell-exclamation',
  BellOn = 'bell-on',
  BellPlus = 'bell-plus',
  BellSchoolSlash = 'bell-school-slash',
  BellSchool = 'bell-school',
  BellSlash = 'bell-slash',
  Bell = 'bell',
  Bells = 'bells',
  BenchTree = 'bench-tree',
  BezierCurve = 'bezier-curve',
  Bicycle = 'bicycle',
  BinaryCircleCheck = 'binary-circle-check',
  BinaryLock = 'binary-lock',
  BinarySlash = 'binary-slash',
  Binary = 'binary',
  Binoculars = 'binoculars',
  Biohazard = 'biohazard',
  Bird = 'bird',
  BitcoinSign = 'bitcoin-sign',
  BlanketFire = 'blanket-fire',
  Blanket = 'blanket',
  BlenderPhone = 'blender-phone',
  Blender = 'blender',
  BlindsOpen = 'blinds-open',
  BlindsRaised = 'blinds-raised',
  Blinds = 'blinds',
  BlockBrickFire = 'block-brick-fire',
  BlockBrick = 'block-brick',
  BlockQuestion = 'block-question',
  BlockQuote = 'block-quote',
  Block = 'block',
  Blog = 'blog',
  Blueberries = 'blueberries',
  Bluetooth = 'bluetooth',
  Bold = 'bold',
  BoltAuto = 'bolt-auto',
  BoltLightning = 'bolt-lightning',
  BoltSlash = 'bolt-slash',
  Bolt = 'bolt',
  Bomb = 'bomb',
  BoneBreak = 'bone-break',
  Bone = 'bone',
  Bong = 'bong',
  BookArrowRight = 'book-arrow-right',
  BookArrowUp = 'book-arrow-up',
  BookAtlas = 'book-atlas',
  BookBible = 'book-bible',
  BookBlank = 'book-blank',
  BookBookmark = 'book-bookmark',
  BookCircleArrowRight = 'book-circle-arrow-right',
  BookCircleArrowUp = 'book-circle-arrow-up',
  BookCopy = 'book-copy',
  BookFont = 'book-font',
  BookHeart = 'book-heart',
  BookJournalWhills = 'book-journal-whills',
  BookMedical = 'book-medical',
  BookOpenCover = 'book-open-cover',
  BookOpenReader = 'book-open-reader',
  BookOpen = 'book-open',
  BookQuran = 'book-quran',
  BookSection = 'book-section',
  BookSkull = 'book-skull',
  BookSparkles = 'book-sparkles',
  BookTanakh = 'book-tanakh',
  BookUser = 'book-user',
  Book = 'book',
  BookmarkSlash = 'bookmark-slash',
  Bookmark = 'bookmark',
  BooksMedical = 'books-medical',
  Books = 'books',
  Boombox = 'boombox',
  BootHeeled = 'boot-heeled',
  Boot = 'boot',
  BoothCurtain = 'booth-curtain',
  BorderAll = 'border-all',
  BorderBottomRight = 'border-bottom-right',
  BorderBottom = 'border-bottom',
  BorderCenterH = 'border-center-h',
  BorderCenterV = 'border-center-v',
  BorderInner = 'border-inner',
  BorderLeft = 'border-left',
  BorderNone = 'border-none',
  BorderOuter = 'border-outer',
  BorderRight = 'border-right',
  BorderTopLeft = 'border-top-left',
  BorderTop = 'border-top',
  BoreHole = 'bore-hole',
  BottleDroplet = 'bottle-droplet',
  BottleWater = 'bottle-water',
  BowArrow = 'bow-arrow',
  BowlChopsticksNoodles = 'bowl-chopsticks-noodles',
  BowlChopsticks = 'bowl-chopsticks',
  BowlFood = 'bowl-food',
  BowlHot = 'bowl-hot',
  BowlRice = 'bowl-rice',
  BowlScoop = 'bowl-scoop',
  BowlScoops = 'bowl-scoops',
  BowlSoftServe = 'bowl-soft-serve',
  BowlSpoon = 'bowl-spoon',
  BowlingBallPin = 'bowling-ball-pin',
  BowlingBall = 'bowling-ball',
  BowlingPins = 'bowling-pins',
  BoxArchive = 'box-archive',
  BoxBallot = 'box-ballot',
  BoxCheck = 'box-check',
  BoxCircleCheck = 'box-circle-check',
  BoxDollar = 'box-dollar',
  BoxHeart = 'box-heart',
  BoxOpenFull = 'box-open-full',
  BoxOpen = 'box-open',
  BoxTaped = 'box-taped',
  BoxTissue = 'box-tissue',
  Box = 'box',
  BoxesPacking = 'boxes-packing',
  BoxesStacked = 'boxes-stacked',
  BoxingGlove = 'boxing-glove',
  BracketCurlyRight = 'bracket-curly-right',
  BracketCurly = 'bracket-curly',
  BracketRoundRight = 'bracket-round-right',
  BracketRound = 'bracket-round',
  BracketSquareRight = 'bracket-square-right',
  BracketSquare = 'bracket-square',
  BracketsCurly = 'brackets-curly',
  BracketsRound = 'brackets-round',
  BracketsSquare = 'brackets-square',
  Braille = 'braille',
  BrainArrowCurvedRight = 'brain-arrow-curved-right',
  BrainCircuit = 'brain-circuit',
  Brain = 'brain',
  BrakeWarning = 'brake-warning',
  BrazilianRealSign = 'brazilian-real-sign',
  BreadLoaf = 'bread-loaf',
  BreadSliceButter = 'bread-slice-butter',
  BreadSlice = 'bread-slice',
  BridgeCircleCheck = 'bridge-circle-check',
  BridgeCircleExclamation = 'bridge-circle-exclamation',
  BridgeCircleXmark = 'bridge-circle-xmark',
  BridgeLock = 'bridge-lock',
  BridgeSuspension = 'bridge-suspension',
  BridgeWater = 'bridge-water',
  Bridge = 'bridge',
  BriefcaseArrowRight = 'briefcase-arrow-right',
  BriefcaseBlank = 'briefcase-blank',
  BriefcaseMedical = 'briefcase-medical',
  Briefcase = 'briefcase',
  BrightnessLow = 'brightness-low',
  Brightness = 'brightness',
  BringForward = 'bring-forward',
  BringFront = 'bring-front',
  Broccoli = 'broccoli',
  BroomBall = 'broom-ball',
  Broom = 'broom',
  Browser = 'browser',
  Browsers = 'browsers',
  Brush = 'brush',
  Bucket = 'bucket',
  BugSlash = 'bug-slash',
  Bug = 'bug',
  Bugs = 'bugs',
  BuildingCircleArrowRight = 'building-circle-arrow-right',
  BuildingCircleCheck = 'building-circle-check',
  BuildingCircleExclamation = 'building-circle-exclamation',
  BuildingCircleXmark = 'building-circle-xmark',
  BuildingColumns = 'building-columns',
  BuildingFlag = 'building-flag',
  BuildingLock = 'building-lock',
  BuildingNgo = 'building-ngo',
  BuildingShield = 'building-shield',
  BuildingUn = 'building-un',
  BuildingUser = 'building-user',
  BuildingWheat = 'building-wheat',
  Building = 'building',
  Buildings = 'buildings',
  Bullhorn = 'bullhorn',
  BullseyeArrow = 'bullseye-arrow',
  BullseyePointer = 'bullseye-pointer',
  Bullseye = 'bullseye',
  BuoyMooring = 'buoy-mooring',
  Buoy = 'buoy',
  BurgerCheese = 'burger-cheese',
  BurgerFries = 'burger-fries',
  BurgerGlass = 'burger-glass',
  BurgerLettuce = 'burger-lettuce',
  BurgerSoda = 'burger-soda',
  Burger = 'burger',
  Burrito = 'burrito',
  Burst = 'burst',
  BusSchool = 'bus-school',
  BusSimple = 'bus-simple',
  Bus = 'bus',
  BusinessTime = 'business-time',
  Butter = 'butter',
  C = 'c',
  Cabin = 'cabin',
  CabinetFiling = 'cabinet-filing',
  CableCar = 'cable-car',
  Cactus = 'cactus',
  CakeCandles = 'cake-candles',
  CakeSlice = 'cake-slice',
  CalculatorSimple = 'calculator-simple',
  Calculator = 'calculator',
  CalendarArrowDown = 'calendar-arrow-down',
  CalendarArrowUp = 'calendar-arrow-up',
  CalendarCheck = 'calendar-check',
  CalendarCircleExclamation = 'calendar-circle-exclamation',
  CalendarCircleMinus = 'calendar-circle-minus',
  CalendarCirclePlus = 'calendar-circle-plus',
  CalendarCircleUser = 'calendar-circle-user',
  CalendarClock = 'calendar-clock',
  CalendarDay = 'calendar-day',
  CalendarDays = 'calendar-days',
  CalendarExclamation = 'calendar-exclamation',
  CalendarHeart = 'calendar-heart',
  CalendarImage = 'calendar-image',
  CalendarLinesPen = 'calendar-lines-pen',
  CalendarLines = 'calendar-lines',
  CalendarMinus = 'calendar-minus',
  CalendarPen = 'calendar-pen',
  CalendarPlus = 'calendar-plus',
  CalendarRange = 'calendar-range',
  CalendarStar = 'calendar-star',
  CalendarWeek = 'calendar-week',
  CalendarXmark = 'calendar-xmark',
  Calendar = 'calendar',
  Calendars = 'calendars',
  Camcorder = 'camcorder',
  CameraCctv = 'camera-cctv',
  CameraMovie = 'camera-movie',
  CameraPolaroid = 'camera-polaroid',
  CameraRetro = 'camera-retro',
  CameraRotate = 'camera-rotate',
  CameraSecurity = 'camera-security',
  CameraSlash = 'camera-slash',
  CameraViewfinder = 'camera-viewfinder',
  CameraWebSlash = 'camera-web-slash',
  CameraWeb = 'camera-web',
  Camera = 'camera',
  Campfire = 'campfire',
  Campground = 'campground',
  CanFood = 'can-food',
  CandleHolder = 'candle-holder',
  CandyBar = 'candy-bar',
  CandyCane = 'candy-cane',
  CandyCorn = 'candy-corn',
  Candy = 'candy',
  Cannabis = 'cannabis',
  Capsules = 'capsules',
  CarBattery = 'car-battery',
  CarBolt = 'car-bolt',
  CarBuilding = 'car-building',
  CarBump = 'car-bump',
  CarBurst = 'car-burst',
  CarBus = 'car-bus',
  CarCircleBolt = 'car-circle-bolt',
  CarGarage = 'car-garage',
  CarMirrors = 'car-mirrors',
  CarOn = 'car-on',
  CarRear = 'car-rear',
  CarSideBolt = 'car-side-bolt',
  CarSide = 'car-side',
  CarTilt = 'car-tilt',
  CarTunnel = 'car-tunnel',
  CarWash = 'car-wash',
  CarWrench = 'car-wrench',
  Car = 'car',
  CaravanSimple = 'caravan-simple',
  Caravan = 'caravan',
  CardClub = 'card-club',
  CardDiamond = 'card-diamond',
  CardHeart = 'card-heart',
  CardSpade = 'card-spade',
  CardsBlank = 'cards-blank',
  Cards = 'cards',
  CaretDown = 'caret-down',
  CaretLeft = 'caret-left',
  CaretRight = 'caret-right',
  CaretUp = 'caret-up',
  Carrot = 'carrot',
  Cars = 'cars',
  CartArrowDown = 'cart-arrow-down',
  CartArrowUp = 'cart-arrow-up',
  CartCircleArrowDown = 'cart-circle-arrow-down',
  CartCircleArrowUp = 'cart-circle-arrow-up',
  CartCircleCheck = 'cart-circle-check',
  CartCircleExclamation = 'cart-circle-exclamation',
  CartCirclePlus = 'cart-circle-plus',
  CartCircleXmark = 'cart-circle-xmark',
  CartFlatbedBoxes = 'cart-flatbed-boxes',
  CartFlatbedEmpty = 'cart-flatbed-empty',
  CartFlatbedSuitcase = 'cart-flatbed-suitcase',
  CartFlatbed = 'cart-flatbed',
  CartMinus = 'cart-minus',
  CartPlus = 'cart-plus',
  CartShoppingFast = 'cart-shopping-fast',
  CartShopping = 'cart-shopping',
  CartXmark = 'cart-xmark',
  CashRegister = 'cash-register',
  CassetteBetamax = 'cassette-betamax',
  CassetteTape = 'cassette-tape',
  CassetteVhs = 'cassette-vhs',
  Castle = 'castle',
  CatSpace = 'cat-space',
  Cat = 'cat',
  Cauldron = 'cauldron',
  CediSign = 'cedi-sign',
  CentSign = 'cent-sign',
  Certificate = 'certificate',
  ChairOffice = 'chair-office',
  Chair = 'chair',
  ChalkboardUser = 'chalkboard-user',
  Chalkboard = 'chalkboard',
  ChampagneGlass = 'champagne-glass',
  ChampagneGlasses = 'champagne-glasses',
  ChargingStation = 'charging-station',
  ChartArea = 'chart-area',
  ChartBar = 'chart-bar',
  ChartBullet = 'chart-bullet',
  ChartCandlestick = 'chart-candlestick',
  ChartColumn = 'chart-column',
  ChartGantt = 'chart-gantt',
  ChartLineDown = 'chart-line-down',
  ChartLineUp = 'chart-line-up',
  ChartLine = 'chart-line',
  ChartMixed = 'chart-mixed',
  ChartNetwork = 'chart-network',
  ChartPieSimple = 'chart-pie-simple',
  ChartPie = 'chart-pie',
  ChartPyramid = 'chart-pyramid',
  ChartRadar = 'chart-radar',
  ChartScatter3D = 'chart-scatter-3d',
  ChartScatterBubble = 'chart-scatter-bubble',
  ChartScatter = 'chart-scatter',
  ChartSimpleHorizontal = 'chart-simple-horizontal',
  ChartSimple = 'chart-simple',
  ChartTreeMap = 'chart-tree-map',
  ChartUser = 'chart-user',
  ChartWaterfall = 'chart-waterfall',
  CheckDouble = 'check-double',
  CheckToSlot = 'check-to-slot',
  Check = 'check',
  CheeseSwiss = 'cheese-swiss',
  Cheese = 'cheese',
  Cherries = 'cherries',
  ChessBishopPiece = 'chess-bishop-piece',
  ChessBishop = 'chess-bishop',
  ChessBoard = 'chess-board',
  ChessClockFlip = 'chess-clock-flip',
  ChessClock = 'chess-clock',
  ChessKingPiece = 'chess-king-piece',
  ChessKing = 'chess-king',
  ChessKnightPiece = 'chess-knight-piece',
  ChessKnight = 'chess-knight',
  ChessPawnPiece = 'chess-pawn-piece',
  ChessPawn = 'chess-pawn',
  ChessQueenPiece = 'chess-queen-piece',
  ChessQueen = 'chess-queen',
  ChessRookPiece = 'chess-rook-piece',
  ChessRook = 'chess-rook',
  Chess = 'chess',
  Chestnut = 'chestnut',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ChevronsDown = 'chevrons-down',
  ChevronsLeft = 'chevrons-left',
  ChevronsRight = 'chevrons-right',
  ChevronsUp = 'chevrons-up',
  ChildCombatant = 'child-combatant',
  ChildDress = 'child-dress',
  ChildReaching = 'child-reaching',
  Child = 'child',
  Children = 'children',
  Chimney = 'chimney',
  Chopsticks = 'chopsticks',
  Church = 'church',
  Circle0 = 'circle-0',
  Circle1 = 'circle-1',
  Circle2 = 'circle-2',
  Circle3 = 'circle-3',
  Circle4 = 'circle-4',
  Circle5 = 'circle-5',
  Circle6 = 'circle-6',
  Circle7 = 'circle-7',
  Circle8 = 'circle-8',
  Circle9 = 'circle-9',
  CircleA = 'circle-a',
  CircleAmpersand = 'circle-ampersand',
  CircleArrowDownLeft = 'circle-arrow-down-left',
  CircleArrowDownRight = 'circle-arrow-down-right',
  CircleArrowDown = 'circle-arrow-down',
  CircleArrowLeft = 'circle-arrow-left',
  CircleArrowRight = 'circle-arrow-right',
  CircleArrowUpLeft = 'circle-arrow-up-left',
  CircleArrowUpRight = 'circle-arrow-up-right',
  CircleArrowUp = 'circle-arrow-up',
  CircleB = 'circle-b',
  CircleBolt = 'circle-bolt',
  CircleBookOpen = 'circle-book-open',
  CircleBookmark = 'circle-bookmark',
  CircleC = 'circle-c',
  CircleCalendar = 'circle-calendar',
  CircleCamera = 'circle-camera',
  CircleCaretDown = 'circle-caret-down',
  CircleCaretLeft = 'circle-caret-left',
  CircleCaretRight = 'circle-caret-right',
  CircleCaretUp = 'circle-caret-up',
  CircleCheck = 'circle-check',
  CircleChevronDown = 'circle-chevron-down',
  CircleChevronLeft = 'circle-chevron-left',
  CircleChevronRight = 'circle-chevron-right',
  CircleChevronUp = 'circle-chevron-up',
  CircleD = 'circle-d',
  CircleDashed = 'circle-dashed',
  CircleDivide = 'circle-divide',
  CircleDollarToSlot = 'circle-dollar-to-slot',
  CircleDollar = 'circle-dollar',
  CircleDot = 'circle-dot',
  CircleDownLeft = 'circle-down-left',
  CircleDownRight = 'circle-down-right',
  CircleDown = 'circle-down',
  CircleE = 'circle-e',
  CircleEllipsisVertical = 'circle-ellipsis-vertical',
  CircleEllipsis = 'circle-ellipsis',
  CircleEnvelope = 'circle-envelope',
  CircleExclamationCheck = 'circle-exclamation-check',
  CircleExclamation = 'circle-exclamation',
  CircleF = 'circle-f',
  CircleG = 'circle-g',
  CircleH = 'circle-h',
  CircleHalfStroke = 'circle-half-stroke',
  CircleHalf = 'circle-half',
  CircleHeart = 'circle-heart',
  CircleI = 'circle-i',
  CircleInfo = 'circle-info',
  CircleJ = 'circle-j',
  CircleK = 'circle-k',
  CircleL = 'circle-l',
  CircleLeft = 'circle-left',
  CircleLocationArrow = 'circle-location-arrow',
  CircleM = 'circle-m',
  CircleMicrophoneLines = 'circle-microphone-lines',
  CircleMicrophone = 'circle-microphone',
  CircleMinus = 'circle-minus',
  CircleN = 'circle-n',
  CircleNodes = 'circle-nodes',
  CircleNotch = 'circle-notch',
  CircleO = 'circle-o',
  CircleP = 'circle-p',
  CircleParking = 'circle-parking',
  CirclePause = 'circle-pause',
  CirclePhoneFlip = 'circle-phone-flip',
  CirclePhoneHangup = 'circle-phone-hangup',
  CirclePhone = 'circle-phone',
  CirclePlay = 'circle-play',
  CirclePlus = 'circle-plus',
  CircleQ = 'circle-q',
  CircleQuarter = 'circle-quarter',
  CircleQuarters = 'circle-quarters',
  CircleQuestion = 'circle-question',
  CircleR = 'circle-r',
  CircleRadiation = 'circle-radiation',
  CircleRight = 'circle-right',
  CircleS = 'circle-s',
  CircleSmall = 'circle-small',
  CircleSortDown = 'circle-sort-down',
  CircleSortUp = 'circle-sort-up',
  CircleSort = 'circle-sort',
  CircleStar = 'circle-star',
  CircleStop = 'circle-stop',
  CircleT = 'circle-t',
  CircleThreeQuarters = 'circle-three-quarters',
  CircleTrash = 'circle-trash',
  CircleU = 'circle-u',
  CircleUpLeft = 'circle-up-left',
  CircleUpRight = 'circle-up-right',
  CircleUp = 'circle-up',
  CircleUser = 'circle-user',
  CircleV = 'circle-v',
  CircleVideo = 'circle-video',
  CircleW = 'circle-w',
  CircleWaveformLines = 'circle-waveform-lines',
  CircleX = 'circle-x',
  CircleXmark = 'circle-xmark',
  CircleY = 'circle-y',
  CircleZ = 'circle-z',
  Circle = 'circle',
  CitrusSlice = 'citrus-slice',
  Citrus = 'citrus',
  City = 'city',
  ClapperboardPlay = 'clapperboard-play',
  Clapperboard = 'clapperboard',
  Clarinet = 'clarinet',
  ClawMarks = 'claw-marks',
  ClipboardCheck = 'clipboard-check',
  ClipboardListCheck = 'clipboard-list-check',
  ClipboardList = 'clipboard-list',
  ClipboardMedical = 'clipboard-medical',
  ClipboardPrescription = 'clipboard-prescription',
  ClipboardQuestion = 'clipboard-question',
  ClipboardUser = 'clipboard-user',
  Clipboard = 'clipboard',
  ClockDesk = 'clock-desk',
  ClockEightThirty = 'clock-eight-thirty',
  ClockEight = 'clock-eight',
  ClockElevenThirty = 'clock-eleven-thirty',
  ClockEleven = 'clock-eleven',
  ClockFiveThirty = 'clock-five-thirty',
  ClockFive = 'clock-five',
  ClockFourThirty = 'clock-four-thirty',
  ClockNineThirty = 'clock-nine-thirty',
  ClockNine = 'clock-nine',
  ClockOneThirty = 'clock-one-thirty',
  ClockOne = 'clock-one',
  ClockRotateLeft = 'clock-rotate-left',
  ClockSevenThirty = 'clock-seven-thirty',
  ClockSeven = 'clock-seven',
  ClockSixThirty = 'clock-six-thirty',
  ClockSix = 'clock-six',
  ClockTenThirty = 'clock-ten-thirty',
  ClockTen = 'clock-ten',
  ClockThreeThirty = 'clock-three-thirty',
  ClockThree = 'clock-three',
  ClockTwelveThirty = 'clock-twelve-thirty',
  ClockTwelve = 'clock-twelve',
  ClockTwoThirty = 'clock-two-thirty',
  ClockTwo = 'clock-two',
  Clock = 'clock',
  Clone = 'clone',
  ClosedCaptioningSlash = 'closed-captioning-slash',
  ClosedCaptioning = 'closed-captioning',
  ClothesHanger = 'clothes-hanger',
  CloudArrowDown = 'cloud-arrow-down',
  CloudArrowUp = 'cloud-arrow-up',
  CloudBoltMoon = 'cloud-bolt-moon',
  CloudBoltSun = 'cloud-bolt-sun',
  CloudBolt = 'cloud-bolt',
  CloudCheck = 'cloud-check',
  CloudDrizzle = 'cloud-drizzle',
  CloudExclamation = 'cloud-exclamation',
  CloudFog = 'cloud-fog',
  CloudHailMixed = 'cloud-hail-mixed',
  CloudHail = 'cloud-hail',
  CloudMeatball = 'cloud-meatball',
  CloudMinus = 'cloud-minus',
  CloudMoonRain = 'cloud-moon-rain',
  CloudMoon = 'cloud-moon',
  CloudMusic = 'cloud-music',
  CloudPlus = 'cloud-plus',
  CloudQuestion = 'cloud-question',
  CloudRain = 'cloud-rain',
  CloudRainbow = 'cloud-rainbow',
  CloudShowersHeavy = 'cloud-showers-heavy',
  CloudShowersWater = 'cloud-showers-water',
  CloudShowers = 'cloud-showers',
  CloudSlash = 'cloud-slash',
  CloudSleet = 'cloud-sleet',
  CloudSnow = 'cloud-snow',
  CloudSunRain = 'cloud-sun-rain',
  CloudSun = 'cloud-sun',
  CloudWord = 'cloud-word',
  CloudXmark = 'cloud-xmark',
  Cloud = 'cloud',
  CloudsMoon = 'clouds-moon',
  CloudsSun = 'clouds-sun',
  Clouds = 'clouds',
  Clover = 'clover',
  Club = 'club',
  Coconut = 'coconut',
  CodeBranch = 'code-branch',
  CodeCommit = 'code-commit',
  CodeCompare = 'code-compare',
  CodeFork = 'code-fork',
  CodeMerge = 'code-merge',
  CodePullRequestClosed = 'code-pull-request-closed',
  CodePullRequestDraft = 'code-pull-request-draft',
  CodePullRequest = 'code-pull-request',
  CodeSimple = 'code-simple',
  Code = 'code',
  CoffeeBean = 'coffee-bean',
  CoffeeBeans = 'coffee-beans',
  CoffeePot = 'coffee-pot',
  CoffinCross = 'coffin-cross',
  Coffin = 'coffin',
  CoinBlank = 'coin-blank',
  CoinFront = 'coin-front',
  CoinVertical = 'coin-vertical',
  Coin = 'coin',
  Coins = 'coins',
  ColonSign = 'colon-sign',
  Colon = 'colon',
  Columns3 = 'columns-3',
  Comet = 'comet',
  Comma = 'comma',
  Command = 'command',
  CommentArrowDown = 'comment-arrow-down',
  CommentArrowUpRight = 'comment-arrow-up-right',
  CommentArrowUp = 'comment-arrow-up',
  CommentCaptions = 'comment-captions',
  CommentCheck = 'comment-check',
  CommentCode = 'comment-code',
  CommentDollar = 'comment-dollar',
  CommentDots = 'comment-dots',
  CommentExclamation = 'comment-exclamation',
  CommentHeart = 'comment-heart',
  CommentImage = 'comment-image',
  CommentLines = 'comment-lines',
  CommentMedical = 'comment-medical',
  CommentMiddleTop = 'comment-middle-top',
  CommentMiddle = 'comment-middle',
  CommentMinus = 'comment-minus',
  CommentMusic = 'comment-music',
  CommentPen = 'comment-pen',
  CommentPlus = 'comment-plus',
  CommentQuestion = 'comment-question',
  CommentQuote = 'comment-quote',
  CommentSlash = 'comment-slash',
  CommentSmile = 'comment-smile',
  CommentSms = 'comment-sms',
  CommentText = 'comment-text',
  CommentXmark = 'comment-xmark',
  Comment = 'comment',
  CommentsDollar = 'comments-dollar',
  CommentsQuestionCheck = 'comments-question-check',
  CommentsQuestion = 'comments-question',
  Comments = 'comments',
  CompactDisc = 'compact-disc',
  CompassDrafting = 'compass-drafting',
  CompassSlash = 'compass-slash',
  Compass = 'compass',
  CompressWide = 'compress-wide',
  Compress = 'compress',
  ComputerClassic = 'computer-classic',
  ComputerMouseScrollwheel = 'computer-mouse-scrollwheel',
  ComputerMouse = 'computer-mouse',
  ComputerSpeaker = 'computer-speaker',
  Computer = 'computer',
  ContainerStorage = 'container-storage',
  ConveyorBeltBoxes = 'conveyor-belt-boxes',
  ConveyorBeltEmpty = 'conveyor-belt-empty',
  ConveyorBelt = 'conveyor-belt',
  CookieBite = 'cookie-bite',
  Cookie = 'cookie',
  Copy = 'copy',
  Copyright = 'copyright',
  Corn = 'corn',
  Corner = 'corner',
  Couch = 'couch',
  Cow = 'cow',
  CowbellCirclePlus = 'cowbell-circle-plus',
  Cowbell = 'cowbell',
  Crab = 'crab',
  CrateApple = 'crate-apple',
  CrateEmpty = 'crate-empty',
  CreditCardBlank = 'credit-card-blank',
  CreditCardFront = 'credit-card-front',
  CreditCard = 'credit-card',
  CricketBatBall = 'cricket-bat-ball',
  Croissant = 'croissant',
  CropSimple = 'crop-simple',
  Crop = 'crop',
  Cross = 'cross',
  CrosshairsSimple = 'crosshairs-simple',
  Crosshairs = 'crosshairs',
  Crow = 'crow',
  Crown = 'crown',
  Crutch = 'crutch',
  Crutches = 'crutches',
  CruzeiroSign = 'cruzeiro-sign',
  CrystalBall = 'crystal-ball',
  Cube = 'cube',
  CubesStacked = 'cubes-stacked',
  Cubes = 'cubes',
  Cucumber = 'cucumber',
  CupStrawSwoosh = 'cup-straw-swoosh',
  CupStraw = 'cup-straw',
  CupTogo = 'cup-togo',
  Cupcake = 'cupcake',
  CurlingStone = 'curling-stone',
  Custard = 'custard',
  D = 'd',
  Dagger = 'dagger',
  Dash = 'dash',
  Database = 'database',
  DeerRudolph = 'deer-rudolph',
  Deer = 'deer',
  DeleteLeft = 'delete-left',
  DeleteRight = 'delete-right',
  Democrat = 'democrat',
  DesktopArrowDown = 'desktop-arrow-down',
  Desktop = 'desktop',
  Dharmachakra = 'dharmachakra',
  DiagramCells = 'diagram-cells',
  DiagramLeanCanvas = 'diagram-lean-canvas',
  DiagramNested = 'diagram-nested',
  DiagramNext = 'diagram-next',
  DiagramPredecessor = 'diagram-predecessor',
  DiagramPrevious = 'diagram-previous',
  DiagramProject = 'diagram-project',
  DiagramSankey = 'diagram-sankey',
  DiagramSubtask = 'diagram-subtask',
  DiagramSuccessor = 'diagram-successor',
  DiagramVenn = 'diagram-venn',
  DialHigh = 'dial-high',
  DialLow = 'dial-low',
  DialMax = 'dial-max',
  DialMedLow = 'dial-med-low',
  DialMed = 'dial-med',
  DialMin = 'dial-min',
  DialOff = 'dial-off',
  Dial = 'dial',
  DiamondExclamation = 'diamond-exclamation',
  DiamondHalfStroke = 'diamond-half-stroke',
  DiamondHalf = 'diamond-half',
  DiamondTurnRight = 'diamond-turn-right',
  Diamond = 'diamond',
  DiceD10 = 'dice-d10',
  DiceD12 = 'dice-d12',
  DiceD20 = 'dice-d20',
  DiceD4 = 'dice-d4',
  DiceD6 = 'dice-d6',
  DiceD8 = 'dice-d8',
  DiceFive = 'dice-five',
  DiceFour = 'dice-four',
  DiceOne = 'dice-one',
  DiceSix = 'dice-six',
  DiceThree = 'dice-three',
  DiceTwo = 'dice-two',
  Dice = 'dice',
  Diploma = 'diploma',
  DiscDrive = 'disc-drive',
  Disease = 'disease',
  DisplayArrowDown = 'display-arrow-down',
  DisplayCode = 'display-code',
  DisplayMedical = 'display-medical',
  DisplaySlash = 'display-slash',
  Display = 'display',
  DistributeSpacingHorizontal = 'distribute-spacing-horizontal',
  DistributeSpacingVertical = 'distribute-spacing-vertical',
  Ditto = 'ditto',
  Divide = 'divide',
  Dna = 'dna',
  DoNotEnter = 'do-not-enter',
  DogLeashed = 'dog-leashed',
  Dog = 'dog',
  DollarSign = 'dollar-sign',
  DollyEmpty = 'dolly-empty',
  Dolly = 'dolly',
  Dolphin = 'dolphin',
  DongSign = 'dong-sign',
  Donut = 'donut',
  DoorClosed = 'door-closed',
  DoorOpen = 'door-open',
  Dove = 'dove',
  DownFromDottedLine = 'down-from-dotted-line',
  DownFromLine = 'down-from-line',
  DownLeftAndUpRightToCenter = 'down-left-and-up-right-to-center',
  DownLeft = 'down-left',
  DownLong = 'down-long',
  DownRight = 'down-right',
  DownToBracket = 'down-to-bracket',
  DownToDottedLine = 'down-to-dotted-line',
  DownToLine = 'down-to-line',
  Down = 'down',
  Download = 'download',
  Dragon = 'dragon',
  DrawCircle = 'draw-circle',
  DrawPolygon = 'draw-polygon',
  DrawSquare = 'draw-square',
  Dreidel = 'dreidel',
  DroneFront = 'drone-front',
  Drone = 'drone',
  DropletDegree = 'droplet-degree',
  DropletPercent = 'droplet-percent',
  DropletSlash = 'droplet-slash',
  Droplet = 'droplet',
  DrumSteelpan = 'drum-steelpan',
  Drum = 'drum',
  DrumstickBite = 'drumstick-bite',
  Drumstick = 'drumstick',
  DryerHeat = 'dryer-heat',
  Dryer = 'dryer',
  Duck = 'duck',
  Dumbbell = 'dumbbell',
  DumpsterFire = 'dumpster-fire',
  Dumpster = 'dumpster',
  Dungeon = 'dungeon',
  E = 'e',
  EarDeaf = 'ear-deaf',
  EarListen = 'ear-listen',
  EarMuffs = 'ear-muffs',
  Ear = 'ear',
  EarthAfrica = 'earth-africa',
  EarthAmericas = 'earth-americas',
  EarthAsia = 'earth-asia',
  EarthEurope = 'earth-europe',
  EarthOceania = 'earth-oceania',
  Eclipse = 'eclipse',
  EggFried = 'egg-fried',
  Egg = 'egg',
  Eggplant = 'eggplant',
  Eject = 'eject',
  Elephant = 'elephant',
  Elevator = 'elevator',
  EllipsisStrokeVertical = 'ellipsis-stroke-vertical',
  EllipsisStroke = 'ellipsis-stroke',
  EllipsisVertical = 'ellipsis-vertical',
  Ellipsis = 'ellipsis',
  EmptySet = 'empty-set',
  EngineWarning = 'engine-warning',
  Engine = 'engine',
  EnvelopeCircleCheck = 'envelope-circle-check',
  EnvelopeDot = 'envelope-dot',
  EnvelopeOpenDollar = 'envelope-open-dollar',
  EnvelopeOpenText = 'envelope-open-text',
  EnvelopeOpen = 'envelope-open',
  Envelope = 'envelope',
  EnvelopesBulk = 'envelopes-bulk',
  Envelopes = 'envelopes',
  Equals = 'equals',
  Eraser = 'eraser',
  Escalator = 'escalator',
  Ethernet = 'ethernet',
  EuroSign = 'euro-sign',
  Exclamation = 'exclamation',
  ExpandWide = 'expand-wide',
  Expand = 'expand',
  Explosion = 'explosion',
  EyeDropperFull = 'eye-dropper-full',
  EyeDropperHalf = 'eye-dropper-half',
  EyeDropper = 'eye-dropper',
  EyeEvil = 'eye-evil',
  EyeLowVision = 'eye-low-vision',
  EyeSlash = 'eye-slash',
  Eye = 'eye',
  Eyes = 'eyes',
  F = 'f',
  FaceAngryHorns = 'face-angry-horns',
  FaceAngry = 'face-angry',
  FaceAnguished = 'face-anguished',
  FaceAnxiousSweat = 'face-anxious-sweat',
  FaceAstonished = 'face-astonished',
  FaceAwesome = 'face-awesome',
  FaceBeamHandOverMouth = 'face-beam-hand-over-mouth',
  FaceClouds = 'face-clouds',
  FaceConfounded = 'face-confounded',
  FaceConfused = 'face-confused',
  FaceCowboyHat = 'face-cowboy-hat',
  FaceDiagonalMouth = 'face-diagonal-mouth',
  FaceDisappointed = 'face-disappointed',
  FaceDisguise = 'face-disguise',
  FaceDizzy = 'face-dizzy',
  FaceDotted = 'face-dotted',
  FaceDowncastSweat = 'face-downcast-sweat',
  FaceDrooling = 'face-drooling',
  FaceExhaling = 'face-exhaling',
  FaceExplode = 'face-explode',
  FaceExpressionless = 'face-expressionless',
  FaceEyesXmarks = 'face-eyes-xmarks',
  FaceFearful = 'face-fearful',
  FaceFlushed = 'face-flushed',
  FaceFrownOpen = 'face-frown-open',
  FaceFrownSlight = 'face-frown-slight',
  FaceFrown = 'face-frown',
  FaceGlasses = 'face-glasses',
  FaceGrimace = 'face-grimace',
  FaceGrinBeamSweat = 'face-grin-beam-sweat',
  FaceGrinBeam = 'face-grin-beam',
  FaceGrinHearts = 'face-grin-hearts',
  FaceGrinSquintTears = 'face-grin-squint-tears',
  FaceGrinSquint = 'face-grin-squint',
  FaceGrinStars = 'face-grin-stars',
  FaceGrinTears = 'face-grin-tears',
  FaceGrinTongueSquint = 'face-grin-tongue-squint',
  FaceGrinTongueWink = 'face-grin-tongue-wink',
  FaceGrinTongue = 'face-grin-tongue',
  FaceGrinWide = 'face-grin-wide',
  FaceGrinWink = 'face-grin-wink',
  FaceGrin = 'face-grin',
  FaceHandOverMouth = 'face-hand-over-mouth',
  FaceHandPeeking = 'face-hand-peeking',
  FaceHandYawn = 'face-hand-yawn',
  FaceHeadBandage = 'face-head-bandage',
  FaceHoldingBackTears = 'face-holding-back-tears',
  FaceHushed = 'face-hushed',
  FaceIcicles = 'face-icicles',
  FaceKissBeam = 'face-kiss-beam',
  FaceKissClosedEyes = 'face-kiss-closed-eyes',
  FaceKissWinkHeart = 'face-kiss-wink-heart',
  FaceKiss = 'face-kiss',
  FaceLaughBeam = 'face-laugh-beam',
  FaceLaughSquint = 'face-laugh-squint',
  FaceLaughWink = 'face-laugh-wink',
  FaceLaugh = 'face-laugh',
  FaceLying = 'face-lying',
  FaceMask = 'face-mask',
  FaceMehBlank = 'face-meh-blank',
  FaceMeh = 'face-meh',
  FaceMelting = 'face-melting',
  FaceMonocle = 'face-monocle',
  FaceNauseated = 'face-nauseated',
  FaceNoseSteam = 'face-nose-steam',
  FaceParty = 'face-party',
  FacePensive = 'face-pensive',
  FacePersevering = 'face-persevering',
  FacePleading = 'face-pleading',
  FacePouting = 'face-pouting',
  FaceRaisedEyebrow = 'face-raised-eyebrow',
  FaceRelieved = 'face-relieved',
  FaceRollingEyes = 'face-rolling-eyes',
  FaceSadCry = 'face-sad-cry',
  FaceSadSweat = 'face-sad-sweat',
  FaceSadTear = 'face-sad-tear',
  FaceSaluting = 'face-saluting',
  FaceScream = 'face-scream',
  FaceShush = 'face-shush',
  FaceSleeping = 'face-sleeping',
  FaceSleepy = 'face-sleepy',
  FaceSmileBeam = 'face-smile-beam',
  FaceSmileHalo = 'face-smile-halo',
  FaceSmileHearts = 'face-smile-hearts',
  FaceSmileHorns = 'face-smile-horns',
  FaceSmilePlus = 'face-smile-plus',
  FaceSmileRelaxed = 'face-smile-relaxed',
  FaceSmileTear = 'face-smile-tear',
  FaceSmileTongue = 'face-smile-tongue',
  FaceSmileUpsideDown = 'face-smile-upside-down',
  FaceSmileWink = 'face-smile-wink',
  FaceSmile = 'face-smile',
  FaceSmilingHands = 'face-smiling-hands',
  FaceSmirking = 'face-smirking',
  FaceSpiralEyes = 'face-spiral-eyes',
  FaceSunglasses = 'face-sunglasses',
  FaceSurprise = 'face-surprise',
  FaceSwear = 'face-swear',
  FaceThermometer = 'face-thermometer',
  FaceThinking = 'face-thinking',
  FaceTired = 'face-tired',
  FaceTissue = 'face-tissue',
  FaceTongueMoney = 'face-tongue-money',
  FaceTongueSweat = 'face-tongue-sweat',
  FaceUnamused = 'face-unamused',
  FaceViewfinder = 'face-viewfinder',
  FaceVomit = 'face-vomit',
  FaceWeary = 'face-weary',
  FaceWoozy = 'face-woozy',
  FaceWorried = 'face-worried',
  FaceZany = 'face-zany',
  FaceZipper = 'face-zipper',
  Falafel = 'falafel',
  FamilyDress = 'family-dress',
  FamilyPants = 'family-pants',
  Family = 'family',
  FanTable = 'fan-table',
  Fan = 'fan',
  Farm = 'farm',
  FaucetDrip = 'faucet-drip',
  Faucet = 'faucet',
  Fax = 'fax',
  FeatherPointed = 'feather-pointed',
  Feather = 'feather',
  Fence = 'fence',
  FerrisWheel = 'ferris-wheel',
  Ferry = 'ferry',
  FieldHockeyStickBall = 'field-hockey-stick-ball',
  FileArrowDown = 'file-arrow-down',
  FileArrowUp = 'file-arrow-up',
  FileAudio = 'file-audio',
  FileBinary = 'file-binary',
  FileCertificate = 'file-certificate',
  FileChartColumn = 'file-chart-column',
  FileChartPie = 'file-chart-pie',
  FileCheck = 'file-check',
  FileCircleCheck = 'file-circle-check',
  FileCircleExclamation = 'file-circle-exclamation',
  FileCircleInfo = 'file-circle-info',
  FileCircleMinus = 'file-circle-minus',
  FileCirclePlus = 'file-circle-plus',
  FileCircleQuestion = 'file-circle-question',
  FileCircleXmark = 'file-circle-xmark',
  FileCode = 'file-code',
  FileContract = 'file-contract',
  FileCsv = 'file-csv',
  FileDashedLine = 'file-dashed-line',
  FileExcel = 'file-excel',
  FileExclamation = 'file-exclamation',
  FileExport = 'file-export',
  FileHeart = 'file-heart',
  FileImage = 'file-image',
  FileImport = 'file-import',
  FileInvoiceDollar = 'file-invoice-dollar',
  FileInvoice = 'file-invoice',
  FileLines = 'file-lines',
  FileLock = 'file-lock',
  FileMagnifyingGlass = 'file-magnifying-glass',
  FileMedical = 'file-medical',
  FileMinus = 'file-minus',
  FileMusic = 'file-music',
  FilePdf = 'file-pdf',
  FilePen = 'file-pen',
  FilePlusMinus = 'file-plus-minus',
  FilePlus = 'file-plus',
  FilePowerpoint = 'file-powerpoint',
  FilePrescription = 'file-prescription',
  FileShield = 'file-shield',
  FileSignature = 'file-signature',
  FileSlash = 'file-slash',
  FileSpreadsheet = 'file-spreadsheet',
  FileUser = 'file-user',
  FileVideo = 'file-video',
  FileWaveform = 'file-waveform',
  FileWord = 'file-word',
  FileXmark = 'file-xmark',
  FileZipper = 'file-zipper',
  File = 'file',
  FilesMedical = 'files-medical',
  Files = 'files',
  FillDrip = 'fill-drip',
  Fill = 'fill',
  FilmCanister = 'film-canister',
  FilmSimple = 'film-simple',
  FilmSlash = 'film-slash',
  Film = 'film',
  Films = 'films',
  FilterCircleDollar = 'filter-circle-dollar',
  FilterCircleXmark = 'filter-circle-xmark',
  FilterList = 'filter-list',
  FilterSlash = 'filter-slash',
  Filter = 'filter',
  Filters = 'filters',
  Fingerprint = 'fingerprint',
  FireBurner = 'fire-burner',
  FireExtinguisher = 'fire-extinguisher',
  FireFlameCurved = 'fire-flame-curved',
  FireFlameSimple = 'fire-flame-simple',
  FireFlame = 'fire-flame',
  FireHydrant = 'fire-hydrant',
  FireSmoke = 'fire-smoke',
  Fire = 'fire',
  Fireplace = 'fireplace',
  FishBones = 'fish-bones',
  FishCooked = 'fish-cooked',
  FishFins = 'fish-fins',
  Fish = 'fish',
  FishingRod = 'fishing-rod',
  FlagCheckered = 'flag-checkered',
  FlagPennant = 'flag-pennant',
  FlagSwallowtail = 'flag-swallowtail',
  FlagUsa = 'flag-usa',
  Flag = 'flag',
  Flashlight = 'flashlight',
  FlaskRoundPoison = 'flask-round-poison',
  FlaskRoundPotion = 'flask-round-potion',
  FlaskVial = 'flask-vial',
  Flask = 'flask',
  FlatbreadStuffed = 'flatbread-stuffed',
  Flatbread = 'flatbread',
  FloppyDiskCircleArrowRight = 'floppy-disk-circle-arrow-right',
  FloppyDiskCircleXmark = 'floppy-disk-circle-xmark',
  FloppyDiskPen = 'floppy-disk-pen',
  FloppyDisk = 'floppy-disk',
  FloppyDisks = 'floppy-disks',
  FlorinSign = 'florin-sign',
  FlowerDaffodil = 'flower-daffodil',
  FlowerTulip = 'flower-tulip',
  Flower = 'flower',
  Flute = 'flute',
  FluxCapacitor = 'flux-capacitor',
  FlyingDisc = 'flying-disc',
  FolderArrowDown = 'folder-arrow-down',
  FolderArrowUp = 'folder-arrow-up',
  FolderBookmark = 'folder-bookmark',
  FolderClosed = 'folder-closed',
  FolderGear = 'folder-gear',
  FolderGrid = 'folder-grid',
  FolderHeart = 'folder-heart',
  FolderImage = 'folder-image',
  FolderMagnifyingGlass = 'folder-magnifying-glass',
  FolderMedical = 'folder-medical',
  FolderMinus = 'folder-minus',
  FolderMusic = 'folder-music',
  FolderOpen = 'folder-open',
  FolderPlus = 'folder-plus',
  FolderTree = 'folder-tree',
  FolderUser = 'folder-user',
  FolderXmark = 'folder-xmark',
  Folder = 'folder',
  Folders = 'folders',
  FonduePot = 'fondue-pot',
  FontAwesome = 'font-awesome',
  FontCase = 'font-case',
  Font = 'font',
  FootballHelmet = 'football-helmet',
  Football = 'football',
  ForkKnife = 'fork-knife',
  Fork = 'fork',
  Forklift = 'forklift',
  Fort = 'fort',
  ForwardFast = 'forward-fast',
  ForwardStep = 'forward-step',
  Forward = 'forward',
  Frame = 'frame',
  FrancSign = 'franc-sign',
  FrenchFries = 'french-fries',
  Frog = 'frog',
  Function = 'function',
  Futbol = 'futbol',
  G = 'g',
  Galaxy = 'galaxy',
  GalleryThumbnails = 'gallery-thumbnails',
  GameBoardSimple = 'game-board-simple',
  GameBoard = 'game-board',
  GameConsoleHandheldCrank = 'game-console-handheld-crank',
  GameConsoleHandheld = 'game-console-handheld',
  GamepadModern = 'gamepad-modern',
  Gamepad = 'gamepad',
  GarageCar = 'garage-car',
  GarageOpen = 'garage-open',
  Garage = 'garage',
  Garlic = 'garlic',
  GasPumpSlash = 'gas-pump-slash',
  GasPump = 'gas-pump',
  GaugeCircleBolt = 'gauge-circle-bolt',
  GaugeCircleMinus = 'gauge-circle-minus',
  GaugeCirclePlus = 'gauge-circle-plus',
  GaugeHigh = 'gauge-high',
  GaugeLow = 'gauge-low',
  GaugeMax = 'gauge-max',
  GaugeMin = 'gauge-min',
  GaugeSimpleHigh = 'gauge-simple-high',
  GaugeSimpleLow = 'gauge-simple-low',
  GaugeSimpleMax = 'gauge-simple-max',
  GaugeSimpleMin = 'gauge-simple-min',
  GaugeSimple = 'gauge-simple',
  Gauge = 'gauge',
  Gavel = 'gavel',
  Gear = 'gear',
  Gears = 'gears',
  Gem = 'gem',
  Genderless = 'genderless',
  Ghost = 'ghost',
  Gif = 'gif',
  GiftCard = 'gift-card',
  Gift = 'gift',
  Gifts = 'gifts',
  GingerbreadMan = 'gingerbread-man',
  GlassCitrus = 'glass-citrus',
  GlassEmpty = 'glass-empty',
  GlassHalf = 'glass-half',
  GlassWaterDroplet = 'glass-water-droplet',
  GlassWater = 'glass-water',
  Glass = 'glass',
  GlassesRound = 'glasses-round',
  Glasses = 'glasses',
  GlobeSnow = 'globe-snow',
  GlobeStand = 'globe-stand',
  Globe = 'globe',
  GoalNet = 'goal-net',
  GolfBallTee = 'golf-ball-tee',
  GolfClub = 'golf-club',
  GolfFlagHole = 'golf-flag-hole',
  Gopuram = 'gopuram',
  GraduationCap = 'graduation-cap',
  Gramophone = 'gramophone',
  Grapes = 'grapes',
  GrateDroplet = 'grate-droplet',
  Grate = 'grate',
  GreaterThanEqual = 'greater-than-equal',
  GreaterThan = 'greater-than',
  Grid2Plus = 'grid-2-plus',
  Grid2 = 'grid-2',
  Grid4 = 'grid-4',
  Grid5 = 'grid-5',
  GridDividers = 'grid-dividers',
  GridHorizontal = 'grid-horizontal',
  Grid = 'grid',
  GrillFire = 'grill-fire',
  GrillHot = 'grill-hot',
  Grill = 'grill',
  GripDotsVertical = 'grip-dots-vertical',
  GripDots = 'grip-dots',
  GripLinesVertical = 'grip-lines-vertical',
  GripLines = 'grip-lines',
  GripVertical = 'grip-vertical',
  Grip = 'grip',
  GroupArrowsRotate = 'group-arrows-rotate',
  GuaraniSign = 'guarani-sign',
  GuitarElectric = 'guitar-electric',
  Guitar = 'guitar',
  Guitars = 'guitars',
  GunSlash = 'gun-slash',
  GunSquirt = 'gun-squirt',
  Gun = 'gun',
  H = 'h',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  HammerCrash = 'hammer-crash',
  HammerWar = 'hammer-war',
  Hammer = 'hammer',
  Hamsa = 'hamsa',
  HandBackFist = 'hand-back-fist',
  HandBackPointDown = 'hand-back-point-down',
  HandBackPointLeft = 'hand-back-point-left',
  HandBackPointRibbon = 'hand-back-point-ribbon',
  HandBackPointRight = 'hand-back-point-right',
  HandBackPointUp = 'hand-back-point-up',
  HandDots = 'hand-dots',
  HandFingersCrossed = 'hand-fingers-crossed',
  HandFist = 'hand-fist',
  HandHeart = 'hand-heart',
  HandHoldingBox = 'hand-holding-box',
  HandHoldingDollar = 'hand-holding-dollar',
  HandHoldingDroplet = 'hand-holding-droplet',
  HandHoldingHand = 'hand-holding-hand',
  HandHoldingHeart = 'hand-holding-heart',
  HandHoldingMagic = 'hand-holding-magic',
  HandHoldingMedical = 'hand-holding-medical',
  HandHoldingSeedling = 'hand-holding-seedling',
  HandHoldingSkull = 'hand-holding-skull',
  HandHolding = 'hand-holding',
  HandHorns = 'hand-horns',
  HandLizard = 'hand-lizard',
  HandLove = 'hand-love',
  HandMiddleFinger = 'hand-middle-finger',
  HandPeace = 'hand-peace',
  HandPointDown = 'hand-point-down',
  HandPointLeft = 'hand-point-left',
  HandPointRibbon = 'hand-point-ribbon',
  HandPointRight = 'hand-point-right',
  HandPointUp = 'hand-point-up',
  HandPointer = 'hand-pointer',
  HandScissors = 'hand-scissors',
  HandSparkles = 'hand-sparkles',
  HandSpock = 'hand-spock',
  HandWave = 'hand-wave',
  Hand = 'hand',
  Handcuffs = 'handcuffs',
  HandsAslInterpreting = 'hands-asl-interpreting',
  HandsBound = 'hands-bound',
  HandsBubbles = 'hands-bubbles',
  HandsClapping = 'hands-clapping',
  HandsHoldingChild = 'hands-holding-child',
  HandsHoldingCircle = 'hands-holding-circle',
  HandsHoldingDiamond = 'hands-holding-diamond',
  HandsHoldingDollar = 'hands-holding-dollar',
  HandsHoldingHeart = 'hands-holding-heart',
  HandsHolding = 'hands-holding',
  HandsPraying = 'hands-praying',
  Hands = 'hands',
  HandshakeAngle = 'handshake-angle',
  HandshakeSimpleSlash = 'handshake-simple-slash',
  HandshakeSimple = 'handshake-simple',
  HandshakeSlash = 'handshake-slash',
  Handshake = 'handshake',
  Hanukiah = 'hanukiah',
  HardDrive = 'hard-drive',
  HashtagLock = 'hashtag-lock',
  Hashtag = 'hashtag',
  HatChef = 'hat-chef',
  HatCowboySide = 'hat-cowboy-side',
  HatCowboy = 'hat-cowboy',
  HatSanta = 'hat-santa',
  HatWinter = 'hat-winter',
  HatWitch = 'hat-witch',
  HatWizard = 'hat-wizard',
  HeadSideBrain = 'head-side-brain',
  HeadSideCoughSlash = 'head-side-cough-slash',
  HeadSideCough = 'head-side-cough',
  HeadSideGoggles = 'head-side-goggles',
  HeadSideHeadphones = 'head-side-headphones',
  HeadSideHeart = 'head-side-heart',
  HeadSideMask = 'head-side-mask',
  HeadSideMedical = 'head-side-medical',
  HeadSideVirus = 'head-side-virus',
  HeadSide = 'head-side',
  Heading = 'heading',
  HeadphonesSimple = 'headphones-simple',
  Headphones = 'headphones',
  Headset = 'headset',
  HeartCircleBolt = 'heart-circle-bolt',
  HeartCircleCheck = 'heart-circle-check',
  HeartCircleExclamation = 'heart-circle-exclamation',
  HeartCircleMinus = 'heart-circle-minus',
  HeartCirclePlus = 'heart-circle-plus',
  HeartCircleXmark = 'heart-circle-xmark',
  HeartCrack = 'heart-crack',
  HeartHalfStroke = 'heart-half-stroke',
  HeartHalf = 'heart-half',
  HeartPulse = 'heart-pulse',
  Heart = 'heart',
  Heat = 'heat',
  HelicopterSymbol = 'helicopter-symbol',
  Helicopter = 'helicopter',
  HelmetBattle = 'helmet-battle',
  HelmetSafety = 'helmet-safety',
  HelmetUn = 'helmet-un',
  HexagonCheck = 'hexagon-check',
  HexagonDivide = 'hexagon-divide',
  HexagonExclamation = 'hexagon-exclamation',
  HexagonImage = 'hexagon-image',
  HexagonMinus = 'hexagon-minus',
  HexagonPlus = 'hexagon-plus',
  HexagonVerticalNftSlanted = 'hexagon-vertical-nft-slanted',
  HexagonVerticalNft = 'hexagon-vertical-nft',
  HexagonXmark = 'hexagon-xmark',
  Hexagon = 'hexagon',
  HighDefinition = 'high-definition',
  HighlighterLine = 'highlighter-line',
  Highlighter = 'highlighter',
  HillAvalanche = 'hill-avalanche',
  HillRockslide = 'hill-rockslide',
  Hippo = 'hippo',
  HockeyMask = 'hockey-mask',
  HockeyPuck = 'hockey-puck',
  HockeyStickPuck = 'hockey-stick-puck',
  HockeySticks = 'hockey-sticks',
  HollyBerry = 'holly-berry',
  HoneyPot = 'honey-pot',
  HoodCloak = 'hood-cloak',
  HorizontalRule = 'horizontal-rule',
  HorseHead = 'horse-head',
  HorseSaddle = 'horse-saddle',
  Horse = 'horse',
  HoseReel = 'hose-reel',
  Hose = 'hose',
  HospitalUser = 'hospital-user',
  Hospital = 'hospital',
  Hospitals = 'hospitals',
  HotTubPerson = 'hot-tub-person',
  Hotdog = 'hotdog',
  Hotel = 'hotel',
  HourglassClock = 'hourglass-clock',
  HourglassEnd = 'hourglass-end',
  HourglassHalf = 'hourglass-half',
  HourglassStart = 'hourglass-start',
  Hourglass = 'hourglass',
  HouseBlank = 'house-blank',
  HouseBuilding = 'house-building',
  HouseChimneyBlank = 'house-chimney-blank',
  HouseChimneyCrack = 'house-chimney-crack',
  HouseChimneyHeart = 'house-chimney-heart',
  HouseChimneyMedical = 'house-chimney-medical',
  HouseChimneyUser = 'house-chimney-user',
  HouseChimneyWindow = 'house-chimney-window',
  HouseChimney = 'house-chimney',
  HouseCircleCheck = 'house-circle-check',
  HouseCircleExclamation = 'house-circle-exclamation',
  HouseCircleXmark = 'house-circle-xmark',
  HouseCrack = 'house-crack',
  HouseDay = 'house-day',
  HouseFire = 'house-fire',
  HouseFlag = 'house-flag',
  HouseFloodWaterCircleArrowRight = 'house-flood-water-circle-arrow-right',
  HouseFloodWater = 'house-flood-water',
  HouseHeart = 'house-heart',
  HouseLaptop = 'house-laptop',
  HouseLock = 'house-lock',
  HouseMedicalCircleCheck = 'house-medical-circle-check',
  HouseMedicalCircleExclamation = 'house-medical-circle-exclamation',
  HouseMedicalCircleXmark = 'house-medical-circle-xmark',
  HouseMedicalFlag = 'house-medical-flag',
  HouseMedical = 'house-medical',
  HouseNight = 'house-night',
  HousePersonLeave = 'house-person-leave',
  HousePersonReturn = 'house-person-return',
  HouseSignal = 'house-signal',
  HouseTree = 'house-tree',
  HouseTsunami = 'house-tsunami',
  HouseTurret = 'house-turret',
  HouseUser = 'house-user',
  HouseWater = 'house-water',
  HouseWindow = 'house-window',
  House = 'house',
  HryvniaSign = 'hryvnia-sign',
  HundredPoints = 'hundred-points',
  Hurricane = 'hurricane',
  Hyphen = 'hyphen',
  ICursor = 'i-cursor',
  I = 'i',
  IceCream = 'ice-cream',
  IceSkate = 'ice-skate',
  Icicles = 'icicles',
  Icons = 'icons',
  IdBadge = 'id-badge',
  IdCardClip = 'id-card-clip',
  IdCard = 'id-card',
  Igloo = 'igloo',
  ImageLandscape = 'image-landscape',
  ImagePolaroidUser = 'image-polaroid-user',
  ImagePolaroid = 'image-polaroid',
  ImagePortrait = 'image-portrait',
  ImageSlash = 'image-slash',
  ImageUser = 'image-user',
  Image = 'image',
  ImagesUser = 'images-user',
  Images = 'images',
  InboxFull = 'inbox-full',
  InboxIn = 'inbox-in',
  InboxOut = 'inbox-out',
  Inbox = 'inbox',
  Inboxes = 'inboxes',
  Indent = 'indent',
  IndianRupeeSign = 'indian-rupee-sign',
  IndustryWindows = 'industry-windows',
  Industry = 'industry',
  Infinity = 'infinity',
  Info = 'info',
  Inhaler = 'inhaler',
  InputNumeric = 'input-numeric',
  InputPipe = 'input-pipe',
  InputText = 'input-text',
  Integral = 'integral',
  Interrobang = 'interrobang',
  Intersection = 'intersection',
  IslandTropical = 'island-tropical',
  Italic = 'italic',
  J = 'j',
  JackOLantern = 'jack-o-lantern',
  JarWheat = 'jar-wheat',
  Jar = 'jar',
  Jedi = 'jedi',
  JetFighterUp = 'jet-fighter-up',
  JetFighter = 'jet-fighter',
  Joint = 'joint',
  Joystick = 'joystick',
  JugDetergent = 'jug-detergent',
  Jug = 'jug',
  K = 'k',
  Kaaba = 'kaaba',
  Kazoo = 'kazoo',
  Kerning = 'kerning',
  KeySkeletonLeftRight = 'key-skeleton-left-right',
  KeySkeleton = 'key-skeleton',
  Key = 'key',
  KeyboardBrightnessLow = 'keyboard-brightness-low',
  KeyboardBrightness = 'keyboard-brightness',
  KeyboardDown = 'keyboard-down',
  KeyboardLeft = 'keyboard-left',
  Keyboard = 'keyboard',
  Keynote = 'keynote',
  Khanda = 'khanda',
  Kidneys = 'kidneys',
  KipSign = 'kip-sign',
  KitMedical = 'kit-medical',
  KitchenSet = 'kitchen-set',
  Kite = 'kite',
  KiwiBird = 'kiwi-bird',
  KiwiFruit = 'kiwi-fruit',
  KnifeKitchen = 'knife-kitchen',
  Knife = 'knife',
  L = 'l',
  LacrosseStickBall = 'lacrosse-stick-ball',
  LacrosseStick = 'lacrosse-stick',
  Lambda = 'lambda',
  LampDesk = 'lamp-desk',
  LampFloor = 'lamp-floor',
  LampStreet = 'lamp-street',
  Lamp = 'lamp',
  LandMineOn = 'land-mine-on',
  LandmarkDome = 'landmark-dome',
  LandmarkFlag = 'landmark-flag',
  Landmark = 'landmark',
  Language = 'language',
  LaptopArrowDown = 'laptop-arrow-down',
  LaptopCode = 'laptop-code',
  LaptopFile = 'laptop-file',
  LaptopMedical = 'laptop-medical',
  LaptopMobile = 'laptop-mobile',
  LaptopSlash = 'laptop-slash',
  Laptop = 'laptop',
  LariSign = 'lari-sign',
  LassoSparkles = 'lasso-sparkles',
  Lasso = 'lasso',
  LayerGroup = 'layer-group',
  LayerMinus = 'layer-minus',
  LayerPlus = 'layer-plus',
  LeafHeart = 'leaf-heart',
  LeafMaple = 'leaf-maple',
  LeafOak = 'leaf-oak',
  Leaf = 'leaf',
  LeafyGreen = 'leafy-green',
  LeftFromLine = 'left-from-line',
  LeftLongToLine = 'left-long-to-line',
  LeftLong = 'left-long',
  LeftRight = 'left-right',
  LeftToLine = 'left-to-line',
  Left = 'left',
  Lemon = 'lemon',
  LessThanEqual = 'less-than-equal',
  LessThan = 'less-than',
  LifeRing = 'life-ring',
  LightCeiling = 'light-ceiling',
  LightEmergencyOn = 'light-emergency-on',
  LightEmergency = 'light-emergency',
  LightSwitchOff = 'light-switch-off',
  LightSwitchOn = 'light-switch-on',
  LightSwitch = 'light-switch',
  LightbulbCflOn = 'lightbulb-cfl-on',
  LightbulbCfl = 'lightbulb-cfl',
  LightbulbDollar = 'lightbulb-dollar',
  LightbulbExclamationOn = 'lightbulb-exclamation-on',
  LightbulbExclamation = 'lightbulb-exclamation',
  LightbulbOn = 'lightbulb-on',
  LightbulbSlash = 'lightbulb-slash',
  Lightbulb = 'lightbulb',
  LightsHoliday = 'lights-holiday',
  LineColumns = 'line-columns',
  LineHeight = 'line-height',
  LinesLeaning = 'lines-leaning',
  LinkHorizontalSlash = 'link-horizontal-slash',
  LinkHorizontal = 'link-horizontal',
  LinkSimpleSlash = 'link-simple-slash',
  LinkSimple = 'link-simple',
  LinkSlash = 'link-slash',
  Link = 'link',
  Lips = 'lips',
  LiraSign = 'lira-sign',
  ListCheck = 'list-check',
  ListDropdown = 'list-dropdown',
  ListMusic = 'list-music',
  ListOl = 'list-ol',
  ListRadio = 'list-radio',
  ListTimeline = 'list-timeline',
  ListTree = 'list-tree',
  ListUl = 'list-ul',
  List = 'list',
  LitecoinSign = 'litecoin-sign',
  Loader = 'loader',
  Lobster = 'lobster',
  LocationArrow = 'location-arrow',
  LocationCheck = 'location-check',
  LocationCrosshairsSlash = 'location-crosshairs-slash',
  LocationCrosshairs = 'location-crosshairs',
  LocationDotSlash = 'location-dot-slash',
  LocationDot = 'location-dot',
  LocationExclamation = 'location-exclamation',
  LocationMinus = 'location-minus',
  LocationPen = 'location-pen',
  LocationPinLock = 'location-pin-lock',
  LocationPinSlash = 'location-pin-slash',
  LocationPin = 'location-pin',
  LocationPlus = 'location-plus',
  LocationQuestion = 'location-question',
  LocationSmile = 'location-smile',
  LocationXmark = 'location-xmark',
  LockA = 'lock-a',
  LockHashtag = 'lock-hashtag',
  LockKeyholeOpen = 'lock-keyhole-open',
  LockKeyhole = 'lock-keyhole',
  LockOpen = 'lock-open',
  Lock = 'lock',
  Locust = 'locust',
  Lollipop = 'lollipop',
  Loveseat = 'loveseat',
  LuchadorMask = 'luchador-mask',
  LungsVirus = 'lungs-virus',
  Lungs = 'lungs',
  M = 'm',
  Mace = 'mace',
  Magnet = 'magnet',
  MagnifyingGlassArrowRight = 'magnifying-glass-arrow-right',
  MagnifyingGlassChart = 'magnifying-glass-chart',
  MagnifyingGlassDollar = 'magnifying-glass-dollar',
  MagnifyingGlassLocation = 'magnifying-glass-location',
  MagnifyingGlassMinus = 'magnifying-glass-minus',
  MagnifyingGlassPlus = 'magnifying-glass-plus',
  MagnifyingGlass = 'magnifying-glass',
  MailboxFlagUp = 'mailbox-flag-up',
  Mailbox = 'mailbox',
  ManatSign = 'manat-sign',
  Mandolin = 'mandolin',
  Mango = 'mango',
  Manhole = 'manhole',
  MapLocationDot = 'map-location-dot',
  MapLocation = 'map-location',
  MapPin = 'map-pin',
  Map = 'map',
  Marker = 'marker',
  MarsAndVenusBurst = 'mars-and-venus-burst',
  MarsAndVenus = 'mars-and-venus',
  MarsDouble = 'mars-double',
  MarsStrokeRight = 'mars-stroke-right',
  MarsStrokeUp = 'mars-stroke-up',
  MarsStroke = 'mars-stroke',
  Mars = 'mars',
  MartiniGlassCitrus = 'martini-glass-citrus',
  MartiniGlassEmpty = 'martini-glass-empty',
  MartiniGlass = 'martini-glass',
  MaskFace = 'mask-face',
  MaskSnorkel = 'mask-snorkel',
  MaskVentilator = 'mask-ventilator',
  Mask = 'mask',
  MasksTheater = 'masks-theater',
  MattressPillow = 'mattress-pillow',
  Maximize = 'maximize',
  Meat = 'meat',
  Medal = 'medal',
  Megaphone = 'megaphone',
  MelonSlice = 'melon-slice',
  Melon = 'melon',
  MemoCircleCheck = 'memo-circle-check',
  MemoCircleInfo = 'memo-circle-info',
  MemoPad = 'memo-pad',
  Memo = 'memo',
  Memory = 'memory',
  Menorah = 'menorah',
  Mercury = 'mercury',
  Merge = 'merge',
  MessageArrowDown = 'message-arrow-down',
  MessageArrowUpRight = 'message-arrow-up-right',
  MessageArrowUp = 'message-arrow-up',
  MessageBot = 'message-bot',
  MessageCaptions = 'message-captions',
  MessageCheck = 'message-check',
  MessageCode = 'message-code',
  MessageDollar = 'message-dollar',
  MessageDots = 'message-dots',
  MessageExclamation = 'message-exclamation',
  MessageHeart = 'message-heart',
  MessageImage = 'message-image',
  MessageLines = 'message-lines',
  MessageMedical = 'message-medical',
  MessageMiddleTop = 'message-middle-top',
  MessageMiddle = 'message-middle',
  MessageMinus = 'message-minus',
  MessageMusic = 'message-music',
  MessagePen = 'message-pen',
  MessagePlus = 'message-plus',
  MessageQuestion = 'message-question',
  MessageQuote = 'message-quote',
  MessageSlash = 'message-slash',
  MessageSmile = 'message-smile',
  MessageSms = 'message-sms',
  MessageText = 'message-text',
  MessageXmark = 'message-xmark',
  Message = 'message',
  MessagesDollar = 'messages-dollar',
  MessagesQuestion = 'messages-question',
  Messages = 'messages',
  Meteor = 'meteor',
  MeterBolt = 'meter-bolt',
  MeterDroplet = 'meter-droplet',
  MeterFire = 'meter-fire',
  Meter = 'meter',
  MicrochipAi = 'microchip-ai',
  Microchip = 'microchip',
  MicrophoneLinesSlash = 'microphone-lines-slash',
  MicrophoneLines = 'microphone-lines',
  MicrophoneSlash = 'microphone-slash',
  MicrophoneStand = 'microphone-stand',
  Microphone = 'microphone',
  Microscope = 'microscope',
  Microwave = 'microwave',
  MillSign = 'mill-sign',
  Minimize = 'minimize',
  Minus = 'minus',
  Mistletoe = 'mistletoe',
  Mitten = 'mitten',
  MobileButton = 'mobile-button',
  MobileNotch = 'mobile-notch',
  MobileRetro = 'mobile-retro',
  MobileScreenButton = 'mobile-screen-button',
  MobileScreen = 'mobile-screen',
  MobileSignalOut = 'mobile-signal-out',
  MobileSignal = 'mobile-signal',
  Mobile = 'mobile',
  MoneyBill1Wave = 'money-bill-1-wave',
  MoneyBill1 = 'money-bill-1',
  MoneyBillSimpleWave = 'money-bill-simple-wave',
  MoneyBillSimple = 'money-bill-simple',
  MoneyBillTransfer = 'money-bill-transfer',
  MoneyBillTrendUp = 'money-bill-trend-up',
  MoneyBillWave = 'money-bill-wave',
  MoneyBillWheat = 'money-bill-wheat',
  MoneyBill = 'money-bill',
  MoneyBillsSimple = 'money-bills-simple',
  MoneyBills = 'money-bills',
  MoneyCheckDollarPen = 'money-check-dollar-pen',
  MoneyCheckDollar = 'money-check-dollar',
  MoneyCheckPen = 'money-check-pen',
  MoneyCheck = 'money-check',
  MoneyFromBracket = 'money-from-bracket',
  MoneySimpleFromBracket = 'money-simple-from-bracket',
  MonitorWaveform = 'monitor-waveform',
  Monkey = 'monkey',
  Monument = 'monument',
  MoonCloud = 'moon-cloud',
  MoonOverSun = 'moon-over-sun',
  MoonStars = 'moon-stars',
  Moon = 'moon',
  Moped = 'moped',
  MortarPestle = 'mortar-pestle',
  Mosque = 'mosque',
  MosquitoNet = 'mosquito-net',
  Mosquito = 'mosquito',
  Motorcycle = 'motorcycle',
  Mound = 'mound',
  MountainCity = 'mountain-city',
  MountainSun = 'mountain-sun',
  Mountain = 'mountain',
  Mountains = 'mountains',
  MouseField = 'mouse-field',
  Mp3Player = 'mp3-player',
  MugHot = 'mug-hot',
  MugMarshmallows = 'mug-marshmallows',
  MugSaucer = 'mug-saucer',
  MugTeaSaucer = 'mug-tea-saucer',
  MugTea = 'mug-tea',
  Mug = 'mug',
  Mushroom = 'mushroom',
  MusicNoteSlash = 'music-note-slash',
  MusicNote = 'music-note',
  MusicSlash = 'music-slash',
  Music = 'music',
  Mustache = 'mustache',
  N = 'n',
  NairaSign = 'naira-sign',
  Narwhal = 'narwhal',
  NestingDolls = 'nesting-dolls',
  NetworkWired = 'network-wired',
  Neuter = 'neuter',
  Newspaper = 'newspaper',
  NfcLock = 'nfc-lock',
  NfcMagnifyingGlass = 'nfc-magnifying-glass',
  NfcPen = 'nfc-pen',
  NfcSignal = 'nfc-signal',
  NfcSlash = 'nfc-slash',
  NfcSymbol = 'nfc-symbol',
  NfcTrash = 'nfc-trash',
  Nfc = 'nfc',
  Nose = 'nose',
  NotEqual = 'not-equal',
  Notdef = 'notdef',
  NoteMedical = 'note-medical',
  NoteSticky = 'note-sticky',
  Note = 'note',
  Notebook = 'notebook',
  NotesMedical = 'notes-medical',
  Notes = 'notes',
  O = 'o',
  ObjectExclude = 'object-exclude',
  ObjectGroup = 'object-group',
  ObjectIntersect = 'object-intersect',
  ObjectSubtract = 'object-subtract',
  ObjectUngroup = 'object-ungroup',
  ObjectUnion = 'object-union',
  ObjectsAlignBottom = 'objects-align-bottom',
  ObjectsAlignCenterHorizontal = 'objects-align-center-horizontal',
  ObjectsAlignCenterVertical = 'objects-align-center-vertical',
  ObjectsAlignLeft = 'objects-align-left',
  ObjectsAlignRight = 'objects-align-right',
  ObjectsAlignTop = 'objects-align-top',
  ObjectsColumn = 'objects-column',
  OctagonCheck = 'octagon-check',
  OctagonDivide = 'octagon-divide',
  OctagonExclamation = 'octagon-exclamation',
  OctagonMinus = 'octagon-minus',
  OctagonPlus = 'octagon-plus',
  OctagonXmark = 'octagon-xmark',
  Octagon = 'octagon',
  OilCanDrip = 'oil-can-drip',
  OilCan = 'oil-can',
  OilTemperature = 'oil-temperature',
  OilWell = 'oil-well',
  OliveBranch = 'olive-branch',
  Olive = 'olive',
  Om = 'om',
  Omega = 'omega',
  Onion = 'onion',
  Option = 'option',
  Ornament = 'ornament',
  Otter = 'otter',
  Outdent = 'outdent',
  Outlet = 'outlet',
  Oven = 'oven',
  Overline = 'overline',
  P = 'p',
  PageCaretDown = 'page-caret-down',
  PageCaretUp = 'page-caret-up',
  Page = 'page',
  Pager = 'pager',
  PaintRoller = 'paint-roller',
  PaintbrushFine = 'paintbrush-fine',
  PaintbrushPencil = 'paintbrush-pencil',
  Paintbrush = 'paintbrush',
  Palette = 'palette',
  PalletBox = 'pallet-box',
  PalletBoxes = 'pallet-boxes',
  Pallet = 'pallet',
  PanFood = 'pan-food',
  PanFrying = 'pan-frying',
  Pancakes = 'pancakes',
  PanelEws = 'panel-ews',
  PanelFire = 'panel-fire',
  Panorama = 'panorama',
  PaperPlaneTop = 'paper-plane-top',
  PaperPlane = 'paper-plane',
  PaperclipVertical = 'paperclip-vertical',
  Paperclip = 'paperclip',
  ParachuteBox = 'parachute-box',
  ParagraphLeft = 'paragraph-left',
  Paragraph = 'paragraph',
  PartyBell = 'party-bell',
  PartyHorn = 'party-horn',
  Passport = 'passport',
  Paste = 'paste',
  Pause = 'pause',
  PawClaws = 'paw-claws',
  PawSimple = 'paw-simple',
  Paw = 'paw',
  Peace = 'peace',
  Peach = 'peach',
  Peanut = 'peanut',
  Peanuts = 'peanuts',
  Peapod = 'peapod',
  Pear = 'pear',
  Pedestal = 'pedestal',
  Pegasus = 'pegasus',
  PenCircle = 'pen-circle',
  PenClipSlash = 'pen-clip-slash',
  PenClip = 'pen-clip',
  PenFancySlash = 'pen-fancy-slash',
  PenFancy = 'pen-fancy',
  PenField = 'pen-field',
  PenLine = 'pen-line',
  PenNibSlash = 'pen-nib-slash',
  PenNib = 'pen-nib',
  PenPaintbrush = 'pen-paintbrush',
  PenRuler = 'pen-ruler',
  PenSlash = 'pen-slash',
  PenSwirl = 'pen-swirl',
  PenToSquare = 'pen-to-square',
  Pen = 'pen',
  PencilSlash = 'pencil-slash',
  Pencil = 'pencil',
  PeopleArrows = 'people-arrows',
  PeopleCarryBox = 'people-carry-box',
  PeopleDressSimple = 'people-dress-simple',
  PeopleDress = 'people-dress',
  PeopleGroup = 'people-group',
  PeopleLine = 'people-line',
  PeoplePantsSimple = 'people-pants-simple',
  PeoplePants = 'people-pants',
  PeoplePulling = 'people-pulling',
  PeopleRobbery = 'people-robbery',
  PeopleRoof = 'people-roof',
  PeopleSimple = 'people-simple',
  People = 'people',
  PepperHot = 'pepper-hot',
  Pepper = 'pepper',
  Percent = 'percent',
  Period = 'period',
  PersonArrowDownToLine = 'person-arrow-down-to-line',
  PersonArrowUpFromLine = 'person-arrow-up-from-line',
  PersonBikingMountain = 'person-biking-mountain',
  PersonBiking = 'person-biking',
  PersonBooth = 'person-booth',
  PersonBreastfeeding = 'person-breastfeeding',
  PersonBurst = 'person-burst',
  PersonCane = 'person-cane',
  PersonCarryBox = 'person-carry-box',
  PersonChalkboard = 'person-chalkboard',
  PersonCircleCheck = 'person-circle-check',
  PersonCircleExclamation = 'person-circle-exclamation',
  PersonCircleMinus = 'person-circle-minus',
  PersonCirclePlus = 'person-circle-plus',
  PersonCircleQuestion = 'person-circle-question',
  PersonCircleXmark = 'person-circle-xmark',
  PersonDigging = 'person-digging',
  PersonDollyEmpty = 'person-dolly-empty',
  PersonDolly = 'person-dolly',
  PersonDotsFromLine = 'person-dots-from-line',
  PersonDressBurst = 'person-dress-burst',
  PersonDressSimple = 'person-dress-simple',
  PersonDress = 'person-dress',
  PersonDrowning = 'person-drowning',
  PersonFallingBurst = 'person-falling-burst',
  PersonFalling = 'person-falling',
  PersonFromPortal = 'person-from-portal',
  PersonHalfDress = 'person-half-dress',
  PersonHarassing = 'person-harassing',
  PersonHiking = 'person-hiking',
  PersonMilitaryPointing = 'person-military-pointing',
  PersonMilitaryRifle = 'person-military-rifle',
  PersonMilitaryToPerson = 'person-military-to-person',
  PersonPinball = 'person-pinball',
  PersonPraying = 'person-praying',
  PersonPregnant = 'person-pregnant',
  PersonRays = 'person-rays',
  PersonRifle = 'person-rifle',
  PersonRunning = 'person-running',
  PersonSeatReclined = 'person-seat-reclined',
  PersonSeat = 'person-seat',
  PersonShelter = 'person-shelter',
  PersonSign = 'person-sign',
  PersonSimple = 'person-simple',
  PersonSkating = 'person-skating',
  PersonSkiJumping = 'person-ski-jumping',
  PersonSkiLift = 'person-ski-lift',
  PersonSkiingNordic = 'person-skiing-nordic',
  PersonSkiing = 'person-skiing',
  PersonSledding = 'person-sledding',
  PersonSnowboarding = 'person-snowboarding',
  PersonSnowmobiling = 'person-snowmobiling',
  PersonSwimming = 'person-swimming',
  PersonThroughWindow = 'person-through-window',
  PersonToDoor = 'person-to-door',
  PersonToPortal = 'person-to-portal',
  PersonWalkingArrowLoopLeft = 'person-walking-arrow-loop-left',
  PersonWalkingArrowRight = 'person-walking-arrow-right',
  PersonWalkingDashedLineArrowRight = 'person-walking-dashed-line-arrow-right',
  PersonWalkingLuggage = 'person-walking-luggage',
  PersonWalkingWithCane = 'person-walking-with-cane',
  PersonWalking = 'person-walking',
  Person = 'person',
  PesetaSign = 'peseta-sign',
  PesoSign = 'peso-sign',
  PhoneArrowDownLeft = 'phone-arrow-down-left',
  PhoneArrowRight = 'phone-arrow-right',
  PhoneArrowUpRight = 'phone-arrow-up-right',
  PhoneFlip = 'phone-flip',
  PhoneHangup = 'phone-hangup',
  PhoneIntercom = 'phone-intercom',
  PhoneMissed = 'phone-missed',
  PhoneOffice = 'phone-office',
  PhonePlus = 'phone-plus',
  PhoneRotary = 'phone-rotary',
  PhoneSlash = 'phone-slash',
  PhoneVolume = 'phone-volume',
  PhoneXmark = 'phone-xmark',
  Phone = 'phone',
  PhotoFilmMusic = 'photo-film-music',
  PhotoFilm = 'photo-film',
  Pi = 'pi',
  PianoKeyboard = 'piano-keyboard',
  Piano = 'piano',
  Pickaxe = 'pickaxe',
  Pickleball = 'pickleball',
  Pie = 'pie',
  Pig = 'pig',
  PiggyBank = 'piggy-bank',
  Pills = 'pills',
  Pinata = 'pinata',
  Pinball = 'pinball',
  Pineapple = 'pineapple',
  PipeCircleCheck = 'pipe-circle-check',
  PipeCollar = 'pipe-collar',
  PipeSection = 'pipe-section',
  PipeSmoking = 'pipe-smoking',
  PipeValve = 'pipe-valve',
  Pipe = 'pipe',
  PizzaSlice = 'pizza-slice',
  Pizza = 'pizza',
  PlaceOfWorship = 'place-of-worship',
  PlaneArrival = 'plane-arrival',
  PlaneCircleCheck = 'plane-circle-check',
  PlaneCircleExclamation = 'plane-circle-exclamation',
  PlaneCircleXmark = 'plane-circle-xmark',
  PlaneDeparture = 'plane-departure',
  PlaneEngines = 'plane-engines',
  PlaneLock = 'plane-lock',
  PlaneProp = 'plane-prop',
  PlaneSlash = 'plane-slash',
  PlaneTail = 'plane-tail',
  PlaneUpSlash = 'plane-up-slash',
  PlaneUp = 'plane-up',
  Plane = 'plane',
  PlanetMoon = 'planet-moon',
  PlanetRinged = 'planet-ringed',
  PlantWilt = 'plant-wilt',
  PlateUtensils = 'plate-utensils',
  PlateWheat = 'plate-wheat',
  PlayPause = 'play-pause',
  Play = 'play',
  PlugCircleBolt = 'plug-circle-bolt',
  PlugCircleCheck = 'plug-circle-check',
  PlugCircleExclamation = 'plug-circle-exclamation',
  PlugCircleMinus = 'plug-circle-minus',
  PlugCirclePlus = 'plug-circle-plus',
  PlugCircleXmark = 'plug-circle-xmark',
  Plug = 'plug',
  PlusLarge = 'plus-large',
  PlusMinus = 'plus-minus',
  Plus = 'plus',
  Podcast = 'podcast',
  PodiumStar = 'podium-star',
  Podium = 'podium',
  PoliceBox = 'police-box',
  PollPeople = 'poll-people',
  Pompebled = 'pompebled',
  PooStorm = 'poo-storm',
  Poo = 'poo',
  Pool8Ball = 'pool-8-ball',
  Poop = 'poop',
  Popcorn = 'popcorn',
  Popsicle = 'popsicle',
  PotFood = 'pot-food',
  Potato = 'potato',
  PowerOff = 'power-off',
  PrescriptionBottleMedical = 'prescription-bottle-medical',
  PrescriptionBottlePill = 'prescription-bottle-pill',
  PrescriptionBottle = 'prescription-bottle',
  Prescription = 'prescription',
  PresentationScreen = 'presentation-screen',
  Pretzel = 'pretzel',
  PrintMagnifyingGlass = 'print-magnifying-glass',
  PrintSlash = 'print-slash',
  Print = 'print',
  Projector = 'projector',
  PumpMedical = 'pump-medical',
  PumpSoap = 'pump-soap',
  Pump = 'pump',
  Pumpkin = 'pumpkin',
  PuzzlePieceSimple = 'puzzle-piece-simple',
  PuzzlePiece = 'puzzle-piece',
  Puzzle = 'puzzle',
  Q = 'q',
  Qrcode = 'qrcode',
  Question = 'question',
  QuoteLeft = 'quote-left',
  QuoteRight = 'quote-right',
  Quotes = 'quotes',
  R = 'r',
  RabbitRunning = 'rabbit-running',
  Rabbit = 'rabbit',
  Racquet = 'racquet',
  Radar = 'radar',
  Radiation = 'radiation',
  RadioTuner = 'radio-tuner',
  Radio = 'radio',
  Rainbow = 'rainbow',
  Raindrops = 'raindrops',
  Ram = 'ram',
  RampLoading = 'ramp-loading',
  RankingStar = 'ranking-star',
  Raygun = 'raygun',
  Receipt = 'receipt',
  RecordVinyl = 'record-vinyl',
  RectangleAd = 'rectangle-ad',
  RectangleBarcode = 'rectangle-barcode',
  RectangleCode = 'rectangle-code',
  RectangleHistoryCirclePlus = 'rectangle-history-circle-plus',
  RectangleHistoryCircleUser = 'rectangle-history-circle-user',
  RectangleHistory = 'rectangle-history',
  RectangleList = 'rectangle-list',
  RectanglePro = 'rectangle-pro',
  RectangleTerminal = 'rectangle-terminal',
  RectangleVerticalHistory = 'rectangle-vertical-history',
  RectangleVertical = 'rectangle-vertical',
  RectangleWide = 'rectangle-wide',
  RectangleXmark = 'rectangle-xmark',
  Rectangle = 'rectangle',
  RectanglesMixed = 'rectangles-mixed',
  Recycle = 'recycle',
  Reel = 'reel',
  Refrigerator = 'refrigerator',
  Registered = 'registered',
  Repeat1 = 'repeat-1',
  Repeat = 'repeat',
  ReplyAll = 'reply-all',
  ReplyClock = 'reply-clock',
  Reply = 'reply',
  Republican = 'republican',
  RestroomSimple = 'restroom-simple',
  Restroom = 'restroom',
  Retweet = 'retweet',
  Rhombus = 'rhombus',
  Ribbon = 'ribbon',
  RightFromBracket = 'right-from-bracket',
  RightFromLine = 'right-from-line',
  RightLeft = 'right-left',
  RightLongToLine = 'right-long-to-line',
  RightLong = 'right-long',
  RightToBracket = 'right-to-bracket',
  RightToLine = 'right-to-line',
  Right = 'right',
  RingDiamond = 'ring-diamond',
  Ring = 'ring',
  RingsWedding = 'rings-wedding',
  RoadBarrier = 'road-barrier',
  RoadBridge = 'road-bridge',
  RoadCircleCheck = 'road-circle-check',
  RoadCircleExclamation = 'road-circle-exclamation',
  RoadCircleXmark = 'road-circle-xmark',
  RoadLock = 'road-lock',
  RoadSpikes = 'road-spikes',
  Road = 'road',
  RobotAstromech = 'robot-astromech',
  Robot = 'robot',
  RocketLaunch = 'rocket-launch',
  Rocket = 'rocket',
  RollerCoaster = 'roller-coaster',
  RotateExclamation = 'rotate-exclamation',
  RotateLeft = 'rotate-left',
  RotateRight = 'rotate-right',
  Rotate = 'rotate',
  RouteHighway = 'route-highway',
  RouteInterstate = 'route-interstate',
  Route = 'route',
  Router = 'router',
  Rss = 'rss',
  RubleSign = 'ruble-sign',
  Rug = 'rug',
  RugbyBall = 'rugby-ball',
  RulerCombined = 'ruler-combined',
  RulerHorizontal = 'ruler-horizontal',
  RulerTriangle = 'ruler-triangle',
  RulerVertical = 'ruler-vertical',
  Ruler = 'ruler',
  RupeeSign = 'rupee-sign',
  RupiahSign = 'rupiah-sign',
  Rv = 'rv',
  S = 's',
  SackDollar = 'sack-dollar',
  SackXmark = 'sack-xmark',
  Sack = 'sack',
  Sailboat = 'sailboat',
  Salad = 'salad',
  SaltShaker = 'salt-shaker',
  Sandwich = 'sandwich',
  SatelliteDish = 'satellite-dish',
  Satellite = 'satellite',
  Sausage = 'sausage',
  SaxophoneFire = 'saxophone-fire',
  Saxophone = 'saxophone',
  ScaleBalanced = 'scale-balanced',
  ScaleUnbalancedFlip = 'scale-unbalanced-flip',
  ScaleUnbalanced = 'scale-unbalanced',
  ScalpelLineDashed = 'scalpel-line-dashed',
  Scalpel = 'scalpel',
  ScannerGun = 'scanner-gun',
  ScannerImage = 'scanner-image',
  ScannerKeyboard = 'scanner-keyboard',
  ScannerTouchscreen = 'scanner-touchscreen',
  Scarecrow = 'scarecrow',
  Scarf = 'scarf',
  SchoolCircleCheck = 'school-circle-check',
  SchoolCircleExclamation = 'school-circle-exclamation',
  SchoolCircleXmark = 'school-circle-xmark',
  SchoolFlag = 'school-flag',
  SchoolLock = 'school-lock',
  School = 'school',
  Scissors = 'scissors',
  ScreenUsers = 'screen-users',
  Screencast = 'screencast',
  ScrewdriverWrench = 'screwdriver-wrench',
  Screwdriver = 'screwdriver',
  Scribble = 'scribble',
  ScrollOld = 'scroll-old',
  ScrollTorah = 'scroll-torah',
  Scroll = 'scroll',
  Scrubber = 'scrubber',
  Scythe = 'scythe',
  SdCard = 'sd-card',
  SdCards = 'sd-cards',
  SealExclamation = 'seal-exclamation',
  SealQuestion = 'seal-question',
  Seal = 'seal',
  SeatAirline = 'seat-airline',
  Section = 'section',
  Seedling = 'seedling',
  Semicolon = 'semicolon',
  SendBack = 'send-back',
  SendBackward = 'send-backward',
  SensorCloud = 'sensor-cloud',
  SensorFire = 'sensor-fire',
  SensorOn = 'sensor-on',
  SensorTriangleExclamation = 'sensor-triangle-exclamation',
  Sensor = 'sensor',
  Server = 'server',
  Shapes = 'shapes',
  ShareAll = 'share-all',
  ShareFromSquare = 'share-from-square',
  ShareNodes = 'share-nodes',
  Share = 'share',
  Sheep = 'sheep',
  SheetPlastic = 'sheet-plastic',
  ShekelSign = 'shekel-sign',
  ShelvesEmpty = 'shelves-empty',
  Shelves = 'shelves',
  ShieldCat = 'shield-cat',
  ShieldCheck = 'shield-check',
  ShieldCross = 'shield-cross',
  ShieldDog = 'shield-dog',
  ShieldExclamation = 'shield-exclamation',
  ShieldHalved = 'shield-halved',
  ShieldHeart = 'shield-heart',
  ShieldKeyhole = 'shield-keyhole',
  ShieldMinus = 'shield-minus',
  ShieldPlus = 'shield-plus',
  ShieldQuartered = 'shield-quartered',
  ShieldSlash = 'shield-slash',
  ShieldVirus = 'shield-virus',
  ShieldXmark = 'shield-xmark',
  Shield = 'shield',
  Ship = 'ship',
  ShirtLongSleeve = 'shirt-long-sleeve',
  ShirtRunning = 'shirt-running',
  ShirtTankTop = 'shirt-tank-top',
  Shirt = 'shirt',
  ShishKebab = 'shish-kebab',
  ShoePrints = 'shoe-prints',
  ShopLock = 'shop-lock',
  ShopSlash = 'shop-slash',
  Shop = 'shop',
  ShovelSnow = 'shovel-snow',
  Shovel = 'shovel',
  ShowerDown = 'shower-down',
  Shower = 'shower',
  Shredder = 'shredder',
  Shrimp = 'shrimp',
  Shuffle = 'shuffle',
  Shutters = 'shutters',
  ShuttleSpace = 'shuttle-space',
  Shuttlecock = 'shuttlecock',
  Sickle = 'sickle',
  SidebarFlip = 'sidebar-flip',
  Sidebar = 'sidebar',
  Sigma = 'sigma',
  SignHanging = 'sign-hanging',
  SignalBarsFair = 'signal-bars-fair',
  SignalBarsGood = 'signal-bars-good',
  SignalBarsSlash = 'signal-bars-slash',
  SignalBarsWeak = 'signal-bars-weak',
  SignalBars = 'signal-bars',
  SignalFair = 'signal-fair',
  SignalGood = 'signal-good',
  SignalSlash = 'signal-slash',
  SignalStreamSlash = 'signal-stream-slash',
  SignalStream = 'signal-stream',
  SignalStrong = 'signal-strong',
  SignalWeak = 'signal-weak',
  Signal = 'signal',
  SignatureLock = 'signature-lock',
  SignatureSlash = 'signature-slash',
  Signature = 'signature',
  SignsPost = 'signs-post',
  SimCard = 'sim-card',
  SimCards = 'sim-cards',
  Sink = 'sink',
  SirenOn = 'siren-on',
  Siren = 'siren',
  Sitemap = 'sitemap',
  Skeleton = 'skeleton',
  SkiBootSki = 'ski-boot-ski',
  SkiBoot = 'ski-boot',
  SkullCow = 'skull-cow',
  SkullCrossbones = 'skull-crossbones',
  Skull = 'skull',
  SlashBack = 'slash-back',
  SlashForward = 'slash-forward',
  Slash = 'slash',
  Sleigh = 'sleigh',
  Slider = 'slider',
  SlidersSimple = 'sliders-simple',
  SlidersUp = 'sliders-up',
  Sliders = 'sliders',
  SlotMachine = 'slot-machine',
  Smog = 'smog',
  Smoke = 'smoke',
  Smoking = 'smoking',
  Snake = 'snake',
  Snooze = 'snooze',
  SnowBlowing = 'snow-blowing',
  SnowflakeDroplets = 'snowflake-droplets',
  Snowflake = 'snowflake',
  Snowflakes = 'snowflakes',
  SnowmanHead = 'snowman-head',
  Snowman = 'snowman',
  Snowplow = 'snowplow',
  Soap = 'soap',
  Socks = 'socks',
  SoftServe = 'soft-serve',
  SolarPanel = 'solar-panel',
  SolarSystem = 'solar-system',
  SortDown = 'sort-down',
  SortUp = 'sort-up',
  Sort = 'sort',
  Spa = 'spa',
  SpaceStationMoonConstruction = 'space-station-moon-construction',
  SpaceStationMoon = 'space-station-moon',
  Spade = 'spade',
  SpaghettiMonsterFlying = 'spaghetti-monster-flying',
  Sparkles = 'sparkles',
  Speaker = 'speaker',
  Speakers = 'speakers',
  SpellCheck = 'spell-check',
  SpiderBlackWidow = 'spider-black-widow',
  SpiderWeb = 'spider-web',
  Spider = 'spider',
  SpinnerThird = 'spinner-third',
  Spinner = 'spinner',
  Split = 'split',
  Splotch = 'splotch',
  Spoon = 'spoon',
  Sportsball = 'sportsball',
  SprayCanSparkles = 'spray-can-sparkles',
  SprayCan = 'spray-can',
  SprinklerCeiling = 'sprinkler-ceiling',
  Sprinkler = 'sprinkler',
  Square0 = 'square-0',
  Square1 = 'square-1',
  Square2 = 'square-2',
  Square3 = 'square-3',
  Square4 = 'square-4',
  Square5 = 'square-5',
  Square6 = 'square-6',
  Square7 = 'square-7',
  Square8 = 'square-8',
  Square9 = 'square-9',
  SquareALock = 'square-a-lock',
  SquareA = 'square-a',
  SquareAmpersand = 'square-ampersand',
  SquareArrowDownLeft = 'square-arrow-down-left',
  SquareArrowDownRight = 'square-arrow-down-right',
  SquareArrowDown = 'square-arrow-down',
  SquareArrowLeft = 'square-arrow-left',
  SquareArrowRight = 'square-arrow-right',
  SquareArrowUpLeft = 'square-arrow-up-left',
  SquareArrowUpRight = 'square-arrow-up-right',
  SquareArrowUp = 'square-arrow-up',
  SquareB = 'square-b',
  SquareBolt = 'square-bolt',
  SquareC = 'square-c',
  SquareCaretDown = 'square-caret-down',
  SquareCaretLeft = 'square-caret-left',
  SquareCaretRight = 'square-caret-right',
  SquareCaretUp = 'square-caret-up',
  SquareCheck = 'square-check',
  SquareChevronDown = 'square-chevron-down',
  SquareChevronLeft = 'square-chevron-left',
  SquareChevronRight = 'square-chevron-right',
  SquareChevronUp = 'square-chevron-up',
  SquareCode = 'square-code',
  SquareD = 'square-d',
  SquareDashedCirclePlus = 'square-dashed-circle-plus',
  SquareDashed = 'square-dashed',
  SquareDivide = 'square-divide',
  SquareDollar = 'square-dollar',
  SquareDownLeft = 'square-down-left',
  SquareDownRight = 'square-down-right',
  SquareDown = 'square-down',
  SquareE = 'square-e',
  SquareEllipsisVertical = 'square-ellipsis-vertical',
  SquareEllipsis = 'square-ellipsis',
  SquareEnvelope = 'square-envelope',
  SquareExclamation = 'square-exclamation',
  SquareF = 'square-f',
  SquareFragile = 'square-fragile',
  SquareFull = 'square-full',
  SquareG = 'square-g',
  SquareH = 'square-h',
  SquareHeart = 'square-heart',
  SquareI = 'square-i',
  SquareInfo = 'square-info',
  SquareJ = 'square-j',
  SquareK = 'square-k',
  SquareKanban = 'square-kanban',
  SquareL = 'square-l',
  SquareLeft = 'square-left',
  SquareList = 'square-list',
  SquareM = 'square-m',
  SquareMinus = 'square-minus',
  SquareN = 'square-n',
  SquareNfi = 'square-nfi',
  SquareO = 'square-o',
  SquareP = 'square-p',
  SquareParkingSlash = 'square-parking-slash',
  SquareParking = 'square-parking',
  SquarePen = 'square-pen',
  SquarePersonConfined = 'square-person-confined',
  SquarePhoneFlip = 'square-phone-flip',
  SquarePhoneHangup = 'square-phone-hangup',
  SquarePhone = 'square-phone',
  SquarePlus = 'square-plus',
  SquarePollHorizontal = 'square-poll-horizontal',
  SquarePollVertical = 'square-poll-vertical',
  SquareQ = 'square-q',
  SquareQuarters = 'square-quarters',
  SquareQuestion = 'square-question',
  SquareQuote = 'square-quote',
  SquareR = 'square-r',
  SquareRight = 'square-right',
  SquareRing = 'square-ring',
  SquareRootVariable = 'square-root-variable',
  SquareRoot = 'square-root',
  SquareRss = 'square-rss',
  SquareS = 'square-s',
  SquareShareNodes = 'square-share-nodes',
  SquareSlidersVertical = 'square-sliders-vertical',
  SquareSliders = 'square-sliders',
  SquareSmall = 'square-small',
  SquareStar = 'square-star',
  SquareT = 'square-t',
  SquareTerminal = 'square-terminal',
  SquareThisWayUp = 'square-this-way-up',
  SquareU = 'square-u',
  SquareUpLeft = 'square-up-left',
  SquareUpRight = 'square-up-right',
  SquareUp = 'square-up',
  SquareUser = 'square-user',
  SquareV = 'square-v',
  SquareVirus = 'square-virus',
  SquareW = 'square-w',
  SquareX = 'square-x',
  SquareXmark = 'square-xmark',
  SquareY = 'square-y',
  SquareZ = 'square-z',
  Square = 'square',
  Squid = 'squid',
  Squirrel = 'squirrel',
  StaffSnake = 'staff-snake',
  Staff = 'staff',
  Stairs = 'stairs',
  Stamp = 'stamp',
  StandardDefinition = 'standard-definition',
  Stapler = 'stapler',
  StarAndCrescent = 'star-and-crescent',
  StarChristmas = 'star-christmas',
  StarExclamation = 'star-exclamation',
  StarHalfStroke = 'star-half-stroke',
  StarHalf = 'star-half',
  StarOfDavid = 'star-of-david',
  StarOfLife = 'star-of-life',
  StarSharpHalfStroke = 'star-sharp-half-stroke',
  StarSharpHalf = 'star-sharp-half',
  StarSharp = 'star-sharp',
  StarShooting = 'star-shooting',
  Star = 'star',
  StarfighterTwinIonEngineAdvanced = 'starfighter-twin-ion-engine-advanced',
  StarfighterTwinIonEngine = 'starfighter-twin-ion-engine',
  Starfighter = 'starfighter',
  Stars = 'stars',
  StarshipFreighter = 'starship-freighter',
  Starship = 'starship',
  Steak = 'steak',
  SteeringWheel = 'steering-wheel',
  SterlingSign = 'sterling-sign',
  Stethoscope = 'stethoscope',
  Stocking = 'stocking',
  Stomach = 'stomach',
  Stop = 'stop',
  Stopwatch20 = 'stopwatch-20',
  Stopwatch = 'stopwatch',
  StoreLock = 'store-lock',
  StoreSlash = 'store-slash',
  Store = 'store',
  Strawberry = 'strawberry',
  StreetView = 'street-view',
  Stretcher = 'stretcher',
  Strikethrough = 'strikethrough',
  Stroopwafel = 'stroopwafel',
  Subscript = 'subscript',
  SuitcaseMedical = 'suitcase-medical',
  SuitcaseRolling = 'suitcase-rolling',
  Suitcase = 'suitcase',
  SunBright = 'sun-bright',
  SunCloud = 'sun-cloud',
  SunDust = 'sun-dust',
  SunHaze = 'sun-haze',
  SunPlantWilt = 'sun-plant-wilt',
  Sun = 'sun',
  Sunglasses = 'sunglasses',
  Sunrise = 'sunrise',
  Sunset = 'sunset',
  Superscript = 'superscript',
  SushiRoll = 'sushi-roll',
  Sushi = 'sushi',
  Swatchbook = 'swatchbook',
  SwordLaserAlt = 'sword-laser-alt',
  SwordLaser = 'sword-laser',
  Sword = 'sword',
  SwordsLaser = 'swords-laser',
  Swords = 'swords',
  Symbols = 'symbols',
  Synagogue = 'synagogue',
  Syringe = 'syringe',
  T = 't',
  TableCellsLarge = 'table-cells-large',
  TableCells = 'table-cells',
  TableColumns = 'table-columns',
  TableLayout = 'table-layout',
  TableList = 'table-list',
  TablePicnic = 'table-picnic',
  TablePivot = 'table-pivot',
  TableRows = 'table-rows',
  TableTennisPaddleBall = 'table-tennis-paddle-ball',
  TableTree = 'table-tree',
  Table = 'table',
  TabletButton = 'tablet-button',
  TabletRugged = 'tablet-rugged',
  TabletScreenButton = 'tablet-screen-button',
  TabletScreen = 'tablet-screen',
  Tablet = 'tablet',
  Tablets = 'tablets',
  TachographDigital = 'tachograph-digital',
  Taco = 'taco',
  Tag = 'tag',
  Tags = 'tags',
  Tally1 = 'tally-1',
  Tally2 = 'tally-2',
  Tally3 = 'tally-3',
  Tally4 = 'tally-4',
  Tally = 'tally',
  Tamale = 'tamale',
  TankWater = 'tank-water',
  Tape = 'tape',
  TarpDroplet = 'tarp-droplet',
  Tarp = 'tarp',
  TaxiBus = 'taxi-bus',
  Taxi = 'taxi',
  TeddyBear = 'teddy-bear',
  TeethOpen = 'teeth-open',
  Teeth = 'teeth',
  Telescope = 'telescope',
  TemperatureArrowDown = 'temperature-arrow-down',
  TemperatureArrowUp = 'temperature-arrow-up',
  TemperatureEmpty = 'temperature-empty',
  TemperatureFull = 'temperature-full',
  TemperatureHalf = 'temperature-half',
  TemperatureHigh = 'temperature-high',
  TemperatureList = 'temperature-list',
  TemperatureLow = 'temperature-low',
  TemperatureQuarter = 'temperature-quarter',
  TemperatureSnow = 'temperature-snow',
  TemperatureSun = 'temperature-sun',
  TemperatureThreeQuarters = 'temperature-three-quarters',
  TengeSign = 'tenge-sign',
  TennisBall = 'tennis-ball',
  TentArrowDownToLine = 'tent-arrow-down-to-line',
  TentArrowLeftRight = 'tent-arrow-left-right',
  TentArrowTurnLeft = 'tent-arrow-turn-left',
  TentArrowsDown = 'tent-arrows-down',
  Tent = 'tent',
  Tents = 'tents',
  Terminal = 'terminal',
  TextHeight = 'text-height',
  TextSize = 'text-size',
  TextSlash = 'text-slash',
  TextWidth = 'text-width',
  Text = 'text',
  Thermometer = 'thermometer',
  Theta = 'theta',
  ThoughtBubble = 'thought-bubble',
  ThumbsDown = 'thumbs-down',
  ThumbsUp = 'thumbs-up',
  Thumbtack = 'thumbtack',
  Tick = 'tick',
  TicketAirline = 'ticket-airline',
  TicketSimple = 'ticket-simple',
  Ticket = 'ticket',
  TicketsAirline = 'tickets-airline',
  Tilde = 'tilde',
  TimelineArrow = 'timeline-arrow',
  Timeline = 'timeline',
  Timer = 'timer',
  TireFlat = 'tire-flat',
  TirePressureWarning = 'tire-pressure-warning',
  TireRugged = 'tire-rugged',
  Tire = 'tire',
  ToggleLargeOff = 'toggle-large-off',
  ToggleLargeOn = 'toggle-large-on',
  ToggleOff = 'toggle-off',
  ToggleOn = 'toggle-on',
  ToiletPaperBlankUnder = 'toilet-paper-blank-under',
  ToiletPaperBlank = 'toilet-paper-blank',
  ToiletPaperCheck = 'toilet-paper-check',
  ToiletPaperSlash = 'toilet-paper-slash',
  ToiletPaperUnderSlash = 'toilet-paper-under-slash',
  ToiletPaperUnder = 'toilet-paper-under',
  ToiletPaperXmark = 'toilet-paper-xmark',
  ToiletPaper = 'toilet-paper',
  ToiletPortable = 'toilet-portable',
  Toilet = 'toilet',
  ToiletsPortable = 'toilets-portable',
  Tomato = 'tomato',
  TombstoneBlank = 'tombstone-blank',
  Tombstone = 'tombstone',
  Toolbox = 'toolbox',
  Tooth = 'tooth',
  Toothbrush = 'toothbrush',
  ToriiGate = 'torii-gate',
  Tornado = 'tornado',
  TowerBroadcast = 'tower-broadcast',
  TowerCell = 'tower-cell',
  TowerControl = 'tower-control',
  TowerObservation = 'tower-observation',
  Tractor = 'tractor',
  Trademark = 'trademark',
  TrafficCone = 'traffic-cone',
  TrafficLightGo = 'traffic-light-go',
  TrafficLightSlow = 'traffic-light-slow',
  TrafficLightStop = 'traffic-light-stop',
  TrafficLight = 'traffic-light',
  Trailer = 'trailer',
  TrainSubwayTunnel = 'train-subway-tunnel',
  TrainSubway = 'train-subway',
  TrainTrack = 'train-track',
  TrainTram = 'train-tram',
  TrainTunnel = 'train-tunnel',
  Train = 'train',
  TransformerBolt = 'transformer-bolt',
  Transgender = 'transgender',
  Transporter1 = 'transporter-1',
  Transporter2 = 'transporter-2',
  Transporter3 = 'transporter-3',
  Transporter4 = 'transporter-4',
  Transporter5 = 'transporter-5',
  Transporter6 = 'transporter-6',
  Transporter7 = 'transporter-7',
  TransporterEmpty = 'transporter-empty',
  Transporter = 'transporter',
  TrashArrowUp = 'trash-arrow-up',
  TrashCanArrowUp = 'trash-can-arrow-up',
  TrashCanCheck = 'trash-can-check',
  TrashCanClock = 'trash-can-clock',
  TrashCanList = 'trash-can-list',
  TrashCanPlus = 'trash-can-plus',
  TrashCanSlash = 'trash-can-slash',
  TrashCanUndo = 'trash-can-undo',
  TrashCanXmark = 'trash-can-xmark',
  TrashCan = 'trash-can',
  TrashCheck = 'trash-check',
  TrashClock = 'trash-clock',
  TrashList = 'trash-list',
  TrashPlus = 'trash-plus',
  TrashSlash = 'trash-slash',
  TrashUndo = 'trash-undo',
  TrashXmark = 'trash-xmark',
  Trash = 'trash',
  TreasureChest = 'treasure-chest',
  TreeChristmas = 'tree-christmas',
  TreeCity = 'tree-city',
  TreeDeciduous = 'tree-deciduous',
  TreeDecorated = 'tree-decorated',
  TreeLarge = 'tree-large',
  TreePalm = 'tree-palm',
  Tree = 'tree',
  Trees = 'trees',
  TriangleExclamation = 'triangle-exclamation',
  TriangleInstrument = 'triangle-instrument',
  TrianglePersonDigging = 'triangle-person-digging',
  Triangle = 'triangle',
  TricycleAdult = 'tricycle-adult',
  Tricycle = 'tricycle',
  Trillium = 'trillium',
  TrophyStar = 'trophy-star',
  Trophy = 'trophy',
  TrowelBricks = 'trowel-bricks',
  Trowel = 'trowel',
  TruckArrowRight = 'truck-arrow-right',
  TruckBolt = 'truck-bolt',
  TruckClock = 'truck-clock',
  TruckContainerEmpty = 'truck-container-empty',
  TruckContainer = 'truck-container',
  TruckDroplet = 'truck-droplet',
  TruckFast = 'truck-fast',
  TruckFieldUn = 'truck-field-un',
  TruckField = 'truck-field',
  TruckFlatbed = 'truck-flatbed',
  TruckFront = 'truck-front',
  TruckMedical = 'truck-medical',
  TruckMonster = 'truck-monster',
  TruckMoving = 'truck-moving',
  TruckPickup = 'truck-pickup',
  TruckPlane = 'truck-plane',
  TruckPlow = 'truck-plow',
  TruckRampBox = 'truck-ramp-box',
  TruckRampCouch = 'truck-ramp-couch',
  TruckRamp = 'truck-ramp',
  TruckTow = 'truck-tow',
  Truck = 'truck',
  Trumpet = 'trumpet',
  TtyAnswer = 'tty-answer',
  Tty = 'tty',
  TugrikSign = 'tugrik-sign',
  Turkey = 'turkey',
  TurkishLiraSign = 'turkish-lira-sign',
  TurnDownLeft = 'turn-down-left',
  TurnDownRight = 'turn-down-right',
  TurnDown = 'turn-down',
  TurnUp = 'turn-up',
  Turntable = 'turntable',
  Turtle = 'turtle',
  TvMusic = 'tv-music',
  TvRetro = 'tv-retro',
  Tv = 'tv',
  Typewriter = 'typewriter',
  U = 'u',
  UfoBeam = 'ufo-beam',
  Ufo = 'ufo',
  UmbrellaBeach = 'umbrella-beach',
  UmbrellaSimple = 'umbrella-simple',
  Umbrella = 'umbrella',
  Underline = 'underline',
  Unicorn = 'unicorn',
  UniformMartialArts = 'uniform-martial-arts',
  Union = 'union',
  UniversalAccess = 'universal-access',
  UnlockKeyhole = 'unlock-keyhole',
  Unlock = 'unlock',
  UpDownLeftRight = 'up-down-left-right',
  UpDown = 'up-down',
  UpFromBracket = 'up-from-bracket',
  UpFromDottedLine = 'up-from-dotted-line',
  UpFromLine = 'up-from-line',
  UpLeft = 'up-left',
  UpLong = 'up-long',
  UpRightAndDownLeftFromCenter = 'up-right-and-down-left-from-center',
  UpRightFromSquare = 'up-right-from-square',
  UpRight = 'up-right',
  UpToDottedLine = 'up-to-dotted-line',
  UpToLine = 'up-to-line',
  Up = 'up',
  Upload = 'upload',
  UsbDrive = 'usb-drive',
  UserAlien = 'user-alien',
  UserAstronaut = 'user-astronaut',
  UserBountyHunter = 'user-bounty-hunter',
  UserCheck = 'user-check',
  UserChef = 'user-chef',
  UserClock = 'user-clock',
  UserCowboy = 'user-cowboy',
  UserCrown = 'user-crown',
  UserDoctorHairLong = 'user-doctor-hair-long',
  UserDoctorHair = 'user-doctor-hair',
  UserDoctorMessage = 'user-doctor-message',
  UserDoctor = 'user-doctor',
  UserGear = 'user-gear',
  UserGraduate = 'user-graduate',
  UserGroupCrown = 'user-group-crown',
  UserGroup = 'user-group',
  UserHairBuns = 'user-hair-buns',
  UserHairLong = 'user-hair-long',
  UserHairMullet = 'user-hair-mullet',
  UserHair = 'user-hair',
  UserHeadset = 'user-headset',
  UserHelmetSafety = 'user-helmet-safety',
  UserInjured = 'user-injured',
  UserLargeSlash = 'user-large-slash',
  UserLarge = 'user-large',
  UserLock = 'user-lock',
  UserMagnifyingGlass = 'user-magnifying-glass',
  UserMinus = 'user-minus',
  UserMusic = 'user-music',
  UserNinja = 'user-ninja',
  UserNurseHairLong = 'user-nurse-hair-long',
  UserNurseHair = 'user-nurse-hair',
  UserNurse = 'user-nurse',
  UserPen = 'user-pen',
  UserPilotTie = 'user-pilot-tie',
  UserPilot = 'user-pilot',
  UserPlus = 'user-plus',
  UserPoliceTie = 'user-police-tie',
  UserPolice = 'user-police',
  UserRobotXmarks = 'user-robot-xmarks',
  UserRobot = 'user-robot',
  UserSecret = 'user-secret',
  UserShakespeare = 'user-shakespeare',
  UserShield = 'user-shield',
  UserSlash = 'user-slash',
  UserTag = 'user-tag',
  UserTieHairLong = 'user-tie-hair-long',
  UserTieHair = 'user-tie-hair',
  UserTie = 'user-tie',
  UserUnlock = 'user-unlock',
  UserVisor = 'user-visor',
  UserVneckHairLong = 'user-vneck-hair-long',
  UserVneckHair = 'user-vneck-hair',
  UserVneck = 'user-vneck',
  UserXmark = 'user-xmark',
  User = 'user',
  UsersBetweenLines = 'users-between-lines',
  UsersGear = 'users-gear',
  UsersLine = 'users-line',
  UsersMedical = 'users-medical',
  UsersRays = 'users-rays',
  UsersRectangle = 'users-rectangle',
  UsersSlash = 'users-slash',
  UsersViewfinder = 'users-viewfinder',
  Users = 'users',
  UtensilsSlash = 'utensils-slash',
  Utensils = 'utensils',
  UtilityPoleDouble = 'utility-pole-double',
  UtilityPole = 'utility-pole',
  V = 'v',
  VacuumRobot = 'vacuum-robot',
  Vacuum = 'vacuum',
  ValueAbsolute = 'value-absolute',
  VanShuttle = 'van-shuttle',
  Vault = 'vault',
  VectorCircle = 'vector-circle',
  VectorPolygon = 'vector-polygon',
  VectorSquare = 'vector-square',
  VentDamper = 'vent-damper',
  VenusDouble = 'venus-double',
  VenusMars = 'venus-mars',
  Venus = 'venus',
  VestPatches = 'vest-patches',
  Vest = 'vest',
  VialCircleCheck = 'vial-circle-check',
  VialVirus = 'vial-virus',
  Vial = 'vial',
  Vials = 'vials',
  VideoArrowDownLeft = 'video-arrow-down-left',
  VideoArrowUpRight = 'video-arrow-up-right',
  VideoPlus = 'video-plus',
  VideoSlash = 'video-slash',
  Video = 'video',
  Vihara = 'vihara',
  Violin = 'violin',
  VirusCovidSlash = 'virus-covid-slash',
  VirusCovid = 'virus-covid',
  VirusSlash = 'virus-slash',
  Virus = 'virus',
  Viruses = 'viruses',
  Voicemail = 'voicemail',
  Volcano = 'volcano',
  Volleyball = 'volleyball',
  VolumeHigh = 'volume-high',
  VolumeLow = 'volume-low',
  VolumeOff = 'volume-off',
  VolumeSlash = 'volume-slash',
  VolumeXmark = 'volume-xmark',
  Volume = 'volume',
  VrCardboard = 'vr-cardboard',
  W = 'w',
  Waffle = 'waffle',
  WagonCovered = 'wagon-covered',
  Walker = 'walker',
  WalkieTalkie = 'walkie-talkie',
  Wallet = 'wallet',
  WandMagicSparkles = 'wand-magic-sparkles',
  WandMagic = 'wand-magic',
  WandSparkles = 'wand-sparkles',
  Wand = 'wand',
  WarehouseFull = 'warehouse-full',
  Warehouse = 'warehouse',
  WashingMachine = 'washing-machine',
  WatchApple = 'watch-apple',
  WatchCalculator = 'watch-calculator',
  WatchFitness = 'watch-fitness',
  WatchSmart = 'watch-smart',
  Watch = 'watch',
  WaterArrowDown = 'water-arrow-down',
  WaterArrowUp = 'water-arrow-up',
  WaterLadder = 'water-ladder',
  Water = 'water',
  WatermelonSlice = 'watermelon-slice',
  WavePulse = 'wave-pulse',
  WaveSine = 'wave-sine',
  WaveSquare = 'wave-square',
  WaveTriangle = 'wave-triangle',
  WaveformLines = 'waveform-lines',
  Waveform = 'waveform',
  WeightHanging = 'weight-hanging',
  WeightScale = 'weight-scale',
  Whale = 'whale',
  WheatAwnCircleExclamation = 'wheat-awn-circle-exclamation',
  WheatAwnSlash = 'wheat-awn-slash',
  WheatAwn = 'wheat-awn',
  WheatSlash = 'wheat-slash',
  Wheat = 'wheat',
  WheelchairMove = 'wheelchair-move',
  Wheelchair = 'wheelchair',
  WhiskeyGlassIce = 'whiskey-glass-ice',
  WhiskeyGlass = 'whiskey-glass',
  Whistle = 'whistle',
  WifiExclamation = 'wifi-exclamation',
  WifiFair = 'wifi-fair',
  WifiSlash = 'wifi-slash',
  WifiWeak = 'wifi-weak',
  Wifi = 'wifi',
  WindTurbine = 'wind-turbine',
  WindWarning = 'wind-warning',
  Wind = 'wind',
  WindowFlip = 'window-flip',
  WindowFrameOpen = 'window-frame-open',
  WindowFrame = 'window-frame',
  WindowMaximize = 'window-maximize',
  WindowMinimize = 'window-minimize',
  WindowRestore = 'window-restore',
  Window = 'window',
  Windsock = 'windsock',
  WineBottle = 'wine-bottle',
  WineGlassCrack = 'wine-glass-crack',
  WineGlassEmpty = 'wine-glass-empty',
  WineGlass = 'wine-glass',
  WonSign = 'won-sign',
  Worm = 'worm',
  Wreath = 'wreath',
  WrenchSimple = 'wrench-simple',
  Wrench = 'wrench',
  XRay = 'x-ray',
  X = 'x',
  XmarkLarge = 'xmark-large',
  XmarkToSlot = 'xmark-to-slot',
  Xmark = 'xmark',
  XmarksLines = 'xmarks-lines',
  Y = 'y',
  YenSign = 'yen-sign',
  YinYang = 'yin-yang',
  Z = 'z',
}

export interface Kehila {
  /** תוכן עמוד אודות */
  about?: string | null
  cycles: CycleFK[]
  dashboardEmbeddedLink: string
  id: number
  /** שפת מערכת */
  kehilaLanguage?: KehilaLanguageEnum | BlankEnum | NullEnum | null
  /** מפתח */
  key?: KehilaKeyEnum | BlankEnum | NullEnum | null
  /**
   * שם
   * @maxLength 100
   */
  name: string
  peilutTypes: PeilutTypeSerializerFK[]
  positions: Position[]
  shchavot: Shichva[]
  terminology: Terminology
}

/**
 * * `KYehudit` - Yehudit
 * * `Bmoshavim` - Bmosh
 * * `Nahal` - Nahal
 * * `KAravit` - Aravit
 * * `KDruzit` - Druzit
 * * `KTest` - Test
 */
export enum KehilaKeyEnum {
  KYehudit = 'KYehudit',
  Bmoshavim = 'Bmoshavim',
  Nahal = 'Nahal',
  KAravit = 'KAravit',
  KDruzit = 'KDruzit',
  KTest = 'KTest',
}

/**
 * * `Hebrew` - Hebrew
 * * `Arabic` - Arabic
 * * `English` - English
 */
export enum KehilaLanguageEnum {
  Hebrew = 'Hebrew',
  Arabic = 'Arabic',
  English = 'English',
}

export interface KehilaPhotos {
  photos: string[]
}

export interface KehilaThinFK {
  id: number
  /** מפתח */
  key?: KehilaKeyEnum | BlankEnum | NullEnum | null
  /**
   * שם
   * @maxLength 100
   */
  name: string
}

export interface KehilaThinnest {
  id: number
  /** מפתח */
  key?: KehilaKeyEnum | BlankEnum | NullEnum | null
  /**
   * שם
   * @maxLength 100
   */
  name: string
}

export interface KehilaThinnestRequest {
  /** מפתח */
  key?: KehilaKeyEnum | BlankEnum | NullEnum | null
  /**
   * שם
   * @minLength 1
   * @maxLength 100
   */
  name: string
}

/**
 * מפתח
 * * `KYehudit` - Yehudit
 * * `Bmoshavim` - Bmosh
 * * `Nahal` - Nahal
 * * `KAravit` - Aravit
 * * `KDruzit` - Druzit
 * * `KTest` - Test
 */
export enum KehilotKeyPhotosRetrieveParamsEnum {
  KYehudit = 'KYehudit',
  Bmoshavim = 'Bmoshavim',
  Nahal = 'Nahal',
  KAravit = 'KAravit',
  KDruzit = 'KDruzit',
  KTest = 'KTest',
  Value = '',
}

/**
 * מפתח
 * * `KYehudit` - Yehudit
 * * `Bmoshavim` - Bmosh
 * * `Nahal` - Nahal
 * * `KAravit` - Aravit
 * * `KDruzit` - Druzit
 * * `KTest` - Test
 */
export enum KehilotKeyPhotosRetrieveParamsKeyEnum {
  KYehudit = 'KYehudit',
  Bmoshavim = 'Bmoshavim',
  Nahal = 'Nahal',
  KAravit = 'KAravit',
  KDruzit = 'KDruzit',
  KTest = 'KTest',
  Value = '',
}

/**
 * מפתח
 * * `KYehudit` - Yehudit
 * * `Bmoshavim` - Bmosh
 * * `Nahal` - Nahal
 * * `KAravit` - Aravit
 * * `KDruzit` - Druzit
 * * `KTest` - Test
 */
export enum KehilotKeyRetrieveParamsEnum {
  KYehudit = 'KYehudit',
  Bmoshavim = 'Bmoshavim',
  Nahal = 'Nahal',
  KAravit = 'KAravit',
  KDruzit = 'KDruzit',
  KTest = 'KTest',
  Value = '',
}

/**
 * מפתח
 * * `KYehudit` - Yehudit
 * * `Bmoshavim` - Bmosh
 * * `Nahal` - Nahal
 * * `KAravit` - Aravit
 * * `KDruzit` - Druzit
 * * `KTest` - Test
 */
export enum KehilotKeyRetrieveParamsKeyEnum {
  KYehudit = 'KYehudit',
  Bmoshavim = 'Bmoshavim',
  Nahal = 'Nahal',
  KAravit = 'KAravit',
  KDruzit = 'KDruzit',
  KTest = 'KTest',
  Value = '',
}

export interface KehilotListParams {
  /** Affiliation id */
  affiliation?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface Ken {
  id: number
  /** @maxLength 100 */
  name: string
}

export interface KenFK {
  id: number
  /** @maxLength 100 */
  name: string
}

export interface KenGroup {
  id: number
  /** @maxLength 100 */
  name: string
}

export interface KenGroupRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
}

export interface KenRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
}

export interface KenimListParams {
  /** Affiliation id */
  affiliation?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /** A search term. */
  search?: string
}

/**
 * * `PEULA` - פעולה
 * * `EZER_HADRACHA` - כלי הדרכה
 * * `YESHIVA` - ישיבה
 * * `EREV_TARBUT` - ערב תרבות
 */
export enum KeyEnum {
  EREV_TARBUT = 'EREV_TARBUT',
  EZER_HADRACHA = 'EZER_HADRACHA',
  PEULA = 'PEULA',
  YESHIVA = 'YESHIVA',
}

/**
 * * `KLALI_MANAGER` - Klali Manager
 * * `KEHILA_MANAGER` - Kehila Manager
 * * `MAHOZ_MANAGER` - Mahoz Manager
 * * `KEN_GROUP_MANAGER` - Ken Group Manager
 * * `KEN_MANAGER` - Ken Manager
 * * `COMUNAR` - Comunar
 * * `MASHATZ` - Mashatz
 * * `MADATZ` - Madatz
 * * `MADATZ_TEENS` - Madatz Teens
 * * `MADATZ_TET` - Madatz Tet
 * * `CHANICH` - Chanich
 * * `IRGUNI` - Irguni
 * * `TARBUT` - Tarbut
 * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
 * * `LEGAL_APPROVER` - Legal Approver
 * * `READ_ONLY_KLALI` - Read Only Klali
 * * `READ_ONLY_KEHILA` - Read Only Kehila
 * * `READ_ONLY_MAHOZ` - Read Only Mahoz
 * * `READ_ONLY_GUEST` - Read Only Guest
 * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
 */
export enum KeyEnum1 {
  CHANICH = 'CHANICH',
  COMUNAR = 'COMUNAR',
  IRGUNI = 'IRGUNI',
  KEHILA_MANAGER = 'KEHILA_MANAGER',
  KEN_GROUP_MANAGER = 'KEN_GROUP_MANAGER',
  KEN_GROUP_MANAGER_READONLY = 'KEN_GROUP_MANAGER_READONLY',
  KEN_MANAGER = 'KEN_MANAGER',
  KLALI_MANAGER = 'KLALI_MANAGER',
  LEGAL_APPROVER = 'LEGAL_APPROVER',
  MADATZ = 'MADATZ',
  MADATZ_TEENS = 'MADATZ_TEENS',
  MADATZ_TET = 'MADATZ_TET',
  MAHOZ_MANAGER = 'MAHOZ_MANAGER',
  MASHATZ = 'MASHATZ',
  RAKAZ_CONTENT_KEHILA = 'RAKAZ_CONTENT_KEHILA',
  READ_ONLY_GUEST = 'READ_ONLY_GUEST',
  READ_ONLY_KEHILA = 'READ_ONLY_KEHILA',
  READ_ONLY_KLALI = 'READ_ONLY_KLALI',
  READ_ONLY_MAHOZ = 'READ_ONLY_MAHOZ',
  TARBUT = 'TARBUT',
}

/**
 * * `KLALI_MANAGER` - Klali Manager
 * * `KEHILA_MANAGER` - Kehila Manager
 * * `MAHOZ_MANAGER` - Mahoz Manager
 * * `KEN_GROUP_MANAGER` - Ken Group Manager
 * * `KEN_MANAGER` - Ken Manager
 * * `COMUNAR` - Comunar
 * * `MASHATZ` - Mashatz
 * * `MADATZ` - Madatz
 * * `MADATZ_TEENS` - Madatz Teens
 * * `MADATZ_TET` - Madatz Tet
 * * `CHANICH` - Chanich
 * * `IRGUNI` - Irguni
 * * `TARBUT` - Tarbut
 * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
 * * `LEGAL_APPROVER` - Legal Approver
 * * `READ_ONLY_KLALI` - Read Only Klali
 * * `READ_ONLY_KEHILA` - Read Only Kehila
 * * `READ_ONLY_MAHOZ` - Read Only Mahoz
 * * `READ_ONLY_GUEST` - Read Only Guest
 * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
 */
export enum KeyEnum2 {
  CHANICH = 'CHANICH',
  COMUNAR = 'COMUNAR',
  IRGUNI = 'IRGUNI',
  KEHILA_MANAGER = 'KEHILA_MANAGER',
  KEN_GROUP_MANAGER = 'KEN_GROUP_MANAGER',
  KEN_GROUP_MANAGER_READONLY = 'KEN_GROUP_MANAGER_READONLY',
  KEN_MANAGER = 'KEN_MANAGER',
  KLALI_MANAGER = 'KLALI_MANAGER',
  LEGAL_APPROVER = 'LEGAL_APPROVER',
  MADATZ = 'MADATZ',
  MADATZ_TEENS = 'MADATZ_TEENS',
  MADATZ_TET = 'MADATZ_TET',
  MAHOZ_MANAGER = 'MAHOZ_MANAGER',
  MASHATZ = 'MASHATZ',
  RAKAZ_CONTENT_KEHILA = 'RAKAZ_CONTENT_KEHILA',
  READ_ONLY_GUEST = 'READ_ONLY_GUEST',
  READ_ONLY_KEHILA = 'READ_ONLY_KEHILA',
  READ_ONLY_KLALI = 'READ_ONLY_KLALI',
  READ_ONLY_MAHOZ = 'READ_ONLY_MAHOZ',
  TARBUT = 'TARBUT',
}

export enum LangEnum {
  En = 'en',
  HeIL = 'he-IL',
}

export interface Mahoz {
  /**
   * קישור ללוח בקרה
   * @format uri
   * @maxLength 200
   */
  dashboardEmbeddedLink?: string | null
  id: number
  /** @maxLength 100 */
  name: string
}

export interface MahozFK {
  id: number
  /** @maxLength 100 */
  name: string
}

export interface MahozRequest {
  /**
   * קישור ללוח בקרה
   * @format uri
   * @maxLength 200
   */
  dashboardEmbeddedLink?: string | null
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
}

export interface MehozotListParams {
  /** Affiliation id */
  affiliation?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface Nispach {
  /**
   * טקסט נספח
   * @maxLength 255
   */
  explainText: string | null
  id: number
  /**
   * קישור נספח
   * @format uri
   * @maxLength 200
   */
  urlLink: string | null
}

export interface NispachRequest {
  /**
   * טקסט נספח
   * @maxLength 255
   */
  explainText: string | null
  /**
   * קישור נספח
   * @format uri
   * @maxLength 200
   */
  urlLink: string | null
}

export type NullEnum = null

export interface PaginatedAffiliationViewList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AffiliationView[]
}

export interface PaginatedAssetList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Asset[]
}

export interface PaginatedCategoryList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Category[]
}

export interface PaginatedClassificationViewList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ClassificationView[]
}

export interface PaginatedCycleList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Cycle[]
}

export interface PaginatedKehilaList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Kehila[]
}

export interface PaginatedKenList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Ken[]
}

export interface PaginatedMahozList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Mahoz[]
}

export interface PaginatedPeilutCardDisplayList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PeilutCardDisplay[]
}

export interface PaginatedPeilutCommentViewList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PeilutCommentView[]
}

export interface PaginatedPeilutList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Peilut[]
}

export interface PaginatedPeilutTypeList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PeilutType[]
}

export interface PaginatedPositionList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Position[]
}

export interface PaginatedQNACollectionList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: QNACollection[]
}

export interface PaginatedShichvaList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Shichva[]
}

export interface PaginatedStripList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Strip[]
}

export interface PaginatedTakanonList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Takanon[]
}

export interface PaginatedUsersViewList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: UsersView[]
}

export interface PatchedAffiliationUpdateRequest {
  /** סטטוס */
  affiliationStatus?: AffiliationStatus
  /** קהילה */
  kehila?: number | null
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** תפקיד */
  position?: number
  /** שכבות */
  shchavot?: number[]
  /** משתמש */
  user?: number
}

export interface PatchedAssetRequest {
  /**
   * @minLength 1
   * @maxLength 255
   */
  contentType?: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  filename?: string
  /**
   * @format binary
   * @pattern (?:odt|doc|docx|pages|txt|pdf|jpg|jpeg|ppt|pptx|png|svg|ppsx|xls|xlsx)$
   */
  source?: File
  /**
   * * `temp` - זמני
   * * `att` - משוייך
   */
  status?: StatusEnum
}

export interface PatchedCategoryRequest {
  isHighlighted?: boolean
  /**
   * @minLength 1
   * @maxLength 254
   */
  name?: string
}

export interface PatchedChangePasswordRequest {
  /** @minLength 1 */
  confirmPassword?: string
  /** @minLength 1 */
  newPassword?: string
  /** @minLength 1 */
  oldPassword?: string
}

export interface PatchedClassificationUpdateRequest {
  /** קהילה */
  kehila?: number
  /** קן */
  ken?: number | null
  /** מחוז */
  mahoz?: number | null
  /** פעילות */
  peilut?: number
  /** תפקיד */
  position?: number | null
  /** שכבות */
  shchavot?: number[]
}

export interface PatchedPeilutCommentUpdateRequest {
  /** @minLength 1 */
  body?: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType?: CommentTypeEnum
  creator?: number
  isDone?: boolean
  peilut?: number
}

export interface PatchedPeilutUpdateRequest {
  /** נספחים */
  attachments?: number[]
  /** גוף הפעילות בקובץ סגור */
  body?: number | null
  /** גוף הפעילות בקובץ פתוח */
  bodySrc?: number | null
  categories?: string[]
  classification?: ClassificationUpdateForPeilutRequest
  /** שער */
  cover?: number | null
  /** מחזורים */
  cycles?: number[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  /**
   * שפה
   * @minLength 1
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: NispachRequest[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  /** @minLength 1 */
  peilutType?: string
  peilutTypeId?: number
  /**
   * כותרת
   * @minLength 1
   * @maxLength 254
   */
  title?: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
}

export interface PatchedStripRequest {
  /** מותר למשתמשים לראות פעילויות בלי קשר להיררכיית התפקידים */
  allowActivitiesAboveUserPosition?: boolean
  /**
   * סמליל ייחודי
   * @maxLength 255
   */
  customIcon?: string | null
  /** קטגוריות להסיר */
  excludeCategories?: number[]
  /** פעילויות ספציפיות להסיר */
  excludeSpecificActivities?: number[]
  /** לסנן את הפעילויות לפי המחזור הנוכחי */
  filterByCycle?: boolean
  /** תפקידים לסנן לפיהם את הפעילויות */
  filterPeilutByPositions?: number[]
  /** הסתרת הרצועה */
  hideStrip?: boolean
  /** סמליל */
  icon?: IconEnum | BlankEnum | NullEnum | null
  /** צבע סמליל */
  iconColor?: IconColorEnum | BlankEnum | NullEnum | null
  /** קטגוריות לכלול */
  includeCategories?: number[]
  /** לכלול פעילויות ללא מחזור */
  includeEmptyCycle?: boolean
  /** פעילויות ספציפיות לכלול */
  includeSpecificActivities?: number[]
  /** קהילות */
  kehilot?: number[]
  /** קנים */
  kenim?: number[]
  /** מחוזות */
  mehozot?: number[]
  /**
   * שם
   * @minLength 1
   * @maxLength 255
   */
  name?: string
  peilutTypes?: string[]
  /**
   * עדיפות
   * @min 0
   * @max 2147483647
   */
  priority?: number
  /** שכבות */
  shchavot?: number[]
  /** תפקידים שיראו את הרצועה */
  showStripToPositions?: number[]
  /** מחזורים להראות בהם את הרצועה */
  visibleCycles?: number[]
}

export interface PatchedUsersViewRequest {
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * סיסמה
   * @minLength 1
   * @maxLength 128
   */
  password?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile?: ProfileRequest
}

export interface Peilut {
  attachments?: Asset[]
  body?: Asset
  bodySrc?: Asset
  categories?: Category[]
  categoryNames: string[]
  classifications?: ClassificationFK[]
  cover?: Asset
  /**
   * זמן יצירה
   * @format date-time
   */
  createdAt: string
  creator?: UserFK
  cycles?: Cycle[]
  cyclesLogos: string[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  id: number
  isAuthor: boolean
  isFavorited: boolean
  isTopical: boolean
  /**
   * שפה
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: Nispach[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  peilutStatusDisplay: string
  peilutType: string
  peilutTypeDisplay: string
  shchavotMax: string
  shchavotMin: string
  /**
   * כותרת
   * @maxLength 254
   */
  title: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
  /**
   * זמן עדכון
   * @format date-time
   */
  updatedAt: string
}

export interface PeilutCardDisplay {
  categoryNames: string[]
  cover?: Asset
  cycles?: CycleCard[]
  cyclesLogos: string[]
  id: number
  isAuthor: boolean
  isFavorited: boolean
  isTopical: boolean
  shchavotMax: string
  shchavotMin: string
  /**
   * כותרת
   * @maxLength 254
   */
  title: string
}

export interface PeilutComment {
  body: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType: CommentTypeEnum
  /** @format date-time */
  createdAt: string
  creator?: number
  id: number
  isDone?: boolean
  peilut: number
  /** @format date-time */
  updatedAt: string
}

export interface PeilutCommentRequest {
  /** @minLength 1 */
  body: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType: CommentTypeEnum
  creator?: number
  isDone?: boolean
  peilut: number
}

export interface PeilutCommentUpdate {
  body: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType?: CommentTypeEnum
  /** @format date-time */
  createdAt: string
  creator: number
  id: number
  isDone?: boolean
  peilut: number
  /** @format date-time */
  updatedAt: string
}

export interface PeilutCommentUpdateRequest {
  /** @minLength 1 */
  body: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType?: CommentTypeEnum
  creator: number
  isDone?: boolean
  peilut: number
}

export interface PeilutCommentView {
  body: string
  /**
   * * `LEGAL` - משפטי
   * * `EDUCATIONAL` - חינוכי
   */
  commentType: CommentTypeEnum
  /** @format date-time */
  createdAt: string
  creator: UserComment
  id: number
  isDone?: boolean
  peilut: number
  /** @format date-time */
  updatedAt: string
}

export interface PeilutCreate {
  /** נספחים */
  attachments?: number[]
  /** גוף הפעילות בקובץ סגור */
  body: number | null
  /** גוף הפעילות בקובץ פתוח */
  bodySrc?: number | null
  /** שער */
  cover?: number | null
  /** יוצר */
  creator?: number
  /** מחזורים */
  cycles?: number[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  id: number
  /**
   * שפה
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: Nispach[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  peilutType: string
  /**
   * כותרת
   * @maxLength 254
   */
  title: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
}

export interface PeilutCreateFormPopulate {
  activityTypes: PeilutType[]
  shchavot?: ShichvaFK[]
  targetAudience: PositionFK[]
}

export interface PeilutCreateRequest {
  /** נספחים */
  attachments?: number[]
  /** גוף הפעילות בקובץ סגור */
  body: number | null
  /** גוף הפעילות בקובץ פתוח */
  bodySrc?: number | null
  categories?: string[]
  /** שער */
  cover?: number | null
  /** יוצר */
  creator?: number
  /** מחזורים */
  cycles?: number[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  kehila?: number
  /**
   * שפה
   * @minLength 1
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: NispachRequest[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  /** @minLength 1 */
  peilutType: string
  position?: number
  shchavot?: number[]
  /**
   * כותרת
   * @minLength 1
   * @maxLength 254
   */
  title: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
}

export interface PeilutCreateRetrieveParams {
  /** activity_type key */
  activity_type?: string
  /** Affiliation id */
  affiliation?: number
  /** Kehila id */
  kehila_id?: number
  /** Kehila key */
  kehila_key?: string
  /** Position id */
  target_audience?: number
}

export interface PeilutFK {
  id: number
  /**
   * כותרת
   * @maxLength 254
   */
  title: string
}

export interface PeilutFavoritesListParams {
  /** Affiliation id */
  affiliation?: number
  categories?: number[]
  creator?: number
  /** cycles */
  cycles?: number[]
  /** Kehila id */
  kehila_id?: number
  /** Number of results to return per page. */
  limit?: number
  only_no_cycle?: boolean
  /** A page number within the paginated result set. */
  page?: number
  /**
   * * `DRAFT` - טיוטה
   * * `SUBMITTED` - נשלח
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `ARCHIVED` - בארכיון
   * * `RESUBMITTED` - נשלח שוב
   * * `REVISION_NEEDED` - הוחזר לתיקונים
   * * `EDUCATION_APPROVED` - אושר חינוכית
   *
   * * `DRAFT` - טיוטה
   * * `SUBMITTED` - נשלח
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `ARCHIVED` - בארכיון
   * * `RESUBMITTED` - נשלח שוב
   * * `REVISION_NEEDED` - הוחזר לתיקונים
   * * `EDUCATION_APPROVED` - אושר חינוכית
   */
  peilut_status?: PeilutStatusEnum1[]
  /**
   * * `PEULA` - פעולה
   * * `EZER_HADRACHA` - כלי הדרכה
   * * `YESHIVA` - ישיבה
   * * `EREV_TARBUT` - ערב תרבות
   */
  peilut_type?: PeilutTypeEnum1[]
  position?: number
  /** A search term. */
  search?: string
  shchavot?: number[]
}

/** ססטוס */
export enum PeilutFavoritesListParams1PeilutStatusEnum {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  EDUCATION_APPROVED = 'EDUCATION_APPROVED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  REVISION_NEEDED = 'REVISION_NEEDED',
  SUBMITTED = 'SUBMITTED',
}

export enum PeilutFavoritesListParams1PeilutTypeEnum {
  EREV_TARBUT = EREV_TARBUT,
  EZER_HADRACHA = EZER_HADRACHA,
  PEULA = PEULA,
  YESHIVA = YESHIVA,
}

export interface PeilutListParams {
  /** Affiliation id */
  affiliation?: number
  /** get the card serializer with extra details */
  card?: boolean
  categories?: number[]
  creator?: number
  /** cycles */
  cycles?: number[]
  /** Include peiluyot with no cycle */
  include_no_cycle?: boolean
  /** Kehila id */
  kehila_id?: number
  /** Number of results to return per page. */
  limit?: number
  only_no_cycle?: boolean
  /** A page number within the paginated result set. */
  page?: number
  /**
   * * `DRAFT` - טיוטה
   * * `SUBMITTED` - נשלח
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `ARCHIVED` - בארכיון
   * * `RESUBMITTED` - נשלח שוב
   * * `REVISION_NEEDED` - הוחזר לתיקונים
   * * `EDUCATION_APPROVED` - אושר חינוכית
   *
   * * `DRAFT` - טיוטה
   * * `SUBMITTED` - נשלח
   * * `APPROVED` - אושרה
   * * `REJECTED` - נדחתה
   * * `ARCHIVED` - בארכיון
   * * `RESUBMITTED` - נשלח שוב
   * * `REVISION_NEEDED` - הוחזר לתיקונים
   * * `EDUCATION_APPROVED` - אושר חינוכית
   */
  peilut_status?: PeilutStatusEnum[]
  /**
   * * `PEULA` - פעולה
   * * `EZER_HADRACHA` - כלי הדרכה
   * * `YESHIVA` - ישיבה
   * * `EREV_TARBUT` - ערב תרבות
   */
  peilut_type?: PeilutTypeEnum[]
  position?: number
  /** A search term. */
  search?: string
  shchavot?: number[]
}

/** ססטוס */
export enum PeilutListParams1PeilutStatusEnum {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  EDUCATION_APPROVED = 'EDUCATION_APPROVED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  REVISION_NEEDED = 'REVISION_NEEDED',
  SUBMITTED = 'SUBMITTED',
}

export enum PeilutListParams1PeilutTypeEnum {
  EREV_TARBUT = EREV_TARBUT,
  EZER_HADRACHA = EZER_HADRACHA,
  PEULA = PEULA,
  YESHIVA = YESHIVA,
}

/**
 * * `create` - Create
 * * `update` - Update
 * * `partial_update` - Partial Update
 * * `list` - List
 * * `retrieve` - Retrieve
 * * `delete` - Delete
 * * `legal_approve_peilut` - Legal Approve
 * * `educational_approve_peilut` - Educational Approve
 * * `edit_unowned_peilut` - Edit Unowned
 */
export enum PeilutPermissionActions {
  Create = 'create',
  Update = 'update',
  PartialUpdate = 'partial_update',
  List = 'list',
  Retrieve = 'retrieve',
  Delete = 'delete',
  LegalApprovePeilut = 'legal_approve_peilut',
  EducationalApprovePeilut = 'educational_approve_peilut',
  EditUnownedPeilut = 'edit_unowned_peilut',
}

export interface PeilutRetrieveParams {
  /** Affiliation id */
  affiliation?: number
  /** A unique integer value identifying this פעילות. */
  id: number
}

/**
 * * `DRAFT` - טיוטה
 * * `SUBMITTED` - נשלח
 * * `APPROVED` - אושרה
 * * `REJECTED` - נדחתה
 * * `ARCHIVED` - בארכיון
 * * `RESUBMITTED` - נשלח שוב
 * * `REVISION_NEEDED` - הוחזר לתיקונים
 * * `EDUCATION_APPROVED` - אושר חינוכית
 */
export enum PeilutStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
  RESUBMITTED = 'RESUBMITTED',
  REVISION_NEEDED = 'REVISION_NEEDED',
  EDUCATION_APPROVED = 'EDUCATION_APPROVED',
}

/** ססטוס */
export enum PeilutStatusEnum {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  EDUCATION_APPROVED = 'EDUCATION_APPROVED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  REVISION_NEEDED = 'REVISION_NEEDED',
  SUBMITTED = 'SUBMITTED',
}

/** ססטוס */
export enum PeilutStatusEnum1 {
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  EDUCATION_APPROVED = 'EDUCATION_APPROVED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  REVISION_NEEDED = 'REVISION_NEEDED',
  SUBMITTED = 'SUBMITTED',
}

export interface PeilutType {
  alias: string
  id: number
  /**
   * * `PEULA` - פעולה
   * * `EZER_HADRACHA` - כלי הדרכה
   * * `YESHIVA` - ישיבה
   * * `EREV_TARBUT` - ערב תרבות
   */
  key: PeilutTypeKeyEnum
  /** @maxLength 255 */
  name: string
}

export enum PeilutTypeEnum {
  EREV_TARBUT = EREV_TARBUT,
  EZER_HADRACHA = EZER_HADRACHA,
  PEULA = PEULA,
  YESHIVA = YESHIVA,
}

export enum PeilutTypeEnum1 {
  EREV_TARBUT = EREV_TARBUT,
  EZER_HADRACHA = EZER_HADRACHA,
  PEULA = PEULA,
  YESHIVA = YESHIVA,
}

/**
 * * `PEULA` - פעולה
 * * `EZER_HADRACHA` - כלי הדרכה
 * * `YESHIVA` - ישיבה
 * * `EREV_TARBUT` - ערב תרבות
 */
export enum PeilutTypeKeyEnum {
  PEULA = 'PEULA',
  EZER_HADRACHA = 'EZER_HADRACHA',
  YESHIVA = 'YESHIVA',
  EREV_TARBUT = 'EREV_TARBUT',
}

export interface PeilutTypeSerializerFK {
  alias: string
  id: number
  /** @maxLength 255 */
  key: string
  /** @maxLength 255 */
  name: string
}

export interface PeilutTypesGetByKeyAndKehilaRetrieveParams {
  /** Kehila key */
  kehila_key: string
  /** PeilutType key */
  peilut_type_key: string
}

export interface PeilutTypesListParams {
  /** Affiliation id */
  affiliation?: number
  kehila_id?: number
  kehila_key?: string
  /**
   * * `PEULA` - פעולה
   * * `EZER_HADRACHA` - כלי הדרכה
   * * `YESHIVA` - ישיבה
   * * `EREV_TARBUT` - ערב תרבות
   */
  key?: KeyEnum
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

/**
 * * `PEULA` - פעולה
 * * `EZER_HADRACHA` - כלי הדרכה
 * * `YESHIVA` - ישיבה
 * * `EREV_TARBUT` - ערב תרבות
 */
export enum PeilutTypesListParams1KeyEnum {
  EREV_TARBUT = 'EREV_TARBUT',
  EZER_HADRACHA = 'EZER_HADRACHA',
  PEULA = 'PEULA',
  YESHIVA = 'YESHIVA',
}

export interface PeilutTypesRetrieveParams {
  /** Affiliation id */
  affiliation?: number
  /** A unique integer value identifying this סוג פעילות. */
  id: number
}

export interface PeilutUpdate {
  /** נספחים */
  attachments?: number[]
  /** גוף הפעילות בקובץ סגור */
  body?: number | null
  /** גוף הפעילות בקובץ פתוח */
  bodySrc?: number | null
  /** שער */
  cover?: number | null
  /** מחזורים */
  cycles?: number[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  id: number
  /**
   * שפה
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: Nispach[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  peilutType?: string
  peilutTypeId?: number
  /**
   * כותרת
   * @maxLength 254
   */
  title: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
}

export interface PeilutUpdateRequest {
  /** נספחים */
  attachments?: number[]
  /** גוף הפעילות בקובץ סגור */
  body?: number | null
  /** גוף הפעילות בקובץ פתוח */
  bodySrc?: number | null
  categories?: string[]
  classification?: ClassificationUpdateForPeilutRequest
  /** שער */
  cover?: number | null
  /** מחזורים */
  cycles?: number[]
  /** תיאור */
  description?: string | null
  /** אורך פעילות משוער */
  estimatedDuration?: string | null
  /**
   * שפה
   * @minLength 1
   * @maxLength 10
   */
  language?: string
  nispachimLinks?: NispachRequest[]
  /** ססטוס */
  peilutStatus?: PeilutStatus
  /** @minLength 1 */
  peilutType?: string
  peilutTypeId?: number
  /**
   * כותרת
   * @minLength 1
   * @maxLength 254
   */
  title: string
  /**
   * זמן סיום פעולה אקטואלית
   * @format date
   */
  topicalEndDate?: string | null
  /**
   * זמן התחלה פעולה אקטואלית
   * @format date
   */
  topicalStartDate?: string | null
}

/**
 * * `create` - Create
 * * `update` - Update
 * * `partial_update` - Partial Update
 * * `list` - List
 * * `retrieve` - Retrieve
 * * `delete` - Delete
 */
export enum PermissionActions {
  Create = 'create',
  Update = 'update',
  PartialUpdate = 'partial_update',
  List = 'list',
  Retrieve = 'retrieve',
  Delete = 'delete',
}

export interface Permissions {
  affiliation: AffiliationPermissionActions[]
  affiliationsummary: PermissionActions[]
  apirequestlog: PermissionActions[]
  asset: PermissionActions[]
  category: PermissionActions[]
  classification: PermissionActions[]
  codeemail: PermissionActions[]
  collection: PermissionActions[]
  contenttype: PermissionActions[]
  cycle: PermissionActions[]
  globalpermission: GlobalpermissionEnum[]
  group: PermissionActions[]
  kehila: PermissionActions[]
  ken: PermissionActions[]
  kengroup: PermissionActions[]
  logentry: PermissionActions[]
  mahoz: PermissionActions[]
  peilut: PeilutPermissionActions[]
  peilutcollection: PermissionActions[]
  peilutcomment: PermissionActions[]
  peiluttype: PermissionActions[]
  permission: PermissionActions[]
  position: PermissionActions[]
  positiongroup: PermissionActions[]
  positionscope: PermissionActions[]
  profile: PermissionActions[]
  questionanswer: PermissionActions[]
  questionanswercollection: PermissionActions[]
  session: PermissionActions[]
  shichva: PermissionActions[]
  socialaction: PermissionActions[]
  strip: PermissionActions[]
  subcategory: PermissionActions[]
  takanon: PermissionActions[]
  takanonsignature: PermissionActions[]
  user: PermissionActions[]
}

export interface Position {
  alias: string
  id: number
  /** @maxLength 100 */
  key: string
  /** @maxLength 100 */
  name: string
}

export interface PositionFK {
  alias: string
  id: number
  /**
   * * `KLALI_MANAGER` - Klali Manager
   * * `KEHILA_MANAGER` - Kehila Manager
   * * `MAHOZ_MANAGER` - Mahoz Manager
   * * `KEN_GROUP_MANAGER` - Ken Group Manager
   * * `KEN_MANAGER` - Ken Manager
   * * `COMUNAR` - Comunar
   * * `MASHATZ` - Mashatz
   * * `MADATZ` - Madatz
   * * `MADATZ_TEENS` - Madatz Teens
   * * `MADATZ_TET` - Madatz Tet
   * * `CHANICH` - Chanich
   * * `IRGUNI` - Irguni
   * * `TARBUT` - Tarbut
   * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
   * * `LEGAL_APPROVER` - Legal Approver
   * * `READ_ONLY_KLALI` - Read Only Klali
   * * `READ_ONLY_KEHILA` - Read Only Kehila
   * * `READ_ONLY_MAHOZ` - Read Only Mahoz
   * * `READ_ONLY_GUEST` - Read Only Guest
   * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
   */
  key: PositionKeys
  /** @maxLength 100 */
  name: string
}

/**
 * * `KLALI_MANAGER` - Klali Manager
 * * `KEHILA_MANAGER` - Kehila Manager
 * * `MAHOZ_MANAGER` - Mahoz Manager
 * * `KEN_GROUP_MANAGER` - Ken Group Manager
 * * `KEN_MANAGER` - Ken Manager
 * * `COMUNAR` - Comunar
 * * `MASHATZ` - Mashatz
 * * `MADATZ` - Madatz
 * * `MADATZ_TEENS` - Madatz Teens
 * * `MADATZ_TET` - Madatz Tet
 * * `CHANICH` - Chanich
 * * `IRGUNI` - Irguni
 * * `TARBUT` - Tarbut
 * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
 * * `LEGAL_APPROVER` - Legal Approver
 * * `READ_ONLY_KLALI` - Read Only Klali
 * * `READ_ONLY_KEHILA` - Read Only Kehila
 * * `READ_ONLY_MAHOZ` - Read Only Mahoz
 * * `READ_ONLY_GUEST` - Read Only Guest
 * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
 */
export enum PositionKeys {
  KLALI_MANAGER = 'KLALI_MANAGER',
  KEHILA_MANAGER = 'KEHILA_MANAGER',
  MAHOZ_MANAGER = 'MAHOZ_MANAGER',
  KEN_GROUP_MANAGER = 'KEN_GROUP_MANAGER',
  KEN_MANAGER = 'KEN_MANAGER',
  COMUNAR = 'COMUNAR',
  MASHATZ = 'MASHATZ',
  MADATZ = 'MADATZ',
  MADATZ_TEENS = 'MADATZ_TEENS',
  MADATZ_TET = 'MADATZ_TET',
  CHANICH = 'CHANICH',
  IRGUNI = 'IRGUNI',
  TARBUT = 'TARBUT',
  RAKAZ_CONTENT_KEHILA = 'RAKAZ_CONTENT_KEHILA',
  LEGAL_APPROVER = 'LEGAL_APPROVER',
  READ_ONLY_KLALI = 'READ_ONLY_KLALI',
  READ_ONLY_KEHILA = 'READ_ONLY_KEHILA',
  READ_ONLY_MAHOZ = 'READ_ONLY_MAHOZ',
  READ_ONLY_GUEST = 'READ_ONLY_GUEST',
  KEN_GROUP_MANAGER_READONLY = 'KEN_GROUP_MANAGER_READONLY',
}

export interface PositionRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  key: string
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
}

/** KEN positions or MAHOZ positions */
export enum PositionTierEnum {
  KEHILA = 'KEHILA',
  KEN = 'KEN',
  KEN_GROUP = 'KEN_GROUP',
  KLALI = 'KLALI',
  MAHOZ = 'MAHOZ',
}

export interface PositionsGetByKeyAndKehilaRetrieveParams {
  /** Kehila key */
  kehila_key: string
  /** Position key */
  position_key: string
}

export interface PositionsListByKeysAndKehilaListParams {
  kehila_id?: number
  /** Kehila key */
  kehila_key: string
  /**
   * * `KLALI_MANAGER` - Klali Manager
   * * `KEHILA_MANAGER` - Kehila Manager
   * * `MAHOZ_MANAGER` - Mahoz Manager
   * * `KEN_GROUP_MANAGER` - Ken Group Manager
   * * `KEN_MANAGER` - Ken Manager
   * * `COMUNAR` - Comunar
   * * `MASHATZ` - Mashatz
   * * `MADATZ` - Madatz
   * * `MADATZ_TEENS` - Madatz Teens
   * * `MADATZ_TET` - Madatz Tet
   * * `CHANICH` - Chanich
   * * `IRGUNI` - Irguni
   * * `TARBUT` - Tarbut
   * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
   * * `LEGAL_APPROVER` - Legal Approver
   * * `READ_ONLY_KLALI` - Read Only Klali
   * * `READ_ONLY_KEHILA` - Read Only Kehila
   * * `READ_ONLY_MAHOZ` - Read Only Mahoz
   * * `READ_ONLY_GUEST` - Read Only Guest
   * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
   */
  key?: KeyEnum2
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /** Position key */
  position_keys: string
}

/**
 * * `KLALI_MANAGER` - Klali Manager
 * * `KEHILA_MANAGER` - Kehila Manager
 * * `MAHOZ_MANAGER` - Mahoz Manager
 * * `KEN_GROUP_MANAGER` - Ken Group Manager
 * * `KEN_MANAGER` - Ken Manager
 * * `COMUNAR` - Comunar
 * * `MASHATZ` - Mashatz
 * * `MADATZ` - Madatz
 * * `MADATZ_TEENS` - Madatz Teens
 * * `MADATZ_TET` - Madatz Tet
 * * `CHANICH` - Chanich
 * * `IRGUNI` - Irguni
 * * `TARBUT` - Tarbut
 * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
 * * `LEGAL_APPROVER` - Legal Approver
 * * `READ_ONLY_KLALI` - Read Only Klali
 * * `READ_ONLY_KEHILA` - Read Only Kehila
 * * `READ_ONLY_MAHOZ` - Read Only Mahoz
 * * `READ_ONLY_GUEST` - Read Only Guest
 * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
 */
export enum PositionsListByKeysAndKehilaListParams1KeyEnum {
  CHANICH = 'CHANICH',
  COMUNAR = 'COMUNAR',
  IRGUNI = 'IRGUNI',
  KEHILA_MANAGER = 'KEHILA_MANAGER',
  KEN_GROUP_MANAGER = 'KEN_GROUP_MANAGER',
  KEN_GROUP_MANAGER_READONLY = 'KEN_GROUP_MANAGER_READONLY',
  KEN_MANAGER = 'KEN_MANAGER',
  KLALI_MANAGER = 'KLALI_MANAGER',
  LEGAL_APPROVER = 'LEGAL_APPROVER',
  MADATZ = 'MADATZ',
  MADATZ_TEENS = 'MADATZ_TEENS',
  MADATZ_TET = 'MADATZ_TET',
  MAHOZ_MANAGER = 'MAHOZ_MANAGER',
  MASHATZ = 'MASHATZ',
  RAKAZ_CONTENT_KEHILA = 'RAKAZ_CONTENT_KEHILA',
  READ_ONLY_GUEST = 'READ_ONLY_GUEST',
  READ_ONLY_KEHILA = 'READ_ONLY_KEHILA',
  READ_ONLY_KLALI = 'READ_ONLY_KLALI',
  READ_ONLY_MAHOZ = 'READ_ONLY_MAHOZ',
  TARBUT = 'TARBUT',
}

export interface PositionsListParams {
  /** Affiliation id */
  affiliation?: number
  kehila_id?: number
  kehila_key?: string
  /**
   * * `KLALI_MANAGER` - Klali Manager
   * * `KEHILA_MANAGER` - Kehila Manager
   * * `MAHOZ_MANAGER` - Mahoz Manager
   * * `KEN_GROUP_MANAGER` - Ken Group Manager
   * * `KEN_MANAGER` - Ken Manager
   * * `COMUNAR` - Comunar
   * * `MASHATZ` - Mashatz
   * * `MADATZ` - Madatz
   * * `MADATZ_TEENS` - Madatz Teens
   * * `MADATZ_TET` - Madatz Tet
   * * `CHANICH` - Chanich
   * * `IRGUNI` - Irguni
   * * `TARBUT` - Tarbut
   * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
   * * `LEGAL_APPROVER` - Legal Approver
   * * `READ_ONLY_KLALI` - Read Only Klali
   * * `READ_ONLY_KEHILA` - Read Only Kehila
   * * `READ_ONLY_MAHOZ` - Read Only Mahoz
   * * `READ_ONLY_GUEST` - Read Only Guest
   * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
   */
  key?: KeyEnum1
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

/**
 * * `KLALI_MANAGER` - Klali Manager
 * * `KEHILA_MANAGER` - Kehila Manager
 * * `MAHOZ_MANAGER` - Mahoz Manager
 * * `KEN_GROUP_MANAGER` - Ken Group Manager
 * * `KEN_MANAGER` - Ken Manager
 * * `COMUNAR` - Comunar
 * * `MASHATZ` - Mashatz
 * * `MADATZ` - Madatz
 * * `MADATZ_TEENS` - Madatz Teens
 * * `MADATZ_TET` - Madatz Tet
 * * `CHANICH` - Chanich
 * * `IRGUNI` - Irguni
 * * `TARBUT` - Tarbut
 * * `RAKAZ_CONTENT_KEHILA` - Rakaz Content
 * * `LEGAL_APPROVER` - Legal Approver
 * * `READ_ONLY_KLALI` - Read Only Klali
 * * `READ_ONLY_KEHILA` - Read Only Kehila
 * * `READ_ONLY_MAHOZ` - Read Only Mahoz
 * * `READ_ONLY_GUEST` - Read Only Guest
 * * `KEN_GROUP_MANAGER_READONLY` - Ken Group Manager Readonly
 */
export enum PositionsListParams1KeyEnum {
  CHANICH = 'CHANICH',
  COMUNAR = 'COMUNAR',
  IRGUNI = 'IRGUNI',
  KEHILA_MANAGER = 'KEHILA_MANAGER',
  KEN_GROUP_MANAGER = 'KEN_GROUP_MANAGER',
  KEN_GROUP_MANAGER_READONLY = 'KEN_GROUP_MANAGER_READONLY',
  KEN_MANAGER = 'KEN_MANAGER',
  KLALI_MANAGER = 'KLALI_MANAGER',
  LEGAL_APPROVER = 'LEGAL_APPROVER',
  MADATZ = 'MADATZ',
  MADATZ_TEENS = 'MADATZ_TEENS',
  MADATZ_TET = 'MADATZ_TET',
  MAHOZ_MANAGER = 'MAHOZ_MANAGER',
  MASHATZ = 'MASHATZ',
  RAKAZ_CONTENT_KEHILA = 'RAKAZ_CONTENT_KEHILA',
  READ_ONLY_GUEST = 'READ_ONLY_GUEST',
  READ_ONLY_KEHILA = 'READ_ONLY_KEHILA',
  READ_ONLY_KLALI = 'READ_ONLY_KLALI',
  READ_ONLY_MAHOZ = 'READ_ONLY_MAHOZ',
  TARBUT = 'TARBUT',
}

export interface PositionsRetrieveParams {
  /** Affiliation id */
  affiliation?: number
  /** A unique integer value identifying this תפקיד. */
  id: number
}

export interface Profile {
  /** @maxLength 15 */
  phoneNumber?: string | null
}

export interface ProfileRequest {
  /** @maxLength 15 */
  phoneNumber?: string | null
}

export interface QNA {
  answer: string
  id: number
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
  /** @maxLength 255 */
  question: string
}

export interface QNACollection {
  id: number
  /** @maxLength 255 */
  name: string
  questions: QNA[]
  /**
   * * `SIGNUP` - הרשמה
   * * `SIGNIN` - התחברות
   * * `FEED` - דף ראשי
   * * `USER_MANAGING` - ניהול משתמשים
   */
  section: SectionEnum
}

export interface QnaGroupListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /**
   * * `SIGNUP` - הרשמה
   * * `SIGNIN` - התחברות
   * * `FEED` - דף ראשי
   * * `USER_MANAGING` - ניהול משתמשים
   *
   * * `SIGNUP` - הרשמה
   * * `SIGNIN` - התחברות
   * * `FEED` - דף ראשי
   * * `USER_MANAGING` - ניהול משתמשים
   */
  section?: SectionEnum
}

/**
 * * `SIGNUP` - הרשמה
 * * `SIGNIN` - התחברות
 * * `FEED` - דף ראשי
 * * `USER_MANAGING` - ניהול משתמשים
 *
 * * `SIGNUP` - הרשמה
 * * `SIGNIN` - התחברות
 * * `FEED` - דף ראשי
 * * `USER_MANAGING` - ניהול משתמשים
 */
export enum QnaGroupListParams1SectionEnum {
  FEED = 'FEED',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  USER_MANAGING = 'USER_MANAGING',
}

export interface ResetPassword {
  code: number
  /** @format email */
  email?: string
}

export interface ResetPasswordRequest {
  code: number
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  password: string
  /** @minLength 1 */
  phone?: string
}

export interface SchemaRetrieveParams {
  format?: FormatEnum
  lang?: LangEnum
}

export enum SchemaRetrieveParams1FormatEnum {
  Json = 'json',
  Yaml = 'yaml',
}

export enum SchemaRetrieveParams1LangEnum {
  En = 'en',
  HeIL = 'he-IL',
}

/**
 * * `SIGNUP` - הרשמה
 * * `SIGNIN` - התחברות
 * * `FEED` - דף ראשי
 * * `USER_MANAGING` - ניהול משתמשים
 *
 * * `SIGNUP` - הרשמה
 * * `SIGNIN` - התחברות
 * * `FEED` - דף ראשי
 * * `USER_MANAGING` - ניהול משתמשים
 */
export enum SectionEnum {
  FEED = 'FEED',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  USER_MANAGING = 'USER_MANAGING',
}

export interface ShchavotGetByOrderAndKehilaRetrieveParams {
  /** Kehila key */
  kehila_key: string
  /** shichva order */
  shichva_order: number
}

export interface ShchavotListByOrdersAndKehilaListParams {
  kehila_id?: number
  /** Kehila key */
  kehila_key: string
  key?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  position?: string
  /** shichva order */
  shchavot_orders: number[]
}

export interface ShchavotListParams {
  kehila_id?: number
  kehila_key?: string
  key?: number
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /** created affiliation position id */
  position?: number
}

export interface Shichva {
  alias: string
  id: number
  /** @maxLength 100 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
}

export interface ShichvaFK {
  alias: string
  id: number
  /** @maxLength 100 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
}

export interface ShichvaRequest {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  order: number
}

/**
 * * `temp` - זמני
 * * `att` - משוייך
 */
export enum StatusEnum {
  Temp = 'temp',
  Att = 'att',
}

export interface Strip {
  /** מותר למשתמשים לראות פעילויות בלי קשר להיררכיית התפקידים */
  allowActivitiesAboveUserPosition?: boolean
  /**
   * סמליל ייחודי
   * @maxLength 255
   */
  customIcon?: string | null
  /** קטגוריות להסיר */
  excludeCategories?: number[]
  /** פעילויות ספציפיות להסיר */
  excludeSpecificActivities?: number[]
  /** לסנן את הפעילויות לפי המחזור הנוכחי */
  filterByCycle?: boolean
  /** תפקידים לסנן לפיהם את הפעילויות */
  filterPeilutByPositions?: number[]
  /** הסתרת הרצועה */
  hideStrip?: boolean
  /** סמליל */
  icon?: IconEnum | BlankEnum | NullEnum | null
  /** צבע סמליל */
  iconColor?: IconColorEnum | BlankEnum | NullEnum | null
  iconPreview: string
  id: number
  /** קטגוריות לכלול */
  includeCategories?: number[]
  /** לכלול פעילויות ללא מחזור */
  includeEmptyCycle?: boolean
  /** פעילויות ספציפיות לכלול */
  includeSpecificActivities?: number[]
  /** קהילות */
  kehilot: number[]
  /** קנים */
  kenim?: number[]
  /** מחוזות */
  mehozot?: number[]
  /**
   * שם
   * @maxLength 255
   */
  name: string
  peilutTypes?: string[]
  /**
   * עדיפות
   * @min 0
   * @max 2147483647
   */
  priority: number
  /** שכבות */
  shchavot?: number[]
  /** תפקידים שיראו את הרצועה */
  showStripToPositions?: number[]
  /** מחזורים להראות בהם את הרצועה */
  visibleCycles?: number[]
}

export interface StripRequest {
  /** מותר למשתמשים לראות פעילויות בלי קשר להיררכיית התפקידים */
  allowActivitiesAboveUserPosition?: boolean
  /**
   * סמליל ייחודי
   * @maxLength 255
   */
  customIcon?: string | null
  /** קטגוריות להסיר */
  excludeCategories?: number[]
  /** פעילויות ספציפיות להסיר */
  excludeSpecificActivities?: number[]
  /** לסנן את הפעילויות לפי המחזור הנוכחי */
  filterByCycle?: boolean
  /** תפקידים לסנן לפיהם את הפעילויות */
  filterPeilutByPositions?: number[]
  /** הסתרת הרצועה */
  hideStrip?: boolean
  /** סמליל */
  icon?: IconEnum | BlankEnum | NullEnum | null
  /** צבע סמליל */
  iconColor?: IconColorEnum | BlankEnum | NullEnum | null
  /** קטגוריות לכלול */
  includeCategories?: number[]
  /** לכלול פעילויות ללא מחזור */
  includeEmptyCycle?: boolean
  /** פעילויות ספציפיות לכלול */
  includeSpecificActivities?: number[]
  /** קהילות */
  kehilot: number[]
  /** קנים */
  kenim?: number[]
  /** מחוזות */
  mehozot?: number[]
  /**
   * שם
   * @minLength 1
   * @maxLength 255
   */
  name: string
  peilutTypes?: string[]
  /**
   * עדיפות
   * @min 0
   * @max 2147483647
   */
  priority: number
  /** שכבות */
  shchavot?: number[]
  /** תפקידים שיראו את הרצועה */
  showStripToPositions?: number[]
  /** מחזורים להראות בהם את הרצועה */
  visibleCycles?: number[]
}

export interface StripsListParams {
  /** Affiliation id */
  affiliation?: number
  /** cycles for strips peiluyot */
  cycles?: number[]
  /** Kehila id */
  kehila_id?: number
  /** Kehila key */
  kehila_key?: string
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /** shchavot for strips peiluyot */
  shchavot?: number[]
}

export interface StripsPeilutListParams {
  /** Affiliation id */
  affiliation?: number
  /** cycles for strips peiluyot */
  cycles?: number[]
  /** A unique integer value identifying this רצועה. */
  id: number
  /** Kehila id */
  kehila_id?: number
  /** Kehila key */
  kehila_key?: string
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
  /** shchavot for strips peiluyot */
  shchavot?: number[]
}

export interface Takanon {
  body: string
  /** @format date-time */
  created: string
  id: number
  /** @maxLength 254 */
  title: string
}

export interface TakanonListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface TakanonSignature {
  id: number
  takanon: number
  user?: number | null
}

export interface TakanonSignatureRequest {
  takanon: number
  user?: number | null
}

export interface Terminology {
  background: AssetFK
  cycle: string
  cyclePlural: string
  favicon: string
  /** @format uri */
  hadrachaWebsiteLink: string
  hadrachaWebsiteName: string
  headerColor: string
  heroImageRegister: string
  heroImageResetPw: string
  heroImageSignIn: string
  kehila: string
  kehilaPlural: string
  ken: string
  kenPlural: string
  logoColor: AssetFK
  logoWhite: AssetFK
  mahoz: string
  mahozPlural: string
  /** @format uri */
  movementWebsiteLink: string
  nextToLogo: string
  shareMetadataDescription: string
  shareMetadataImage: string
  shareMetadataTitle: string
  supportEmail: string
  websiteName: string
  workRightsLinkAlias: string
}

export interface ThinUser {
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: number
}

export interface ThinUserRequest {
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: number
}

export interface TokenObtainPairSerializerEmail {
  access: string
  email?: string
  refresh: string
}

export interface TokenObtainPairSerializerEmailRequest {
  /** @minLength 1 */
  email?: string
  /** @minLength 1 */
  password: string
  /** @minLength 1 */
  phone?: string
}

export interface TokenRefresh {
  access: string
  refresh: string
}

export interface TokenRefreshRequest {
  /** @minLength 1 */
  refresh: string
}

export interface UserComment {
  affiliations: AffiliationFK[]
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  isLegalApprover: boolean
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
}

export interface UserFK {
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
}

export interface UsersCreate {
  affiliations: AffiliationView[]
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email?: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: Profile
}

export interface UsersCreateRequest {
  /** @minLength 1 */
  confirmPassword: string
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * סיסמה
   * @minLength 1
   * @maxLength 128
   */
  password: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: ProfileRequest
  signature: number
}

export interface UsersListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface UsersMe {
  affiliations: AffiliationPermissions[]
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email: string
  emailVerified?: boolean
  favoritePeiluyot: any[]
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  phoneVerified?: boolean
  profile: Profile
  signedTakanon: Takanon
}

export interface UsersSendVerificationCodeCreateParams {
  /** only send email code */
  email_only?: boolean
  /** A unique integer value identifying this משתמש. */
  id: number
  /** only send phone code */
  phone_only?: boolean
}

export interface UsersUnaffiliatedListParams {
  /** Number of results to return per page. */
  limit?: number
  /** A page number within the paginated result set. */
  page?: number
}

export interface UsersView {
  affiliations: AffiliationView[]
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @maxLength 254
   */
  email?: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  id: number
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: Profile
}

export interface UsersViewRequest {
  /**
   * כתובת דואר אלקטרוני
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /**
   * שם פרטי
   * @maxLength 150
   */
  firstName?: string
  /**
   * שם משפחה
   * @maxLength 150
   */
  lastName?: string
  /**
   * סיסמה
   * @minLength 1
   * @maxLength 128
   */
  password: string
  /**
   * phone for SMS auth
   * @maxLength 128
   */
  phone?: string | null
  profile: ProfileRequest
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor(
    { securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {},
  ) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>(
    { secure, path, type, query, format, body, ...params }: FullRequestParams,
  ): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  affiliations = {
    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsCreate
     * @request POST:/affiliations/
     * @secure
     */
    affiliationsCreate: (data: AffiliationCreateRequest, params: RequestParams = {}) =>
      this.http.request<AffiliationCreate, any>({
        path: `/affiliations/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsCreateRetrieve
     * @request GET:/affiliations/create/
     * @secure
     */
    affiliationsCreateRetrieve: (
      query: AffiliationsCreateRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<AffiliationCreateFormPopulate, any>({
        path: `/affiliations/create/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsDestroy
     * @request DELETE:/affiliations/{id}/
     * @secure
     */
    affiliationsDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/affiliations/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsList
     * @request GET:/affiliations/
     * @secure
     */
    affiliationsList: (query: AffiliationsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedAffiliationViewList, any>({
        path: `/affiliations/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsPartialUpdate
     * @request PATCH:/affiliations/{id}/
     * @secure
     */
    affiliationsPartialUpdate: (
      id: number,
      data: PatchedAffiliationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<AffiliationUpdate, any>({
        path: `/affiliations/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsRetrieve
     * @request GET:/affiliations/{id}/
     * @secure
     */
    affiliationsRetrieve: (
      { id, ...query }: AffiliationsRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<AffiliationView, any>({
        path: `/affiliations/${id}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsTestDataDestroy
     * @request DELETE:/affiliations/test_data/
     * @secure
     */
    affiliationsTestDataDestroy: (
      query: AffiliationsTestDataDestroyParams,
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/affiliations/test_data/`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags affiliations
     * @name AffiliationsUpdate
     * @request PUT:/affiliations/{id}/
     * @secure
     */
    affiliationsUpdate: (id: number, data: AffiliationUpdateRequest, params: RequestParams = {}) =>
      this.http.request<AffiliationUpdate, any>({
        path: `/affiliations/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  assets = {
    /**
     * No description
     *
     * @tags assets
     * @name AssetsCreate
     * @request POST:/assets/
     * @secure
     */
    assetsCreate: (data: AssetRequest, params: RequestParams = {}) =>
      this.http.request<Asset, any>({
        path: `/assets/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name AssetsDestroy
     * @request DELETE:/assets/{id}/
     * @secure
     */
    assetsDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/assets/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name AssetsList
     * @request GET:/assets/
     * @secure
     */
    assetsList: (query: AssetsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedAssetList, any>({
        path: `/assets/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name AssetsPartialUpdate
     * @request PATCH:/assets/{id}/
     * @secure
     */
    assetsPartialUpdate: (id: number, data: PatchedAssetRequest, params: RequestParams = {}) =>
      this.http.request<Asset, any>({
        path: `/assets/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name AssetsRetrieve
     * @request GET:/assets/{id}/
     * @secure
     */
    assetsRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Asset, any>({
        path: `/assets/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name AssetsUpdate
     * @request PUT:/assets/{id}/
     * @secure
     */
    assetsUpdate: (id: number, data: AssetRequest, params: RequestParams = {}) =>
      this.http.request<Asset, any>({
        path: `/assets/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  authToken = {
    /**
     * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     *
     * @tags auth-token
     * @name AuthTokenCreate
     * @request POST:/auth-token/
     */
    authTokenCreate: (data: TokenObtainPairSerializerEmailRequest, params: RequestParams = {}) =>
      this.http.request<TokenObtainPairSerializerEmail, any>({
        path: `/auth-token/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  categories = {
    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesCreate
     * @request POST:/categories/
     * @secure
     */
    categoriesCreate: (data: CategoryRequest, params: RequestParams = {}) =>
      this.http.request<Category, any>({
        path: `/categories/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesDestroy
     * @request DELETE:/categories/{id}/
     * @secure
     */
    categoriesDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/categories/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesList
     * @request GET:/categories/
     * @secure
     */
    categoriesList: (query: CategoriesListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedCategoryList, any>({
        path: `/categories/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesPartialUpdate
     * @request PATCH:/categories/{id}/
     * @secure
     */
    categoriesPartialUpdate: (
      id: number,
      data: PatchedCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<Category, any>({
        path: `/categories/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesRetrieve
     * @request GET:/categories/{id}/
     * @secure
     */
    categoriesRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Category, any>({
        path: `/categories/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags categories
     * @name CategoriesUpdate
     * @request PUT:/categories/{id}/
     * @secure
     */
    categoriesUpdate: (id: number, data: CategoryRequest, params: RequestParams = {}) =>
      this.http.request<Category, any>({
        path: `/categories/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  changePassword = {
    /**
     * @description endpoint for changing password
     *
     * @tags change-password
     * @name ChangePasswordPartialUpdate
     * @request PATCH:/change-password/
     * @secure
     */
    changePasswordPartialUpdate: (data: PatchedChangePasswordRequest, params: RequestParams = {}) =>
      this.http.request<ChangePassword, any>({
        path: `/change-password/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description endpoint for changing password
     *
     * @tags change-password
     * @name ChangePasswordUpdate
     * @request PUT:/change-password/
     * @secure
     */
    changePasswordUpdate: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.http.request<ChangePassword, any>({
        path: `/change-password/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  classifications = {
    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsCreate
     * @request POST:/classifications/
     * @secure
     */
    classificationsCreate: (data: ClassificationCreateRequest, params: RequestParams = {}) =>
      this.http.request<ClassificationCreate, any>({
        path: `/classifications/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsDestroy
     * @request DELETE:/classifications/{id}/
     * @secure
     */
    classificationsDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/classifications/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsList
     * @request GET:/classifications/
     * @secure
     */
    classificationsList: (query: ClassificationsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedClassificationViewList, any>({
        path: `/classifications/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsPartialUpdate
     * @request PATCH:/classifications/{id}/
     * @secure
     */
    classificationsPartialUpdate: (
      id: number,
      data: PatchedClassificationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<ClassificationUpdate, any>({
        path: `/classifications/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsRetrieve
     * @request GET:/classifications/{id}/
     * @secure
     */
    classificationsRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<ClassificationView, any>({
        path: `/classifications/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags classifications
     * @name ClassificationsUpdate
     * @request PUT:/classifications/{id}/
     * @secure
     */
    classificationsUpdate: (
      id: number,
      data: ClassificationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<ClassificationUpdate, any>({
        path: `/classifications/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  comments = {
    /**
     * No description
     *
     * @tags comments
     * @name CommentsCreate
     * @request POST:/comments/
     * @secure
     */
    commentsCreate: (data: PeilutCommentRequest, params: RequestParams = {}) =>
      this.http.request<PeilutComment, any>({
        path: `/comments/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentsDestroy
     * @request DELETE:/comments/{id}/
     * @secure
     */
    commentsDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/comments/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentsList
     * @request GET:/comments/
     * @secure
     */
    commentsList: (query: CommentsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPeilutCommentViewList, any>({
        path: `/comments/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentsPartialUpdate
     * @request PATCH:/comments/{id}/
     * @secure
     */
    commentsPartialUpdate: (
      id: number,
      data: PatchedPeilutCommentUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<PeilutCommentUpdate, any>({
        path: `/comments/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentsRetrieve
     * @request GET:/comments/{id}/
     * @secure
     */
    commentsRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<PeilutCommentView, any>({
        path: `/comments/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentsUpdate
     * @request PUT:/comments/{id}/
     * @secure
     */
    commentsUpdate: (id: number, data: PeilutCommentUpdateRequest, params: RequestParams = {}) =>
      this.http.request<PeilutCommentUpdate, any>({
        path: `/comments/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  cycles = {
    /**
     * No description
     *
     * @tags cycles
     * @name CyclesAboutRetrieve
     * @request GET:/cycles/{id}/about/
     * @secure
     */
    cyclesAboutRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Cycle, any>({
        path: `/cycles/${id}/about/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cycles
     * @name CyclesList
     * @request GET:/cycles/
     * @secure
     */
    cyclesList: (query: CyclesListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedCycleList, any>({
        path: `/cycles/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cycles
     * @name CyclesRetrieve
     * @request GET:/cycles/{id}/
     * @secure
     */
    cyclesRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Cycle, any>({
        path: `/cycles/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  forgotPassword = {
    /**
     * @description Endpoint for sending email code for changing password
     *
     * @tags forgot-password
     * @name ForgotPasswordCreate
     * @request POST:/forgot-password/
     * @secure
     */
    forgotPasswordCreate: (data: ForgotPasswordRequest, params: RequestParams = {}) =>
      this.http.request<ForgotPassword, any>({
        path: `/forgot-password/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  kehilot = {
    /**
     * No description
     *
     * @tags kehilot
     * @name KehilotKeyPhotosRetrieve
     * @request GET:/kehilot/key/{key}/photos/
     * @secure
     */
    kehilotKeyPhotosRetrieve: (
      key: KehilotKeyPhotosRetrieveParamsEnum,
      params: RequestParams = {},
    ) =>
      this.http.request<KehilaPhotos, any>({
        path: `/kehilot/key/${key}/photos/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kehilot
     * @name KehilotKeyRetrieve
     * @request GET:/kehilot/key/{key}/
     * @secure
     */
    kehilotKeyRetrieve: (key: KehilotKeyRetrieveParamsEnum, params: RequestParams = {}) =>
      this.http.request<Record<string, any>, any>({
        path: `/kehilot/key/${key}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kehilot
     * @name KehilotList
     * @request GET:/kehilot/
     * @secure
     */
    kehilotList: (query: KehilotListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedKehilaList, any>({
        path: `/kehilot/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kehilot
     * @name KehilotPhotosRetrieve
     * @request GET:/kehilot/{id}/photos/
     * @secure
     */
    kehilotPhotosRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<KehilaPhotos, any>({
        path: `/kehilot/${id}/photos/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kehilot
     * @name KehilotRetrieve
     * @request GET:/kehilot/{id}/
     * @secure
     */
    kehilotRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Kehila, any>({
        path: `/kehilot/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  kenim = {
    /**
     * No description
     *
     * @tags kenim
     * @name KenimList
     * @request GET:/kenim/
     * @secure
     */
    kenimList: (query: KenimListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedKenList, any>({
        path: `/kenim/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kenim
     * @name KenimRetrieve
     * @request GET:/kenim/{id}/
     * @secure
     */
    kenimRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Ken, any>({
        path: `/kenim/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  mehozot = {
    /**
     * No description
     *
     * @tags mehozot
     * @name MehozotList
     * @request GET:/mehozot/
     * @secure
     */
    mehozotList: (query: MehozotListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedMahozList, any>({
        path: `/mehozot/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mehozot
     * @name MehozotRetrieve
     * @request GET:/mehozot/{id}/
     * @secure
     */
    mehozotRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Mahoz, any>({
        path: `/mehozot/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  peilut = {
    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutCreate
     * @request POST:/peilut/
     * @secure
     */
    peilutCreate: (data: PeilutCreateRequest, params: RequestParams = {}) =>
      this.http.request<PeilutCreate, any>({
        path: `/peilut/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutCreateRetrieve
     * @request GET:/peilut/create/
     * @secure
     */
    peilutCreateRetrieve: (query: PeilutCreateRetrieveParams, params: RequestParams = {}) =>
      this.http.request<PeilutCreateFormPopulate, any>({
        path: `/peilut/create/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutDestroy
     * @request DELETE:/peilut/{id}/
     * @secure
     */
    peilutDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/peilut/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutFavoriteCreate
     * @request POST:/peilut/{id}/favorite/
     * @secure
     */
    peilutFavoriteCreate: (id: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/peilut/${id}/favorite/`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutFavoritesList
     * @request GET:/peilut/favorites/
     * @secure
     */
    peilutFavoritesList: (query: PeilutFavoritesListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPeilutList, any>({
        path: `/peilut/favorites/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutList
     * @request GET:/peilut/
     * @secure
     */
    peilutList: (query: PeilutListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPeilutList, any>({
        path: `/peilut/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutPartialUpdate
     * @request PATCH:/peilut/{id}/
     * @secure
     */
    peilutPartialUpdate: (
      id: number,
      data: PatchedPeilutUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<PeilutUpdate, any>({
        path: `/peilut/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutRetrieve
     * @request GET:/peilut/{id}/
     * @secure
     */
    peilutRetrieve: ({ id, ...query }: PeilutRetrieveParams, params: RequestParams = {}) =>
      this.http.request<Peilut, any>({
        path: `/peilut/${id}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutUnfavoriteDestroy
     * @request DELETE:/peilut/{id}/unfavorite/
     * @secure
     */
    peilutUnfavoriteDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/peilut/${id}/unfavorite/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags peilut
     * @name PeilutUpdate
     * @request PUT:/peilut/{id}/
     * @secure
     */
    peilutUpdate: (id: number, data: PeilutUpdateRequest, params: RequestParams = {}) =>
      this.http.request<PeilutUpdate, any>({
        path: `/peilut/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  peilutTypes = {
    /**
     * No description
     *
     * @tags peilut_types
     * @name PeilutTypesGetByKeyAndKehilaRetrieve
     * @request GET:/peilut_types/get_by_key_and_kehila/
     * @secure
     */
    peilutTypesGetByKeyAndKehilaRetrieve: (
      query: PeilutTypesGetByKeyAndKehilaRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<PeilutType, any>({
        path: `/peilut_types/get_by_key_and_kehila/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags peilut_types
     * @name PeilutTypesList
     * @request GET:/peilut_types/
     * @secure
     */
    peilutTypesList: (query: PeilutTypesListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPeilutTypeList, any>({
        path: `/peilut_types/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags peilut_types
     * @name PeilutTypesRetrieve
     * @request GET:/peilut_types/{id}/
     * @secure
     */
    peilutTypesRetrieve: (
      { id, ...query }: PeilutTypesRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<PeilutType, any>({
        path: `/peilut_types/${id}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  positions = {
    /**
     * No description
     *
     * @tags positions
     * @name PositionsGetByKeyAndKehilaRetrieve
     * @request GET:/positions/get_by_key_and_kehila/
     * @secure
     */
    positionsGetByKeyAndKehilaRetrieve: (
      query: PositionsGetByKeyAndKehilaRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<Position, any>({
        path: `/positions/get_by_key_and_kehila/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags positions
     * @name PositionsList
     * @request GET:/positions/
     * @secure
     */
    positionsList: (query: PositionsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPositionList, any>({
        path: `/positions/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags positions
     * @name PositionsListByKeysAndKehilaList
     * @request GET:/positions/list_by_keys_and_kehila/
     * @secure
     */
    positionsListByKeysAndKehilaList: (
      query: PositionsListByKeysAndKehilaListParams,
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedPositionList, any>({
        path: `/positions/list_by_keys_and_kehila/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags positions
     * @name PositionsRetrieve
     * @request GET:/positions/{id}/
     * @secure
     */
    positionsRetrieve: ({ id, ...query }: PositionsRetrieveParams, params: RequestParams = {}) =>
      this.http.request<Position, any>({
        path: `/positions/${id}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  qnaGroup = {
    /**
     * No description
     *
     * @tags qna_group
     * @name QnaGroupList
     * @request GET:/qna_group/
     * @secure
     */
    qnaGroupList: (query: QnaGroupListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedQNACollectionList, any>({
        path: `/qna_group/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags qna_group
     * @name QnaGroupRetrieve
     * @request GET:/qna_group/{id}/
     * @secure
     */
    qnaGroupRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<QNACollection, any>({
        path: `/qna_group/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  refreshToken = {
    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags refresh-token
     * @name RefreshTokenCreate
     * @request POST:/refresh-token/
     */
    refreshTokenCreate: (data: TokenRefreshRequest, params: RequestParams = {}) =>
      this.http.request<TokenRefresh, any>({
        path: `/refresh-token/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  resetPassword = {
    /**
     * @description Endpoint for setting new password with email code
     *
     * @tags reset-password
     * @name ResetPasswordCreate
     * @request POST:/reset-password/
     * @secure
     */
    resetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.http.request<ResetPassword, any>({
        path: `/reset-password/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/schema/
     * @secure
     */
    schemaRetrieve: (query: SchemaRetrieveParams, params: RequestParams = {}) =>
      this.http.request<Record<string, any>, any>({
        path: `/schema/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  shchavot = {
    /**
     * No description
     *
     * @tags shchavot
     * @name ShchavotGetByOrderAndKehilaRetrieve
     * @request GET:/shchavot/get_by_order_and_kehila/
     * @secure
     */
    shchavotGetByOrderAndKehilaRetrieve: (
      query: ShchavotGetByOrderAndKehilaRetrieveParams,
      params: RequestParams = {},
    ) =>
      this.http.request<Shichva, any>({
        path: `/shchavot/get_by_order_and_kehila/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shchavot
     * @name ShchavotList
     * @request GET:/shchavot/
     * @secure
     */
    shchavotList: (query: ShchavotListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedShichvaList, any>({
        path: `/shchavot/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shchavot
     * @name ShchavotListByOrdersAndKehilaList
     * @request GET:/shchavot/list_by_orders_and_kehila/
     * @secure
     */
    shchavotListByOrdersAndKehilaList: (
      query: ShchavotListByOrdersAndKehilaListParams,
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedShichvaList, any>({
        path: `/shchavot/list_by_orders_and_kehila/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shchavot
     * @name ShchavotRetrieve
     * @request GET:/shchavot/{id}/
     * @secure
     */
    shchavotRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Shichva, any>({
        path: `/shchavot/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  signature = {
    /**
     * No description
     *
     * @tags signature
     * @name SignatureCreate
     * @request POST:/signature/
     * @secure
     */
    signatureCreate: (data: TakanonSignatureRequest, params: RequestParams = {}) =>
      this.http.request<TakanonSignature, any>({
        path: `/signature/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  strips = {
    /**
     * No description
     *
     * @tags strips
     * @name StripsCreate
     * @request POST:/strips/
     * @secure
     */
    stripsCreate: (data: StripRequest, params: RequestParams = {}) =>
      this.http.request<Strip, any>({
        path: `/strips/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsDestroy
     * @request DELETE:/strips/{id}/
     * @secure
     */
    stripsDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/strips/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsList
     * @request GET:/strips/
     * @secure
     */
    stripsList: (query: StripsListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedStripList, any>({
        path: `/strips/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsPartialUpdate
     * @request PATCH:/strips/{id}/
     * @secure
     */
    stripsPartialUpdate: (id: number, data: PatchedStripRequest, params: RequestParams = {}) =>
      this.http.request<Strip, any>({
        path: `/strips/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsPeilutList
     * @request GET:/strips/{id}/peilut/
     * @secure
     */
    stripsPeilutList: ({ id, ...query }: StripsPeilutListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedPeilutCardDisplayList, any>({
        path: `/strips/${id}/peilut/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsRetrieve
     * @request GET:/strips/{id}/
     * @secure
     */
    stripsRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Strip, any>({
        path: `/strips/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strips
     * @name StripsUpdate
     * @request PUT:/strips/{id}/
     * @secure
     */
    stripsUpdate: (id: number, data: StripRequest, params: RequestParams = {}) =>
      this.http.request<Strip, any>({
        path: `/strips/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  takanon = {
    /**
     * No description
     *
     * @tags takanon
     * @name TakanonList
     * @request GET:/takanon/
     * @secure
     */
    takanonList: (query: TakanonListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedTakanonList, any>({
        path: `/takanon/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags takanon
     * @name TakanonRetrieve
     * @request GET:/takanon/{id}/
     * @secure
     */
    takanonRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<Takanon, any>({
        path: `/takanon/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersCreate
     * @request POST:/users/
     * @secure
     */
    usersCreate: (data: UsersCreateRequest, params: RequestParams = {}) =>
      this.http.request<UsersCreate, any>({
        path: `/users/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersDestroy
     * @request DELETE:/users/{id}/
     * @secure
     */
    usersDestroy: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/users/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersList
     * @request GET:/users/
     * @secure
     */
    usersList: (query: UsersListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedUsersViewList, any>({
        path: `/users/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersMeRetrieve
     * @request GET:/users/me/
     * @secure
     */
    usersMeRetrieve: (params: RequestParams = {}) =>
      this.http.request<UsersMe, any>({
        path: `/users/me/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersPartialUpdate
     * @request PATCH:/users/{id}/
     * @secure
     */
    usersPartialUpdate: (id: number, data: PatchedUsersViewRequest, params: RequestParams = {}) =>
      this.http.request<UsersView, any>({
        path: `/users/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/users/{id}/
     * @secure
     */
    usersRetrieve: (id: number, params: RequestParams = {}) =>
      this.http.request<UsersView, any>({
        path: `/users/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersSendVerificationCodeCreate
     * @request POST:/users/{id}/send_verification_code/
     * @secure
     */
    usersSendVerificationCodeCreate: (
      { id, ...query }: UsersSendVerificationCodeCreateParams,
      params: RequestParams = {},
    ) =>
      this.http.request<void, void>({
        path: `/users/${id}/send_verification_code/`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersUnaffiliatedList
     * @request GET:/users/unaffiliated/
     * @secure
     */
    usersUnaffiliatedList: (query: UsersUnaffiliatedListParams, params: RequestParams = {}) =>
      this.http.request<PaginatedUsersViewList, any>({
        path: `/users/unaffiliated/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersUpdate
     * @request PUT:/users/{id}/
     * @secure
     */
    usersUpdate: (id: number, data: UsersViewRequest, params: RequestParams = {}) =>
      this.http.request<UsersView, any>({
        path: `/users/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersVerifyEmailCreate
     * @request POST:/users/{id}/verify_email/
     * @secure
     */
    usersVerifyEmailCreate: (id: number, data: CodeEmailRequest, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/users/${id}/verify_email/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Mixin to log requests
     *
     * @tags users
     * @name UsersVerifyPhoneCreate
     * @request POST:/users/{id}/verify_phone/
     * @secure
     */
    usersVerifyPhoneCreate: (id: number, data: CodePhoneRequest, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/users/${id}/verify_phone/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
}
